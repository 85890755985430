import { Stack, StackItem } from "@fluentui/react";
import { FC } from "react";
import Header, { IHeaderProps } from "v2/components/common/Header";
import CustomerInfoForm from "v2/components/customer-form/CustomerInfoForm";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";

const headerDetails: IHeaderProps = {
  title: "Tell us about yourself",
  subtitle:
    "Please complete the following information below in order for us to better assist you.",
  step: "STEP 1",
};

const CustomerInfo: FC = () => {
  const { customerInfo } = useTcoCalculationContext();
  const navigate = useNavigate();

  const isButtonDisabled = (): boolean => {
    return !(
      customerInfo.industry &&
      customerInfo.role &&
      customerInfo.companySize
    );
  };

  return (
    <Stack
      styles={{ root: { padding: "40px", width: "90%" } }}
      tokens={{ childrenGap: 32 }}
    >
      <Header
        title={headerDetails.title}
        subtitle={headerDetails.subtitle}
        step={headerDetails.step}
      />
      <StackItem>
        <CustomerInfoForm />
      </StackItem>
      <StackItem align="start">
        <Button
          appearance="primary"
          disabled={isButtonDisabled()}
          onClick={() => navigate("/choose-your-surface-mix")}
        >
          Continue
        </Button>
      </StackItem>
    </Stack>
  );
};

export default CustomerInfo;
