import { mergeStyleSets, Stack, StackItem, Text } from "@fluentui/react";
import { Button, Image, mergeClasses } from "@fluentui/react-components";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { commonStyles } from "v2/pages/tco/TcoLandingPage";

const styles = mergeStyleSets({
  textColor: {
    color: "#fff",
  },
  heading: {
    fontSize: "28px",
    fontWeight: "700",
  },
});

const Banner: FC = () => {
  const navigate = useNavigate();

  return (
    <Stack
      className={commonStyles.container}
      horizontal
      tokens={{ childrenGap: 80 }}
      horizontalAlign="space-evenly"
      verticalAlign="center"
    >
      <Stack tokens={{ childrenGap: 19 }} style={{ width: "50%" }}>
        <StackItem>
          <Text className={mergeClasses(styles.heading, styles.textColor)}>
            Discover the business value of Surface{" "}
          </Text>
        </StackItem>
        <StackItem>
          <Text className={styles.textColor}>
            Leverage the Total Cost of Ownership (TCO) calculator to get a
            report estimating the costs, savings, and ROI of replacing other PCs
            with Surface devices and M365. Discover how you can recoup time and
            boost productivity.
          </Text>
        </StackItem>
        <StackItem align="start">
          <Button
            appearance="primary"
            onClick={() => navigate("/tell-us-about-yourself")}
          >
            Get started
          </Button>
        </StackItem>
      </Stack>
      <Stack style={{ width: "50%" }}>
        <Image
          alt="image"
          src={require("../../assets/tco/banner/TCO -Banner.png")}
        />
      </Stack>
    </Stack>
  );
};
export default Banner;
