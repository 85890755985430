import { Currency } from "v2/types/currencyType";
import CurrencySymbols from "v2/store/CurrencySymbols.json";

export const convertFromUSDToOther = (
  valueInUSD: number,
  currency: Currency | undefined
) => {
  return currency?.currencyPerUSD !== undefined
    ? parseFloat((currency?.currencyPerUSD * valueInUSD).toFixed(2))
    : 0;
};

export const convertFromOtherToUSD = (
  valueInOther: number,
  currency: Currency | undefined
) => {
  return currency?.usdPerCurrency !== undefined
    ? parseFloat((valueInOther * currency?.usdPerCurrency).toFixed(2))
    : 0;
};

export const formatCurrency = (
  num: number,
  symbol: string,
  maximumFractionDigits: number
) => {
  if ((!num || isNaN(num)) && num !== 0) return 0;
  if (typeof num === "string") {
    num = Number(num);
  }

  let str = "";

  let isPrefix = CurrencySymbols.currencySymbols.filter(
    (item) => item.symbol === symbol
  )[0]?.isLeft;

  if (Math.abs(num) >= 1000000) {
    str = `${(num / 1000000).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionDigits ?? 0,
    })}M`;
  } else {
    str = num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionDigits ?? 0,
    });
  }

  if (isPrefix) {
    let arr = str.split("-");
    if (arr.length > 1 && num !== 0) {
      str = `-${symbol}${arr[1]}`;
    } else {
      str = `${symbol}${str}`;
    }
  } else {
    str = `${str} ${symbol}`;
  }

  return str;
};

export const convertStringToNumber = (str: string) => {
  if (typeof str === "number") return str;
  str = str.replaceAll(",", "");
  return Number(str);
};

export const validateDecimal = (value: string): boolean => {
  const regex = /^\d+(\.\d{0,2})?$/;
  return regex.test(value) || value === "";
};
