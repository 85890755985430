import { FC, useState } from "react";
import { Stack, StackItem, Text, Link } from "@fluentui/react";
import { Button, makeStyles, Spinner } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { generatePDF } from "v2/utilities/tco/pdfHelper";
import { useNavigate } from "react-router-dom";
import Header, { IHeaderProps } from "v2/components/common/Header";
import defaultDevices from "v2/store/DefaultDevices.json";
import { BUSINESS_CASE_URL } from "v2/constants";

const useStyles = makeStyles({
  heading: {
    fontSize: "28px",
    fontWeight: "700",
  },
  tcoButton: {
    padding: "8px 16px !important",
  },
});

interface ITitleProps {
  chartData: any;
  userInput: any;
}
type LoadingState = "initial" | "downloading" | "sharing";

const headerDetails: IHeaderProps = {
  title: "Review your TCO Report",
  subtitle: "",
  step: "STEP 4",
};

const ReportTitle: FC<ITitleProps> = ({ chartData, userInput }) => {
  const navigate = useNavigate();
  const { tcoCompleteReport, selectedYears, currency } =
    useTcoCalculationContext();
  const [isLoading, setIsLoading] = useState<LoadingState>("initial");
  const styles = useStyles();

  const handleDownload = async () => {
    setIsLoading("downloading");
    try {
      await generatePDF(
        tcoCompleteReport,
        tcoCompleteReport,
        chartData,
        currency.symbol,
        selectedYears,
        userInput
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading("initial");
    }
  };
  // const handleShareClick = () => {
  //   setIsLoading("sharing");
  //   try {
  //     const emailBody = `Please download the report and attach it to this email`;
  //     const encodedBody = encodeURIComponent(emailBody);

  //     const email = `mailto:?subject=TCO Report&body=${encodedBody}`;

  //     window.location.href = email;
  //   } catch (error) {
  //     alert("This functionality is not available.");
  //   } finally {
  //     setIsLoading("initial");
  //   }
  // };

  const handleCreateNew = () => {
    window.location.reload();
  };

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{ paddingBottom: "20px" }}
      >
        <StackItem align="start">
          <Header
            title={headerDetails.title}
            subtitle={headerDetails.subtitle}
            step={headerDetails.step}
          />
        </StackItem>
        <StackItem align="end">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Button
              className={styles.tcoButton}
              disabledFocusable={isLoading === "downloading"}
              appearance="primary"
              onClick={() => {
                navigate("/choose-your-surface-mix");
              }}
            >
              Edit report
            </Button>
            <Button
              className={styles.tcoButton}
              disabledFocusable={isLoading === "downloading"}
              icon={
                isLoading === "downloading" ? <Spinner size="tiny" /> : null
              }
              appearance="primary"
              onClick={handleDownload}
            >
              Download report
            </Button>
            <Button
              className={styles.tcoButton}
              disabledFocusable={isLoading === "downloading"}
              appearance="secondary"
              onClick={handleCreateNew}
            >
              Create new report
            </Button>
            {/* <Button
              className={styles.tcoButton}
              disabledFocusable={isLoading === "sharing"}
              icon={isLoading === "sharing" ? <Spinner size="tiny" /> : null}
              appearance="primary"
              onClick={handleShareClick}
            >
              Share
            </Button> */}
          </Stack>
        </StackItem>
      </Stack>
      <Stack style={{ paddingBottom: "20px" }}>
        <Text>
          Please note: The statistics and data points included below were pulled
          from an IDC study{" ("}
          <Link
            href={BUSINESS_CASE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Evaluating the Business Case of Microsoft Surface
          </Link>
          ). Relevant Microsoft customer stories are also shared to help
          communicate the value of Microsoft Surface for your industry, but
          these stories are not connected to or based on the IDC study.
        </Text>
      </Stack>
    </>
  );
};

export default ReportTitle;
