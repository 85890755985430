import { FC } from "react";
import { Stack, Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { TcoReportRow } from "v2/types/apiResponseTypes";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { formatCurrency } from "v2/utilities/currencyConverter";

const useStyles = makeStyles({
  typography: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "600",
  },
  regularText: {
    color: "#646464",
    fontWeight: "400",
  },
});

interface TextBlockProps {
  data: TcoReportRow;
  valueType?: string;
}

const TextBlock: FC<TextBlockProps> = ({ data, valueType }) => {
  const { currency, tcoReportPayload } = useTcoCalculationContext();
  const styles = useStyles();

  return (
    <Stack style={{ padding: "10px" }} id="detailsSection">
      <Text className={styles.typography} id="heading">
        {`${formatCurrency(
          (valueType === "savings" ? data?.savings : data?.surfaceValue) * tcoReportPayload.currencyConversionMultipleFromUSD,
          currency?.symbol ?? "$",
          0
        )} ${data?.label}`}
      </Text>
      <Text className={styles.regularText} id="subHeading">
        {data?.description}
      </Text>
    </Stack>
  );
};

export default TextBlock;
