import { mergeStyleSets } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { rootCertificates } from "tls";

export const style = mergeStyleSets({
  mainContentWrapper: {
    width: "100%",
    gap: "16px !important",
  },
  productCardContainer: {
    display: "flex",
    padding: "24px 24px 32px 24px !important",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px !important",
    borderRadius: "8px",
    border: "1px solid #F4F4F4",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
    alignSelf: "flex-start",
    flex: "0 1 calc((100% - 100px) / 3)",
  },
  productCardHeader: {
    // width: '213px',
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    minHeight: "235px",
  },
  productImage: {
    width: "170px",
    height: "103px",
  },
  productHeaderFont: {
    fontWeight: 700,
    fontSize: "16px",
    alignItems: "center",
    color: "#0078D4",
  },
  noDeviceHeaderFont: {
    fontWeight: 700,
    fontSize: "16px",
    alignItems: "center",
    color: "#1E1E1E",
  },
  tcoNormalFont: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#1E1E1E",
  },
  productDescriptionFont: {
    width: "100%",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    alignItems: "center",
    margin: "0px",
  },
  dropdownWrapper: {
    gap: "8px",
    width: "100%",
    "& .f1exfvgq": {
      minWidth: "100px",
    },
  },
  inputWrapper: {
    gap: "8px",
    width: "100%",
  },
  productCardBody: {
    gap: "16px",
    width: "100%",
  },
  productConfigurationRow: {
    gap: "24px",
    width: "100%",
    "@media (max-width: 1182px)": {
      flexWrap: "wrap",
    },
  },
  errorInput: {
    border: "1px solid red !important",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  customizeButton: {
    color: "#0078D4 !important",
    "& .fkfq4zb": {
      color: "#0078D4",
    },
  },
  deleteButton: {
    color: "#515151 !important",
  },
});
