export const TCO_REPORT_CHART_COLOR = {
    totalCosts: [ "#CAEAFF", "#8DC9F7", "#409FE9", "#005CAB"],
    savingsAndBenefits: ["#004B1C", "#BAD80A", "#107C10", "#50c433"],
    totalSavings: ["#B4A0FF", "#AB6BCC", "#5C20AD"],
    it: [
      "#FFF100",
      "#008272",
      "#00B294",
      "#004B1C",
      "#107C10",
      "#BAD80A",
      "#EDEBE9",
    ],
    employee: [
      "#FFF100",
      "#008272",
      "#00B294",
      "#004B1C",
      "#107C10",
      "#BAD80A",
      "#EDEBE9",
    ],
    incidents: {
      helpDesk: ["#C7E0F4", "#2B88D8"],
      security: ["#7076fa", "#b3a2f0"],
    },
  };
  