import { FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Magnifier = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1525_2057)">
        <rect
          x="4"
          y="2"
          width="28"
          height="22"
          rx="2"
          fill="url(#paint0_linear_1525_2057)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 22C4 23.1046 4.89543 24 6 24H30C31.1046 24 32 23.1046 32 22H4Z"
          fill="url(#paint1_linear_1525_2057)"
        />
        <mask
          id="mask0_1525_2057"
          maskUnits="userSpaceOnUse"
          x="4"
          y="2"
          width="28"
          height="22"
        >
          <rect
            x="4"
            y="2"
            width="28"
            height="22"
            rx="2"
            fill="url(#paint2_linear_1525_2057)"
          />
        </mask>
        <g mask="url(#mask0_1525_2057)">
          <g filter="url(#filter0_dd_1525_2057)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 20C0 14.9677 4.0645 11 8.99998 11C14.0322 11 18 14.9677 18 20C18 22.0269 17.3562 23.8811 16.2585 25.3729L20.4428 29.5572C20.9635 30.0779 20.9635 30.9221 20.4428 31.4428C19.9221 31.9635 19.0779 31.9635 18.5572 31.4428L14.3729 27.2585C12.8811 28.3562 11.0269 29 8.99998 29C3.96773 29 0 24.9354 0 20Z"
              fill="url(#paint3_linear_1525_2057)"
            />
          </g>
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 20C0 14.9677 4.0645 11 8.99998 11C14.0322 11 18 14.9677 18 20C18 22.0269 17.3562 23.8811 16.2585 25.3729L20.4428 29.5572C20.9635 30.0779 20.9635 30.9221 20.4428 31.4428C19.9221 31.9635 19.0779 31.9635 18.5572 31.4428L14.3729 27.2585C12.8811 28.3562 11.0269 29 8.99998 29C3.96773 29 0 24.9354 0 20Z"
          fill="url(#paint4_linear_1525_2057)"
        />
        <g filter="url(#filter1_dd_1525_2057)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 15C8.44772 15 8 15.4477 8 16V19H5C4.44772 19 4 19.4477 4 20C4 20.5523 4.44772 21 5 21H8V24C8 24.5523 8.44772 25 9 25C9.55228 25 10 24.5523 10 24V21H13C13.5523 21 14 20.5523 14 20C14 19.4477 13.5523 19 13 19H10V16C10 15.4477 9.55228 15 9 15Z"
            fill="url(#paint5_linear_1525_2057)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_1525_2057"
          x="-2"
          y="9"
          width="24.8335"
          height="24.8333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.333333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_2057"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_2057"
            result="effect2_dropShadow_1525_2057"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_2057"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_1525_2057"
          x="2"
          y="13"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="0.333333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_2057"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_2057"
            result="effect2_dropShadow_1525_2057"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_2057"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1525_2057"
          x1="25.5217"
          y1="28.1982"
          x2="7.50311"
          y2="0.393684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45494D" />
          <stop offset="1" stopColor="#737A80" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1525_2057"
          x1="21.7688"
          y1="27.0375"
          x2="18.8829"
          y2="18.2899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#737A80" />
          <stop offset="1" stopColor="#8A9299" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1525_2057"
          x1="25.5217"
          y1="28.1982"
          x2="7.50311"
          y2="0.393684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45494D" />
          <stop offset="1" stopColor="#737A80" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1525_2057"
          x1="14.8118"
          y1="27.8854"
          x2="4.14614"
          y2="11.7219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#30E5D0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1525_2057"
          x1="14.8118"
          y1="27.8854"
          x2="4.14614"
          y2="11.7219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#30E5D0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1525_2057"
          x1="6.27273"
          y1="13.5227"
          x2="9"
          y2="24.4318"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="white" />
          <stop offset="1" stopColor="#DFDFDF" />
        </linearGradient>
        <clipPath id="clip0_1525_2057">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
