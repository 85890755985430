import * as React from "react";
import { Stack, Text, Link } from "@fluentui/react";
import customerInfoData from "v2/store/customerInfoData.json";

interface AdditionalLinksProps {
  industryName: string;
}

const AdditionalLinks: React.FC<AdditionalLinksProps> = ({ industryName }) => {
  const industry = customerInfoData.industries.find(
    (industry) => industry.name === industryName
  );

  if (!industry) {
    return <Text>No links available for the selected industry.</Text>;
  }

  return (
    <Stack verticalFill tokens={{ childrenGap: 20 }} id="additional-links">
      {industry.links.map((link) => (
        <Stack key={link.id}>
          <Text>
            <Link href={link.url} target="_blank" rel="noopener noreferrer">
              [{link.id}] {link.title}
            </Link>
            &nbsp; {link.date} {link.source && `, ${link.source}`}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
};

export default AdditionalLinks;
