import { mergeStyleSets, Stack } from "@fluentui/react";
import { Button, ToggleButton } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { ProductDetails } from "v2/types/productTypes";
import { isDeviceValid } from "v2/utilities/helper";
export const Buttonstyle = mergeStyleSets({
  backButton: {
    padding: "8px 16px !important",
    gap: "var(--Font-size-100, 10px)",
    borderRadius: "2px",
    borderColor: "#000 !important",
    minWidth: "0px !important",
  },
  tcoButton: {
    padding: "8px 16px !important",
  },
  assumptionsButton: {
    paddingLeft: "0px !important",
    color: "rgb(0, 120, 212) !important",
  },
});

const NavigationButtons: React.FC = () => {
  const { selectedDevices, tcoCompleteReport, tcoReport } =
    useTcoCalculationContext();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const navigate = useNavigate();

  const validateSelectedDevices = (selectedDevices: ProductDetails[]) => {
    const isValid = selectedDevices.every(isDeviceValid);
    const isDisabled = !isValid;
    // ||
    // !(
    //   Object.keys(tcoCompleteReport).length > 0 &&
    //   Object.keys(tcoReport).length > 0
    // );
    setButtonDisabled(isDisabled);
  };
  useEffect(() => {
    validateSelectedDevices(selectedDevices);
  }, [selectedDevices, tcoCompleteReport, tcoReport]);

  return (
    <Stack horizontal tokens={{ childrenGap: 24 }}>
      <Button
        className={Buttonstyle.backButton}
        onClick={() => {
          navigate("/tell-us-about-yourself");
        }}
      >
        Back
      </Button>
      <Stack tokens={{ childrenGap: 16 }} horizontal>
        <Button
          className={Buttonstyle.tcoButton}
          appearance="primary"
          disabled={buttonDisabled}
          onClick={() => {
            navigate("/review-business-case-assumptions");
          }}
        >
          Review business case assumptions
        </Button>
      </Stack>
    </Stack>
  );
};

export default NavigationButtons;
