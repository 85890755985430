import { Stack } from "@fluentui/react";
import { ProductDetails } from "v2/types/productTypes";
import {
  Divider,
  InfoLabel,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  Text,
} from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  convertFromUSDToOther,
  formatCurrency,
} from "v2/utilities/currencyConverter";
import CostsDetailTooltip from "./CostsDetailTooltip";
import { TCO_REPORT_CHART_COLOR } from "constants/tco";
import { TcoFinalReport, TcoReportSegment } from "v2/types/apiResponseTypes";
import style, {
  tableStyles,
  benefitsTooltipStyles,
} from "./CostSavingsBannerStyle";
import React from "react";
const BannerImage = "BannerDevice.png";

const formatMemoryValue = (value: string): string => {
  return value.replace(/(\d+)(GB|TB)/, "$1 $2");
};
type SavingsBenefitsType = {
  name: string;
  value: string;
};
const CostSavingsBanner: React.FC = () => {
  const {
    currency,
    selectedYears,
    tcoReportPayload,
    tcoCompleteReport,
    selectedDevices,
    tcoCompleteReportPerDevice,
    tcoReportPayloadPerDevice,
    setTcoReportPayloadPerDevice,
    tcoCompleteReportMultipleDevice
  } = useTcoCalculationContext();
  const [deviceNames, setDeviceNames] = useState<string>("");
  const [showDeviceDetail, setShowDeviceDetail] = useState<boolean>(false);
  const [costSavings, setCostSavings] = useState<{
    totalCosts: string;
    totalSavings: string;
  }>({ totalCosts: "0", totalSavings: "0" });
  const [selectedTabValue, setSelectedTabValue] = useState<string>(
    selectedDevices.find(device => device.name !== "")?.name || ""
  );
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState<
    ProductDetails[]
  >([]);
  const location = useLocation();
  const costColors = TCO_REPORT_CHART_COLOR.totalCosts;
  const savingsColors = TCO_REPORT_CHART_COLOR.savingsAndBenefits;

  useEffect(() => {
    const deviceNames = selectedDevices
      .map((device: ProductDetails) => device.name)
      .filter((name) => name !== "")
      .join(", ");
    setDeviceNames(deviceNames);
    setSelectedDeviceDetails(
      selectedDevices.filter((device) => {
        return device.name !== "";
      })
    );
    const currentUrl = location.pathname;
    if (!currentUrl.includes("/choose-your-surface-mix")) {
      setShowDeviceDetail(true);
    }
    let deviceName = selectedTabValue;
    if (selectedTabValue && tcoReportPayload.deviceMixPortfolio.length > 0) {
      // if (selectedDevices.filter((device) => device.name === selectedTabValue).length === 0) {
      deviceName = selectedDevices.find(device => device.name !== "")?.name || "";
      setSelectedTabValue(deviceName);
      // }
      fetchTooltipData(deviceName);
    }
  }, [selectedDevices, tcoCompleteReportMultipleDevice, location]);

  useEffect(() => {
    const payload = { ...tcoReportPayload };
    const firstValidDevice = payload.deviceMixPortfolio.find(
      (device) => device.product !== ""
    );

    if (
      firstValidDevice &&
      tcoReportPayloadPerDevice?.deviceMixPortfolio.length === 0
    ) {
      setTcoReportPayloadPerDevice({
        ...payload,
        advancedInput: {},
        deviceMixPortfolio: [{ ...firstValidDevice, quantity: 1 }],
      });
    }
  }, [tcoReportPayload]);

  useEffect(() => {
    const costs = convertFromUSDToOther(
      Math.round(tcoCompleteReportMultipleDevice.mergedResponse.costs),
      currency
    );
    const savings = convertFromUSDToOther(tcoCompleteReportMultipleDevice.mergedResponse.savings, currency);
    setCostSavings({
      totalCosts: `${costs || "0"}`,
      totalSavings: `${savings || "0"}`,
    });
  }, [currency, tcoCompleteReportMultipleDevice]);

  const extractSavingsValues = (
    report?: TcoFinalReport
  ): SavingsBenefitsType[] => {
    if (!report) return [];
    const sustainabilitySavings = Math.round(
      convertFromUSDToOther(report.sustainability?.savings || 0, currency)
    ).toString();
    const totalSavings = Math.round(
      convertFromUSDToOther(report.totalSavings?.savings || 0, currency)
    ).toString();
    const itSimplificationSavings = Math.round(
      convertFromUSDToOther(report.itSimplification?.savings || 0, currency)
    ).toString();
    const employeeEmpowermentSavings = Math.round(
      convertFromUSDToOther(report.employeeEmpowerment?.savings || 0, currency)
    ).toString();

    return [
      { name: "Direct Savings per unit", value: totalSavings },
      {
        name: "IT efficiency benefits per unit",
        value: itSimplificationSavings,
      },
      {
        name: "Employee experience benefits",
        value: employeeEmpowermentSavings,
      },
      { name: "Sustainability savings", value: sustainabilitySavings },
    ];
  };

  const GetTooltip = (type: string) => {
    return (
      <Stack className={style.tooltipRoot}>
        <TabList
          selectedValue={selectedTabValue}
          onTabSelect={onTabSelect}
          className={style.tabListWithLine}
        >
          {selectedDevices.map((device, index) => (
            <Tab id={device.name} value={device.name} key={`${index}_${device.name}`} >
              {device.name}
            </Tab>
          ))}
        </TabList>
        <Stack>
          {type === "Costs" && (
            <CostsDetailTooltip
              colors={costColors}
              data={tcoCompleteReportPerDevice?.totalCosts as TcoReportSegment}
              currency={currency}
            />
          )}
          {type === "Benefits" &&
            savingsAndBenefitsTooltip(
              savingsColors,
              extractSavingsValues(tcoCompleteReportPerDevice)
            )}
        </Stack>
      </Stack>
    );
  };

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTabValue(data.value as string);
    fetchTooltipData(data.value as string);
  };

  const fetchTooltipData = (deviceName: string) => {
    const payload = {
      ...tcoReportPayload,
      deviceMixPortfolio: tcoReportPayload.deviceMixPortfolio.map((device) => ({
        ...device,
      })),
    };
    let deviceDetails = payload.deviceMixPortfolio?.filter(
      (device) => device.product === deviceName
    );
    if (deviceDetails.length > 0) {
      deviceDetails[0].quantity = 1;
      setTcoReportPayloadPerDevice((prev) => ({
        ...payload,
        advancedInput: {},
        deviceMixPortfolio: deviceDetails,
      }));
    }

  };

  const savingsAndBenefitsTooltip = (
    color: string[],
    data?: SavingsBenefitsType[]
  ) => {
    const currencySymbol = currency?.symbol ?? "$";
    // const total = data?.reduce((acc: any, curr: any) => Number(acc) + Number(curr.value), 0);
    const total = tcoCompleteReportPerDevice?.savings;
    return (
      <div
        className={`${benefitsTooltipStyles.wrapper} tco-report-chart-description-wrapper`}
      >
        {data ? (
          <>
            {data.map((item: any, index: number) => {
              const markerColor = { backgroundColor: color[index] };
              return (
                <Stack
                  horizontal
                  key={`${item.name}_${index}`}
                  className={`${benefitsTooltipStyles.tooltipItemList}  tco-report-chart-item-list`}
                  tabIndex={0}
                >
                  <Stack
                    horizontal
                    className={benefitsTooltipStyles.tooltipItemName}
                  >
                    <div
                      className={`${tableStyles.marker} tco-report-chart-item-list-marker`}
                      style={markerColor}
                    ></div>
                    <div>{item.name}</div>
                  </Stack>
                  <div className={benefitsTooltipStyles.tooltipItemValue}>
                    {formatCurrency(
                      convertFromUSDToOther(item.value, currency),
                      currencySymbol,
                      0
                    )}
                  </div>
                </Stack>
              );
            })}
            <Stack
              horizontal
              className={`${benefitsTooltipStyles.tooltipSummary} ${benefitsTooltipStyles.totalRow} tco-report-chart-item-list`}
              tabIndex={0}
            >
              <Stack
                horizontal
                className={benefitsTooltipStyles.tooltipItemName}
              >
                <div className={`${tableStyles.marker} mobile-hide`}></div>
                <div>Total</div>
              </Stack>
              <div style={{ minWidth: "15%" }}>
                {formatCurrency(
                  convertFromUSDToOther(total || 0, currency) || 0,
                  currencySymbol,
                  0
                )}
              </div>
            </Stack>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 24 }}
      className={style.bannerContainer}
      style={{ justifyContent: "space-between" }}
      id="costSavingsBanner"
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 12 }}
        styles={{ root: { alignItems: "center" } }}
        className={style.deviceConfigContainer}
      >
        <img
          className="costSavingsBannerImage"
          src={require(`../../assets/tco/${BannerImage}`)}
          alt={BannerImage}
        />
        {!showDeviceDetail && (
          <Text
            key={`deviceSummary-${deviceNames}`}
            className={`${style.devices} ${style.bannerTextFont}`}
          >
            {deviceNames}
          </Text>
        )}
        {showDeviceDetail && (
          <Stack horizontal tokens={{ childrenGap: 24 }}>
            {selectedDeviceDetails.map((device, index) => (
              <React.Fragment key={`${device.name}_${index}_${index}`}>
                <Stack key={`${device.name}__${index}`} className="alignCenter">
                  <Text
                    className={`${style.devices} ${style.bannerTextFont}`}
                    key={`${device.name}__`}
                  >
                    {device.name}
                  </Text>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 8 }}
                    className="alignCenter"
                  >
                    <Text key={`${device.processor}-${device.name}`}>
                      {device.processor}
                    </Text>
                    <Divider vertical className="subDivider" />
                    <Text key={`${device.ram}-${device.name}`}>
                      {formatMemoryValue(device.ram)}
                    </Text>
                    <Divider vertical className="subDivider" />
                    <Text key={`${device.driveSize}-${device.name}`}>
                      {formatMemoryValue(device.driveSize)}
                    </Text>
                  </Stack>
                  <Text key={`${device.quantity}-${device.name}`}>
                    {device.quantity} devices
                  </Text>
                </Stack>
                {index < selectedDeviceDetails.length - 1 && (
                  <Divider key={`${device.name} divider`} vertical inset />
                )}
              </React.Fragment>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 32 }}
        className={style.costSavingsContainer}
        styles={{ root: { marginLeft: "auto" } }}
      >
        <Stack>
          <Text className="currencySymbol" key={`savingsValueWrapper`}>
            {currency?.symbol ?? "$"}
            <Text className="costSavingsValue" key={`savingsValue`}>
              {formatCurrency(Number(costSavings.totalSavings), "", 0)}
            </Text>
          </Text>
          <InfoLabel
            style={{ fontSize: "14px", fontWeight: "400" }}
            info={GetTooltip("Benefits")}
          >
            Total Surface Savings and Benefits
          </InfoLabel>
          <Text style={{ fontSize: "10px", fontWeight: "400" }}>
            * Value over {selectedYears} years
          </Text>
        </Stack>
        <Divider
          vertical
          style={{ height: "100%" }}
          className={style.dividerStyle}
        />
        <Stack>
          <Text className="currencySymbol">
            {currency?.symbol ?? "$"}
            <Text className="costSavingsValue">
              {formatCurrency(Number(costSavings.totalCosts), "", 0)}
            </Text>
          </Text>
          <InfoLabel
            style={{ fontSize: "14px", fontWeight: "400" }}
            info={GetTooltip("Costs")}
          >
            Total Costs
          </InfoLabel>
          <Text style={{ fontSize: "10px", fontWeight: "400" }}>
            * Value over {selectedYears} years
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CostSavingsBanner;
