import { on, Stack, StackItem, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { InputOnChangeData } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import LabelledInput from "../common/LabelledInput";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
} from "v2/utilities/currencyConverter";
import { TcoFinalReport, TcoReportRow } from "v2/types/apiResponseTypes";
import { AdvancedInputItem, AdvancedInput } from "v2/types/advancedInput";
import {
  convertNumberToString,
  convertStringToNumber,
  formatCurrency,
  formatNumber,
} from "utils/helpers";
import { set } from "lodash";

interface ITEfficiencyProps {
  device: string; // Add your desired prop here
}

const ITEfficiency: FC<ITEfficiencyProps> = ({ device }) => {
  const {
    setTcoReportPayload,
    setTcoCompleteReport,
    currency,
    tcoCompleteReport,
    tcoReportPayloadList,
    selectedCountry,
    setTcoReportPayloadList,
    tcoCompleteReportMultipleDevice,
  } = useTcoCalculationContext();

  let r = tcoCompleteReport.itSimplification.rows;

  let c1= tcoReportPayloadList.payloadList[0].advancedInput;

  const [itEfficiency, setITEfficiency] = useState<TcoReportRow>(
    {} as TcoReportRow
  );
  const [initialITEfficiency, setInitialITEfficiency] = useState<TcoReportRow>(
    {} as TcoReportRow
  );
  const getInfo = (field: string) => {
    return <Stack>{field}</Stack>;
  };
  const ITEfficiencyData = [
    {
      Id: "Device Consolidation",
      key: "Device Consolidation",
      label: "Device Consolidation",
      isAmount: true,
      DisplayProperty: "pcValue",
      info: getInfo(
        "Cost calculations uses the cost of the other PC x number of device that you will consolidate including license and support. Forty-four percent of respondents reported being able to consolidate devices by using Microsoft Surface, with 74 percent of these organizations consolidating two devices into one Surface, 20 percent consolidating three devices, and 6 percent consolidating four devices. On average, organizations that consolidated devices replaced 2.32 devices with a single Microsoft Surface device. (Refer to IDC study.)"
      ),
    },
    {
      Id: "Reduced Ongoing Maintenance",
      key: "Reduced Ongoing Maintenance",
      label: "Ongoing Maintenance",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your IT staff' Microsoft Surface devices need less ongoing maintenance. Organizations experience a reduction in annual ongoing maintenance of 5.1 hours, with an annual maintenance of 7.5 hours for Surface devices (versus 12.6 hours for other devices). This results in a 40-percent increase in efficiency and a three-year benefit of $810. (Refer to IDC study.)"
      ),
    },
    {
      Id: "Reduced Helpdesk Support",
      key: "Reduced Helpdesk Support",
      label: "Helpdesk Support",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your IT staff' Microsoft Surface devices enable a reduction of HelpDesk support—of 5.5 hours per year (per organization, provided that Surface is used company-wide)—with an annual support-hours total of 7.2 for Surface devices (versus 12.7 hours for other devices). This equates to a 44-percent increase in efficiency and a three-year benefit of $885. (Refer to IDC study.)"
      ),
    },
    {
      Id: "Reduced Time for Security Incident Response",
      key: "Reduced Time for Security Incident Response",
      label: "Time For Security Incident Response",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your IT staff' Microsoft Surface devices reduce annual time spent responding to security incidents, with a response time of two hours for Surface devices (versus 2.8 hours for other devices). This translates to a 29-percent increase in efficiency and a three-year benefit of $126. (Refer to IDC study.)"
      ),
    },
    {
      Id: "Reduced IT Deployment Costs_1",
      key: "Reduced IT Deployment Costs",
      label: "Reduced IT Deployment Costs",
      isAmount: true,
      DisplayProperty: "pcValue",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your IT staff' Microsoft Surface devices enable a reduction in costs for one-time IT deployment, with 3.7 hours for Surface devices (versus 4.5 hours for other devices). This equates to a 17-percent increase in efficiency and a three-year benefit of $42. (Refer to IDC study.)"
      ),
    },
    {
      Id: "Reduced IT Deployment Costs_2",
      key: "Reduced IT Deployment Costs",
      label: "",
      isAmount: false,
      DisplayProperty: "pcHours",
    },
    {
      Id: "Reduced IT Staff Time for Ongoing Security_1",
      key: "Reduced IT Staff Time for Ongoing Security",
      label: "Ongoing Security",
      isAmount: true,
      DisplayProperty: "pcValue",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your IT staff' Microsoft Surface devices require less annual IT staff time for ongoing security, with a time requirement of 3.7 hours for Surface devices (versus 3.8 hours for other devices). This results in a three-percent increase in efficiency and a three-year benefit of $18. (Refer to IDC study.)"
      ),
    },
    {
      Id: "Reduced IT Staff Time for Ongoing Security_2",
      key: "Reduced IT Staff Time for Ongoing Security",
      label: "",
      isAmount: false,
      DisplayProperty: "pcHours",
    },
    {
      Id: "IT Training Costs_1",
      key: "IT Training Costs",
      label: "IT Training Costs",
      isAmount: true,
      DisplayProperty: "pcValue",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your IT staff' Microsoft Surface devices have a higher one-time IT-training cost when compared to other devices, with a cost of $34 per each Surface device (versus $32 each for other devices). This represents a six-percent decrease in efficiency and a one-time cost of $2 more. (Refer to IDC study.)"
      ),
    },
    {
      Id: "IT Training Costs_2",
      key: "IT Training Costs",
      label: "",
      isAmount: false,
      DisplayProperty: "pcHours",
    },
  ];

  const [editingField, setEditingField] = useState<string>({} as string);

  const [editingValue, setEditingValue] = useState<string>({} as string);

  const [editingCurrency, setEditingCurrency] = useState<string>({} as string);

  const onChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    tcoreport: any,
    key: string,
    displayProperty: string
  ) => {
    const tcoreportCopy = { ...tcoreport };

    tcoreportCopy[key][displayProperty] = formatNumber(
      data.value === "" ? "0" : data.value
    );

    setITEfficiency(tcoreportCopy);
  };

  const removeCommas = (value: any) => {
    return value?.toString()?.replace(/,/g, "");
  };

  const onBlur = (
    ev: React.FocusEvent<HTMLInputElement, Element>,
    key: string,
    displayProperty: string,
    tcoreport: any
  ) => {
    const tcoreportCopy = { ...tcoreport, [key]: { ...tcoreport[key] } };
    let isValueChanged: boolean = false;
    setEditingField(key);
    setEditingValue(ev.target.value);
    setEditingCurrency(currency?.countryCode);

    let i = removeCommas(ev.target.value);

    let data: string =
      displayProperty === "pcValue"
        ? selectedCountry.code === "US"
          ? i.toString()
          : (Number(removeCommas(i)) * currency?.usdPerCurrency)
            .toFixed(2)
            .toString()
            .toString()
        : i.toString();

    tcoreportCopy[key][displayProperty] = formatNumber(data);

    const advancedinput: AdvancedInput = {};


     let advancedpayload =
      
     tcoReportPayloadList.payloadList?.find(i=>i.deviceMixPortfolio[0].product==device)?.advancedInput === undefined
        ? advancedinput
        :  tcoReportPayloadList.payloadList?.find(i=>i.deviceMixPortfolio[0].product==device)?.advancedInput;

       var item= tcoReportPayloadList.payloadList.find(i=>i.deviceMixPortfolio[0].product==device);

       if(item)
       {
        item.advancedInput = advancedpayload;
       }   
        
    //tcoReportPayloadList.payloadList.find(i=>i.deviceMixPortfolio[0].product==device).advancedInput = advancedpayload;

    if (key == "Device Consolidation") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcHours).toFixed(2);
      if (isValueChanged) {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreportCopy[key]["savings"],
        surfaceValue: tcoreportCopy[key]["surfaceValue"],
        pcValue: data,
        isOtherPCValueChanged: true,
      };
      if(advancedpayload)
        advancedpayload.deviceConsolidation = advancedAssumptionsInputItem;
      }
      else if(advancedpayload){
        delete advancedpayload.deviceConsolidation;
      }
    }

    if (key == "Reduced Helpdesk Support") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcHours).toFixed(2);
      if (isValueChanged) {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreportCopy[key]["savings"],
        surfaceValue: tcoreportCopy[key]["surfaceValue"],
        pcValue: tcoreportCopy[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };
      if(advancedpayload)
        advancedpayload.deviceConsolidation = advancedAssumptionsInputItem;
      }
      else if(advancedpayload){
        delete advancedpayload.helpdeskSupport;
      }
    }

    if (key == "Reduced Ongoing Maintenance") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcHours).toFixed(2);
      if (isValueChanged) {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreportCopy[key]["savings"],
        surfaceValue: tcoreportCopy[key]["surfaceValue"],
        pcValue: tcoreportCopy[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };
      if(advancedpayload)
        advancedpayload.deviceConsolidation = advancedAssumptionsInputItem;
      }
      else if(advancedpayload){
        delete advancedpayload.ongoingMaintenance;
      }
    }

    if (key == "Reduced Time for Security Incident Response") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcHours).toFixed(2);
      if (isValueChanged) {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreportCopy[key]["savings"],
        surfaceValue: tcoreportCopy[key]["surfaceValue"],
        pcValue: tcoreportCopy[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };

      if(advancedpayload)
        advancedpayload.deviceConsolidation = advancedAssumptionsInputItem;
      }
      else if(advancedpayload){
        delete advancedpayload.securityIncidentResponse;
      }
    }
  
     if (key === "Reduced IT Deployment Costs" && displayProperty === "pcHours") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      const initialPcValues = removeCommas((initialITEfficiency as any)[key]?.pcValue);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcHours).toFixed(2);
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreportCopy[key]["savings"],
        surfaceValue: tcoreportCopy[key]["surfaceValue"],
        pcValue: tcoreportCopy[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
        isOtherPCValueChanged: false,
      };
      if (!isValueChanged) {
        advancedAssumptionsInputItem.isHoursChanged = false;
      }
      if(advancedpayload)
      advancedpayload.reducedITDeploymentCosts = advancedAssumptionsInputItem;
      if (!isValueChanged && Number(advancedpayload?.reducedITDeploymentCosts?.pcValue).toFixed(2) === Number(initialPcValues).toFixed(2)) {
       if(advancedpayload)
        delete advancedpayload.reducedITDeploymentCosts;
      }
    }
    if (key === "Reduced IT Deployment Costs" && displayProperty === "pcValue") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      const initialPcValue = removeCommas((initialITEfficiency as any)[key]?.pcValue);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcValue).toFixed(2);
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreportCopy[key]["savings"],
        surfaceValue: tcoreportCopy[key]["surfaceValue"],
        pcValue: data,
        pcHours: tcoreportCopy[key]["pcHours"],
        isOtherPCValueChanged: true,
      };
      if (!isValueChanged) {
        advancedAssumptionsInputItem.isOtherPCValueChanged = false;
      }
      if(advancedpayload)
      advancedpayload.reducedITDeploymentCosts = advancedAssumptionsInputItem;
      if (!isValueChanged && Number(advancedpayload?.reducedITDeploymentCosts?.pcHours) === Number(initialPcHours)) {
        if(advancedpayload)
        delete advancedpayload.reducedITDeploymentCosts;
      }
    }

    if (
      key === "Reduced IT Staff Time for Ongoing Security" &&
      displayProperty === "pcHours"
    ) {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      const initialPcValues = removeCommas((initialITEfficiency as any)[key]?.pcValue);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcHours).toFixed(2);
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
        isOtherPCValueChanged: false,
      };
      if (!isValueChanged) {
        advancedAssumptionsInputItem.isHoursChanged = false;
      }
      if(advancedpayload)
      advancedpayload.ongoingSecurity = advancedAssumptionsInputItem;
      if (!isValueChanged && Number(advancedpayload?.ongoingSecurity?.pcValue).toFixed(2) === Number(initialPcValues).toFixed(2)) {
        if(advancedpayload)
        delete advancedpayload.ongoingSecurity;
      }
    }
    if (
      key === "Reduced IT Staff Time for Ongoing Security" &&
      displayProperty === "pcValue"
    ) {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      const initialPcValue = removeCommas((initialITEfficiency as any)[key]?.pcValue);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcValue).toFixed(2);
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: data,
        pcHours: tcoreport[key]["pcHours"],
        isOtherPCValueChanged: true,
      };
      if (!isValueChanged) {
        advancedAssumptionsInputItem.isOtherPCValueChanged = false;
      }
      if(advancedpayload)
      advancedpayload.ongoingSecurity = advancedAssumptionsInputItem;
      if (!isValueChanged && Number(advancedpayload?.ongoingSecurity?.pcHours) === Number(initialPcHours)) {
        if(advancedpayload)
        delete advancedpayload.ongoingSecurity;
      }
    }

    if (key === "IT Training Costs" && displayProperty === "pcHours") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      const initialPcValues = removeCommas((initialITEfficiency as any)[key]?.pcValue);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcHours).toFixed(2);
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
        isOtherPCValueChanged: tcoreport[key]["isOtherPCValueChanged"],
      };
      if (!isValueChanged) {
        advancedAssumptionsInputItem.isHoursChanged = false;
      }
      if(advancedpayload)
      advancedpayload.itTraining = advancedAssumptionsInputItem;

      if (!isValueChanged && Number(advancedpayload?.itTraining?.pcValue).toFixed(2) === Number(initialPcValues).toFixed(2)) {
        if(advancedpayload)
        delete advancedpayload.itTraining;
      }
    }
    if (key === "IT Training Costs" && displayProperty === "pcValue") {
      const initialPcHours = removeCommas((initialITEfficiency as any)[key]?.pcHours);
      const initialPcValue = removeCommas((initialITEfficiency as any)[key]?.pcValue);
      isValueChanged = Number(data).toFixed(2) !== Number(initialPcValue).toFixed(2);
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: data,
        pcHours: tcoreport[key]["pcHours"],
        isHoursChanged: tcoreport[key]["isHoursChanged"],
        isOtherPCValueChanged: true,
      };
      if (!isValueChanged) {
        advancedAssumptionsInputItem.isOtherPCValueChanged = false;
      }
      if(advancedpayload)
      advancedpayload.itTraining = advancedAssumptionsInputItem;

      if (!isValueChanged && Number(advancedpayload?.itTraining?.pcHours) === Number(initialPcHours)) {
        if( advancedpayload)
        delete advancedpayload.itTraining;
      }
    }

    let tcoReportPayloadCopy = {
      ... tcoReportPayloadList.payloadList.find(i=>i.deviceMixPortfolio[0].product==device),
      advancedInput: advancedpayload,
    };

    let copiedTcoReportPayloadList = { ...tcoReportPayloadList };

    let y = copiedTcoReportPayloadList.payloadList.find(i => i.deviceMixPortfolio[0].product == device);
    
    if (y) {
      y.advancedInput = tcoReportPayloadCopy.advancedInput;
    }
   
    setTcoReportPayloadList(copiedTcoReportPayloadList);
  };

  useEffect(() => {
    const data = ITEfficiencyData.reduce((acc, item) => {
      const foundItem = tcoCompleteReportMultipleDevice.deviceResponse?.find(i=>i.device==device)?.itSimplification?.rows?.find(
        (i) => i.label === item.key
      );
      return {
        ...acc,
        [item.key]: {
          ...foundItem,
          pcValue:
            selectedCountry.code === "US"
              ? Math.round(Number(foundItem?.pcValue))
              : Math.round(Number(foundItem?.pcValue) * currency.currencyPerUSD),
          pcHours: (foundItem?.pcHours != undefined) ? convertNumberToString((Number(foundItem?.pcHours).toFixed(2))).toString() : "0"
        },
      };
    }, {} as any);

    setITEfficiency((preState) => {
      const updatedState = data;

      return updatedState;

    });
  }, [currency, tcoCompleteReport]);

  return (
    <Stack
      horizontal
      wrap
      tokens={{ childrenGap: 20 }}
      styles={{ root: { padding: "20px 0px 0px 50px" } }}
    >
      {ITEfficiencyData?.map((item) => (
        <StackItem grow style={{ width: "45%" }} key={item.Id}>
          <LabelledInput
            label={item.label}
            sublabel={item.isAmount ? "Other PC Value" : "Other PC Hours"}
            isHeadingBold={true}
            isAmount={item.isAmount}
            infoLabel={item.info ?? ""}
            value={`${((itEfficiency as any)[item.key] as any)?.[item.DisplayProperty]
              }`.toString()}
            onChange={(ev, data) => {
              onChange(
                ev,
                data,
                itEfficiency as any,
                item.key,
                item.DisplayProperty
              );
            }}
            onBlur={(ev) =>
              onBlur(ev, item.key, item.DisplayProperty, itEfficiency as any)
            }
          />
        </StackItem>
      ))}
    </Stack>
  );
};

export default ITEfficiency;
