import { FC, useEffect, useState } from "react";
import { mergeStyleSets, Pivot, PivotItem, Stack } from "@fluentui/react";
import Header from "v2/components/common/Header";
import assumptionDataAll from "v2/components/business-assumptions/BusinessAssumptionDataAll.json";
import assumptionDataDevices from "v2/components/business-assumptions/BusinessAssumptionDataDevices.json";
import AssumptionDetailPanel from "v2/components/business-assumptions/AssumptionDetailPanel";
import LabelledDropdown from "v2/components/common/LabelledDropdown";
import {
  Button,
  Divider,
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
  tokens,
} from "@fluentui/react-components";
import EmployeeAssumption from "v2/components/business-assumptions/EmployeeAssumption";
import ITEfficiency from "v2/components/business-assumptions/ITEfficiency";
import EmployeeExperience from "v2/components/business-assumptions/EmployeeExperience";
import AccessoriesAndExtendedWarranty from "v2/components/business-assumptions/AccessoriesAndExtendedWarranty";
import SurfaceAndOtherPC from "v2/components/business-assumptions/SurfaceAndOtherPC";
import Sustainability from "v2/components/business-assumptions/Sustainability";
import DeviceSavings from "v2/components/business-assumptions/DeviceSavings";
import DeviceCosts from "v2/components/business-assumptions/DeviceCosts";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { useNavigate } from "react-router-dom";
import { Buttonstyle } from "v2/components/navigation-buttons/NavigationButtons";

import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

import CostSavingsBanner from "v2/components/cost-savings-banner/CostSavingsBanner";
import { set } from "lodash";

const step = "STEP 3";
const heading = "Review business case assumptions (optional)";
const subHeading =
  "To get a more accurate TCO report, update the assumptions below with your own information or accept default assumptions based on a study conducted by IDC and commissioned by Microsoft in 2022.";
  export type BussinessAssumption = {
    id: string;
    title: string;
    subTitle: string;
    iconName: string;
  };
  
  interface BusinessAssumptionJson {
    [key: string]: BussinessAssumption;
  }
  
  const childrenMapping: { [key: string]: FC<any> } = {
    //employeeAssumptions: EmployeeAssumption,
    itEfficiency: ITEfficiency,
    employeeExperience: EmployeeExperience,
    // accessoriesAndExternalWarranty: AccessoriesAndExtendedWarranty,
    // surfaceAndOtherPCDevices: SurfaceAndOtherPC,
    // Sustainability: Sustainability,
    deviceSavings: DeviceSavings,
    deviceCost: DeviceCosts,
    //add other childrens here
  };
  
  const childrenMappingAllDevices: { [key: string]: FC<any> } = {
    employeeAssumptions: EmployeeAssumption,
    accessoriesAndExternalWarranty: AccessoriesAndExtendedWarranty,
    surfaceAndOtherPCDevices: SurfaceAndOtherPC,
    Sustainability: Sustainability,
  };
  const useStyles = makeStyles({
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "15px 5px",
      rowGap: "15px",
    },
    panels: {
      padding: "0 2px",
      "& th": {
        textAlign: "left",
        padding: "0 14px 0 0",
      },
    },
    propsTable: {
      "& td:first-child": {
        fontWeight: tokens.fontWeightSemibold,
      },
      "& td": {
        padding: "0 15px 0 0",
      },
    },
  });

  interface DeviceAssumptionsProps {
    DeviceName: string; // Add your desired prop here
  }
 
  const Devices: FC<DeviceAssumptionsProps> = (DeviceName) => {
    const navigate = useNavigate();

    const [openItems, setOpenItems] = useState<string[]>([]);
    const [selectedValue, setSelectedValue] =
    React.useState<TabValue>("All Devices");
  const styles = useStyles();
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const dataAll = assumptionDataAll as BusinessAssumptionJson;
  const dataDevices = assumptionDataDevices as BusinessAssumptionJson;
  const {
    selectedYears,
    setSelectedYears,
    setTcoReportPayload,
    tcoReportPayloadList,
    setTcoReportPayloadList,
  } = useTcoCalculationContext();

return (

  <Stack
  styles={{
    root: { paddingLeft: "40px", paddingTop: "10px", width: "90%" },
  }}
  tokens={{ childrenGap: 32 }}
>
  <Header
    title={`${DeviceName.DeviceName} - ${heading}`}
    subtitle={subHeading}
    step={""}
  />
  {Object.keys(dataDevices).map((key, index) => {
    const Children = childrenMapping[key];
    return (
      <Stack key={index} tokens={{ childrenGap: 32 }}>
        <AssumptionDetailPanel
          data={dataDevices[key]}
          key={key}
          Id={dataDevices[key].id}
          openItems={openItems}
          device={DeviceName.DeviceName}
          children={Children && <Children device={DeviceName.DeviceName} />}
        />
        {index === 3 && <Stack tokens={{ childrenGap: 24 }}></Stack>}
      </Stack>
    );
  })}
  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 24 }}>
    <Button
      onClick={() => navigate("/choose-your-surface-mix")}
      className={Buttonstyle.backButton}
    >
      Back
    </Button>
    <Button
      appearance="primary"
      onClick={() => navigate("/tco-report")}
      className={Buttonstyle.tcoButton}
    >
      View TCO report
    </Button>
  </Stack>
</Stack>
)
  }
export default Devices;