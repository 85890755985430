import { LARGE_NUMBER } from "constants/sustainability";
import jsPDF from "jspdf";
import PDFLandingImg from "assets/PDFLanding.png";
import LPImage1 from "assets/LPImage1.png";
import LPImage2 from "assets/LPImage2.png";
import LPImage3 from "assets/LPImage3.png";
import Page3Img1 from "assets/Page3Img1.png";
import Page3Img2 from "assets/Page3Img2.png";
import Page3Img3 from "assets/Page3Img3.png";
import Page3Img4 from "assets/Page3Img4.png";
import LeafImage from "assets/leaf.png";
//import MSSurfaceLogo from "assets/MSSurfaceLogo.png";
import {  
  TCO_REPORT_CHART_COLOR,
  logo,
} from "constants/tco";
import {
  amiriFont,
  rupeeFont,
  rayligFont
} from "constants/psat";
import CurrencySymbols from "../assets/CurrencySymbols.json";
import ReactGA from 'react-ga4';

export const convertStringToNumber = (str) => {
  if (typeof str === "number") return str;
  str = str.replaceAll(",", "");
  return Number(str);
};

export const convertNumberToString = (num, prefix, maximumFractionDigits) => {
  if (num === undefined) return;
  let str = num.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits ?? 2,
  });
  if (prefix) {
    let arr = str.split("-");
    if (arr.length > 1) {
      str = `-${prefix}${arr[1]}`;
    } else {
      str = `${prefix}${str}`;
    }
  }
  return str;
};

export const isPositiveInteger = (val) => {
  val = val.replaceAll(",", "");
  const isIntegerRegex = /^\d+$/;
  if (!val || !val.match(isIntegerRegex) || val === "0") {
    return false;
  }
  return true;
};

export const isNumber = (val) => {
  if (val === "-") {
    return true;
  }
  val = val.replaceAll(",", "");
  const isNumberRegex = /^-?\d+(\.\d+)?$/;
  const isHalfDecimalRegex = /^-?\d+\.$/;
  if (val.match(isHalfDecimalRegex)) {
    return true;
  }
  if (!val || !val.match(isNumberRegex)) {
    return false;
  }
  return true;
};

export const isPositiveNumber = (val) => {
  val = val.replaceAll(",", "");
  const isNumberRegex = /^\d+(\.\d+)?$/;
  const isHalfDecimalRegex = /^\d+\.$/;
  if (val.match(isHalfDecimalRegex)) {
    return true;
  }
  if (!val || !val.match(isNumberRegex)) {
    return false;
  }
  return true;
};

export const formatNumber = (val) => {
  val = val.replaceAll(",", "");
  const isNumberRegex = /^-?\d+(\.\d+)?$/;
  const isHalfDecimalRegex = /^-?\d+\.$/;

  if (!val || val.match(isHalfDecimalRegex)) {
    return val;
  }
  if (!val.match(isNumberRegex)) {
    return "";
  }
  let number;
  if (val.includes(".")) {
    const arr = val.split(".");
    let current = arr[0] + "." + arr[1].slice(0, 2);
    const tailZeroIndex = current.search(/0+$/);
    number = parseFloat(current);
    // add tail zero back
    if (tailZeroIndex !== -1) {
      let result = number.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      });
      if (!result.includes(".")) {
        result = `${result}.${current.slice(tailZeroIndex)}`;
      } else {
        result = `${result}${current.slice(tailZeroIndex)}`;
      }
      return result;
    }
  } else {
    number = parseInt(val);
  }
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const convertRemToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const validationCheck = (value, validations) => {
  let error = "";
  validations.forEach((fn) => {
    error = fn(value);
  });
  return error;
};

export const requiredCheck = (value) => {
  if (value === null || value === undefined || value === "") {
    return "This is required";
  }
};

export const emailValidator = (value) => {
  const eMailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regexCheck = eMailReg.test(value);
  if (!regexCheck) {
    return "Email address is not valid";
  }
};

export const phoneValidator = (value) => {
  /*
    Valid formats:
    (123) 456-7890
    (123)456-7890
    123-456-7890
    123.456.7890
    1234567890
    +31636363634
    075-63546725
  */
  const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  if (!phoneReg.test(value)) {
    return "Phone number is not valid";
  }
};

export const numberValidator = (value) => {
  if (isNaN(convertStringToNumber(value))) {
    return "Please enter a valid number.";
  }
};

export const isObjectEmpty = (obj) => {
  return !Object.keys(obj).length;
};

export const kNumFormat = (num, isHideSmallNumber) => {
  if (!num || isNaN(num)) return "0";
  if (typeof num === "string") {
    num = Number(num);
  }
  /* if (isHideSmallNumber && Math.abs(num) / 1000 < 1) {
    
    return num % 1 === 0 ? num : formatNumberWithUnit(num, null, 2); //return "< 1K";
  } */
  /* if (Math.abs(num) > 1000000) {
    return `${convertNumberToString(num / 1000000)}M`;
  } */
  let decimalDigits = null;
  if (Math.abs(num) < 100) {
    const numToString = (num / 1000).toString();
    const index = numToString.indexOf(".");
    const decimal = numToString.length - index - 1;
    decimalDigits = index === -1 ? 0 : decimal > 5 ? 5 : decimal;
  }

  return `${convertNumberToString(num / 1000, null, decimalDigits)}`;
};

export const formatCurrency = (num, symbol, maximumFractionDigits) => {
  if ((!num || isNaN(num)) && num !== 0) return 0;
  if (typeof num === "string") {
    num = Number(num);
  }

  let str = "";

  let isPrefix = CurrencySymbols.currencySymbols.filter(
    (item) => item.symbol === symbol
  )[0].isLeft;

  if (Math.abs(num) >= 1000000) {
    str = `${(num / 1000000).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionDigits ?? 0,
    })}M`;
  } else {
    str = num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionDigits ?? 0,
    });
  }

  if (isPrefix) {
    let arr = str.split("-");
    if (arr.length > 1) {
      str = `-${symbol}${arr[1]}`;
    } else {
      str = `${symbol}${str}`;
    }
  } else {
    str = `${str} ${symbol}`;
  }

  return str;
};

export const formatNumberWithUnit = (num, prefix, maximumFractionDigits) => {
  if (!num || isNaN(num)) return 0;
  if (typeof num === "string") {
    num = Number(num);
  }
  if (Math.abs(num) / 1000 < 1) {
    return convertNumberToString(num, prefix, maximumFractionDigits);
  }
  if (Math.abs(num) > 1000000) {
    return `${convertNumberToString(
      num / 1000000,
      prefix,
      maximumFractionDigits
    )}M`;
  }
  return `${convertNumberToString(num, prefix, maximumFractionDigits)}`; //`${convertNumberToString(num / 1000, prefix, maximumFractionDigits)}K`;
};

export const splitLargeNumber = (num) => {
  const numString = convertNumberToString(num);
  const arr = numString.split(",");
  let splitIndex = arr.length - 1;
  if (arr.length > 5) {
    splitIndex = 4;
  }
  const leftPart = arr.slice(0, splitIndex).join(",");
  const rightPart = arr.slice(splitIndex).join(",");
  return [leftPart, rightPart];
};

export const cutLongString = (str) => {
  let result = str;
  const arr = str.split(" ");
  if (arr.length > 3) {
    result = arr.slice(0, 3).join(" ") + "\n" + arr.slice(3).join(" ");
  }
  return result;
};

export const responsiveSize = (size, ratio = 0.75) => {
  const width = window.innerWidth;
  if (width < 820) {
    return size * ratio;
  }
  return size;
};

export const isSmallWidthScreen = () => {
  const width = window.innerWidth;
  if (width < 820) {
    return true;
  }
  return false;
};

export const isLargeNumber = (num) => {
  num = Math.abs(num);
  if (num > LARGE_NUMBER) {
    return true;
  }
  return false;
};

export const downloadPdf = (fileName) => {
  fetch(`${fileName}.pdf`).then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = `${fileName}.pdf`;
      alink.target = "_blank";
      alink.click();
    });
  });
};

export const sendRequest = (method, url, body) => {
  const options = {
    method: method,
  };

  options.body = JSON.stringify(body);
  let rootURL = process.env.REACT_APP_API_URL;

  let code = process.env.REACT_APP_API_CODE;
  

  url = `${rootURL}${url}?code=${code}`;

  return fetch(url, options).then((response) => {
    if (response.status !== 200) {
      return {
        error: {
          message: response.statusText,
        },
      };
    }
    return response.json();
  });
};

export const calculateNPVAndBPB = (totalCost, totalSavings, type, view, discount) => {
  const discountRate = discount ? Number(discount)/100 : 0.062;
  const years = view === "Advanced" && sessionStorage.getItem("years") ? Number(sessionStorage.getItem("years")) : 3;
  const presentValue = totalSavings / Math.pow(1 + discountRate, years);
  const NPV = presentValue - totalCost;
  const PBP = Math.round(presentValue / totalCost) + " Months";
  return type === "NPV" ? NPV : PBP;
};

export const generateOnePager = (dataPerDevice, currencySymbol, years) => {
  const doc = new jsPDF("p", "pt", "a4");

  const docWidth = doc.internal.pageSize.width;
  const docHeight = doc.internal.pageSize.height;
  const content = getPerDeviceContent(dataPerDevice, currencySymbol, years);

  let html = `
    <div class="pdf">
        ${getOnePagerContent(content, docWidth, docHeight, years)}
    </div>
  `; 

  if (['Kč', 'zł', '₫', '₹', '₱', '₩', '฿'].indexOf(currencySymbol) >= 0 ) {

    let customFont;

    if (['Kč', 'zł', '₫'].indexOf(currencySymbol) >= 0) {
      customFont = amiriFont;      
    } else if(currencySymbol === '₹') {
      customFont = rupeeFont;
    } else if(currencySymbol === '₱' || currencySymbol === '₩') {
      customFont = rayligFont;
    } 

    if (currencySymbol === '฿') {
      html = html.replaceAll(currencySymbol, 'THB')
    } else {
      doc.addFileToVFS("Custom-Regular.ttf", customFont);
      doc.addFont("Custom-Regular.ttf", "Custom", "normal");
      doc.setFont("Custom");
      html = html.replaceAll(currencySymbol, `<span style="font-family: Custom;font-weight:inherit;"> ${currencySymbol} </span>`);

    }
    
   
  }

  doc.html(html, {
    callback: function (doc) {  
      const docWidth = doc.internal.pageSize.width;
      const docHeight = doc.internal.pageSize.height;
      doc.setPage(1);
      doc.addImage(logo, "png", docWidth - 195, 20, 165, 20);
      doc.link(docWidth - 68, docHeight - 40 , 25, 10, {
        url: "https://aka.ms/SurfaceIDCWhitepaper",
      });
      // doc.save("TCO_Summary.pdf");
      // doc.output('dataurlnewwindow', 'TCO_Summary.pdf');
      doc.setProperties({
        title: "TCO Summary"
      });
      window.open(doc.output('bloburl'))
    },
    autoPaging: "text",
    margin: [0, 0, 0, 0],
  });
}

export const generatePDF = (reportData, dataPerDevice, chartData, currencySymbol, years, userInput) => {
  const doc = new jsPDF("p", "pt", "a4");
  const isProduction = false; 

  let html = getHTML(doc, reportData,dataPerDevice, chartData, currencySymbol, years, isProduction, userInput);

  if (['Kč', 'zł', '₫', '₹', '₱', '₩', '฿'].indexOf(currencySymbol) >= 0 ) {

    let customFont;

    if (['Kč', 'zł', '₫'].indexOf(currencySymbol) >= 0) {
      customFont = amiriFont;      
    } else if(currencySymbol === '₹') {
      customFont = rupeeFont;
    } else if(currencySymbol === '₱' || currencySymbol === '₩') {
      customFont = rayligFont;
    } 

    if (currencySymbol === '฿') {
      html = html.replaceAll(currencySymbol, 'THB')
    } else {
      doc.addFileToVFS("Custom-Regular.ttf", customFont);
      doc.addFont("Custom-Regular.ttf", "Custom", "normal");
      doc.setFont("Custom");
      html = html.replaceAll(currencySymbol, `<span style="font-family: Custom;font-weight:inherit;"> ${currencySymbol} </span>`);

    }
    
   
  }

  const pgNum = isProduction ? 3 : 4;

  doc.html(html, {
    callback: function (doc) {
      const docWidth = doc.internal.pageSize.width;
      const docHeight = doc.internal.pageSize.height;
      
      let totalPages = doc.internal.pages.length - 1;
      for (let i = 1; i <= totalPages; i++) {
        const noLogoIndex = [1, totalPages];

        doc.setPage(i);
        if (noLogoIndex.indexOf(i) === -1) {
          doc.addImage(logo, "png", docWidth - 195, 22.5, 165, 20);
        }

        if (!isProduction && i === 2) {
          doc.link(docWidth - 100, docHeight - 40 , 50, 10, {
            url: "https://aka.ms/SurfaceIDCWhitepaper",
          });
        }

        switch (i) {
          case 1:
            doc.link(60, 742.5, 200, 10, {
              url: "https://aka.ms/SurfaceIDCWhitepaper",
            });
            doc.addImage(logo, "png", 30, 490, 165, 20);
            break;
          case pgNum:
            const canvasImageChart1 = getCanvasData("#chart1 canvas");
            if(!canvasImageChart1){
              break;
            }
            doc.addImage(
              canvasImageChart1,
              "jpeg",
              30,
              170,
              275,
              250
            );
            break;
          case pgNum + 1:
            const canvasImageChart2 = getCanvasData("#chart2 canvas");
            if(!canvasImageChart2){
              break;
            }
            doc.addImage(
              canvasImageChart2,
              "jpeg",
              0,
              170,
              300,
              150
            );
            const canvasImageChart3 = getCanvasData("#chart3 canvas");
            if(!canvasImageChart3){
              break;
            }
            doc.addImage(
              canvasImageChart3,
              "jpeg",
              30,
              400,
              275,
              250
            );
            break;
          case pgNum + 2:
            const canvasImageChart4 = getCanvasData("#chart4 canvas");
            if(!canvasImageChart4){
              break;
            }
            doc.addImage(
              canvasImageChart4,
              "jpeg",
              20,
              170,
              275,
              250
            );
            break;
          case pgNum + 3:
            const canvasImageChart5 = getCanvasData("#chart5 canvas");
            if(!canvasImageChart5){
              break;
            }
            doc.addImage(
              canvasImageChart5,
              "jpeg",
              30,
              180,
              260,
              200
            );
            const canvasImageChart6 = getCanvasData("#chart6 canvas");
            if(!canvasImageChart6){
              break;
            }
            doc.addImage(
              canvasImageChart6,
              "jpeg",
              30,
              430,
              260,
              200
            );
            break;
          case pgNum + 4:
            const canvasImageChart7 = getCanvasData("#chart7 canvas");
            if(!canvasImageChart7){
              break;
            }
            doc.addImage(
              canvasImageChart7,
              "jpeg",
              30,
              170,
              275,
              250
            );
            break;
            case pgNum + 6:
            doc.addImage(
              logo,
              "png",
              (docWidth - 340) / 2,
              730,
              340,
              40
            );
            break;
          default:
            break;
        }
      }
      doc.save("TCO_Calculation_Full_Report.pdf");
    },
    autoPaging: "text",
    margin: [0, 0, 0, 0],
  });
};

const getPerecentage = (value1, value2) => {
  const percentage = Math.round((value1 * 100) / value2);

  return value2 === 0 ? 0 : Math.abs(percentage);
}

const getPerDeviceContent = (data, currencySymbol, noOfYears = 3) => {
  
  const reportData = {
    'deviceCosts': data.totalCosts.rows.find(item => item.label === 'Total Device Costs') || {
      "label": "Total Device Costs",
      "surfaceValue": 0,
      "pcValue": 0,
      "savings": 0
    },
   'M365LicenseCost' : data.totalCosts.rows.find(item => item.label === 'Total M365 Licensing Costs') || {
    "label": "Total M365 Licensing Costs",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'accessoriesCosts' : data.totalCosts.rows.find(item => item.label === 'Total Accessories Cost') || {
    "label": "Total Accessories Cost",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'maintenanceCosts': data.totalCosts.rows.find(item => item.label === 'Total Extended Warranty & Maintenance Costs') || {
    "label": "Total Extended Warranty & Maintenance Costs",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'accessoriesSavings' : data.totalSavings.rows.find(item => item.label === 'Accessories Savings') || {
    "label": "Accessories Savings",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'incResidualVal': data.totalSavings.rows.find(item => item.label === 'Increased Residual Value') || {
    "label": "Increased Residual Value",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'reducedSupport': data.totalSavings.rows.find(item => item.label === 'Reduced Support') || {
    "label": "Reduced Support",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'deviceConsolidation': data.itSimplification.rows.find(item => item.label === 'Device Consolidation') || {
    "label": "Device Consolidation",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'reducedTimeForSecurityIncident': data.itSimplification.rows.find(item => item.label === 'Reduced Time for Security Incident Response') || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Reduced Time for Security Incident Response",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'reducedHDSupport': data.itSimplification.rows.find(item => item.label === 'Reduced Helpdesk Support') || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Reduced Helpdesk Support",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'reducedOngoingMaint': data.itSimplification.rows.find(item => item.label === 'Reduced Ongoing Maintenance') || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Reduced Ongoing Maintenance",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'reducedITDeploymentCost': data.itSimplification.rows.find(item => item.label === 'Reduced IT Deployment Costs') || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Reduced IT Deployment Costs",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'reducedTimeForSecurity': data.itSimplification.rows.find(item => item.label === 'Reduced IT Staff Time for Ongoing Security') || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Reduced IT Staff Time for Ongoing Security",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'trainingCost': data.itSimplification.rows.find(item => item.label === 'IT Training Costs') || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "IT Training Costs",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'fasterSurfDeployment': data.employeeEmpowerment.rows.find(item => item.label === "Faster Surface Deployment to Employees") || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Faster Surface Deployment to Employees",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'higherEmployeeRetention': data.employeeEmpowerment.rows.find(item => item.label === "Higher Employee Retention") || {
    "label": "Higher Employee Retention",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'reducedEmpTimeLoss': data.employeeEmpowerment.rows.find(item => item.label === "Reduced Employee Time Lost to Security Incidents") || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Reduced Employee Time Lost to Security Incidents",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
   'fasterBootupTime': data.employeeEmpowerment.rows.find(item => item.label === "Faster Bootup Time") || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Faster Bootup Time",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'productivityGains': data.employeeEmpowerment.rows.find(item => item.label === "Productivity Gains from Increased Mobility") || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Productivity Gains from Increased Mobility",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'employeeTimeLosttoHelpdesk': data.employeeEmpowerment.rows.find(item => item.label === "Reduced Employee Time Lost to Helpdesk") || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Reduced Employee Time Lost to Helpdesk",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'meetingTime': data.employeeEmpowerment.rows.find(item => item.label === "Meeting Time Saved") || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Meeting Time Saved",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'bootupTime': data.employeeEmpowerment.rows.find(item => item.label === "Faster Bootup Time") || {
    "surfaceHours": 0,
    "pcHours": 0,
    "hoursSavings": 0,
    "label": "Faster Bootup Time",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'energySavings' : data.sustainability.rows.find(item => item.label === 'Total Energy Consumption Savings') || {
    "label": "Total Energy Consumption Savings",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  'carbonSavings' : data.sustainability.rows.find(item => item.label === 'Total Carbon Emissions Savings') || {
    "label": "Total Carbon Emissions Savings",
    "surfaceValue": 0,
    "pcValue": 0,
    "savings": 0
  },
  };


  const content  = {
    'avgSavings': formatCurrency(data.totalSavings.savings, currencySymbol, 0),
    'ROI': data.ROI % 1 === 0 ? data.ROI : data.ROI.toFixed(1),
    'additionalSavings': formatCurrency((data.itSimplification.savings + data.employeeEmpowerment.savings), currencySymbol, 0),
    'totalCosts': {
      'data': [
        {
          'value': formatCurrency(reportData.deviceCosts.surfaceValue, currencySymbol, 0),
          'label': 'Surface Device',
          'subLabel': null
        },
        {
          'value': formatCurrency(reportData.M365LicenseCost.surfaceValue, currencySymbol, 0),
          'label': 'Microsoft 365 Subscription',
          'subLabel': `${formatCurrency((reportData.M365LicenseCost.surfaceValue / ( 12 * noOfYears)), currencySymbol, 0)} per user per month. License cost doesn't change across device manufacturers`
        },
        {
          'value': formatCurrency(reportData.accessoriesCosts.surfaceValue, currencySymbol, 0),
          'label': 'Accessories',
          'subLabel': null
        },
        {
          'value': formatCurrency(reportData.maintenanceCosts.surfaceValue, currencySymbol, 0),
          'label': 'Extended Warranty & Maintenance',
          'subLabel': null
        }
      ],
      'total': {
        'value': formatCurrency(data.totalCosts.surfaceValue, currencySymbol, 0),
        'label': 'Total Cost per Surface device',
        'subLabel': `<br/>(incl. accessories) over ${noOfYears} years`
      }
    },
    'totalSavings': {
      'data': [
        {
          'value': formatCurrency(reportData.incResidualVal.savings, currencySymbol, 0),
          'label': 'Increased Residual Value',
          'subLabel': `${ getPerecentage(reportData.incResidualVal.savings, reportData.incResidualVal.pcValue)}% higher than other PC devices`
        },
        {
          'value': formatCurrency(reportData.reducedSupport.savings, currencySymbol, 0),
          'label': 'Reduced Support',
          'subLabel': `${ getPerecentage(reportData.reducedSupport.savings, reportData.reducedSupport.pcValue)}% lower third-party support & security costs`
        },
        {
          'value': formatCurrency(reportData.accessoriesSavings.savings, currencySymbol, 0),
          'label': 'Accessories Savings',
          'subLabel': 'Fewer hybrid meeting accessories (Camera, Lights, Headset)'
        }
      ],
      'total': {
        'value': formatCurrency(data.totalSavings.savings, currencySymbol, 0),
        'label': 'Total Savings per Surface device',
        'subLabel': `<br/>(incl. accessories) over ${noOfYears} years`
      }
    },
    'itSimplification': {
      'data': [
        {
          'value': formatCurrency(reportData.deviceConsolidation.savings, currencySymbol, 0),
          'label': 'Device Consolidation',
          'subLabel': `${ getPerecentage(reportData.deviceConsolidation.savings, reportData.deviceConsolidation.pcValue)}% of organizations consolidated 2 .3 devices on avg`
        },
        {
          'value': formatCurrency(reportData.reducedHDSupport.savings, currencySymbol, 0),
          'label': 'Reduced Helpdesk Support',
          'subLabel': `${ getPerecentage(reportData.reducedHDSupport.savings, reportData.reducedHDSupport.pcValue)}% fewer helpdesk incidents`
        },
        {
          'value': formatCurrency(reportData.reducedOngoingMaint.savings, currencySymbol, 0),
          'label': 'Reduced Ongoing Maintenance',
          'subLabel': `${ getPerecentage(reportData.reducedOngoingMaint.savings, reportData.reducedOngoingMaint.pcValue)}% reduced IT Staff time spent on maintenance`
        },
        {
          'value': formatCurrency(reportData.reducedTimeForSecurityIncident.savings, currencySymbol, 0),
          'label': 'Reduced Time for Security Incident Response',
          'subLabel': `${ getPerecentage(reportData.reducedTimeForSecurityIncident.savings, reportData.reducedTimeForSecurityIncident.pcValue)}% fewer security incidents`
        },
        {
          'value': formatCurrency(reportData.reducedITDeploymentCost.savings, currencySymbol, 0),
          'label': 'Reduced IT Deployment Costs',
          'subLabel': `${ getPerecentage(reportData.reducedITDeploymentCost.savings, reportData.reducedITDeploymentCost.pcValue)}% reduced IT Staff time spent on deployment`
        },
        {
          'value': formatCurrency(reportData.reducedTimeForSecurity.savings, currencySymbol, 0),
          'label': 'Reduced IT Staff Time for Ongoing Security',
          'subLabel': `${ getPerecentage(reportData.reducedTimeForSecurity.savings, reportData.reducedTimeForSecurity.pcValue)}% reduced IT Staff time spent on ongoing security`
        },
        {
          'value': formatCurrency(reportData.trainingCost.savings, currencySymbol, 0),
          'label': 'Higher IT Training Costs',
          'subLabel': `${ getPerecentage(reportData.trainingCost.savings, reportData.trainingCost.pcValue)}% increased IT Staff time due to Surface training`
        }
      ],
      'total': {
        'value': formatCurrency(data.itSimplification.savings, currencySymbol, 0),
        'label': 'Total IT Benefit per Surface device',
        'subLabel': `<br/>(over ${noOfYears} years)`
      }
    },
    'employeeEmpowerment': {
      'data': [
        {
          'value': formatCurrency(reportData.productivityGains.savings, currencySymbol, 0),
          'label': 'Productivity Gains from Increased Mobility',
          'subLabel': '+11.6 hours Employee-productivity time per year'
        },
        {
          'value': formatCurrency(reportData.employeeTimeLosttoHelpdesk.savings, currencySymbol, 0),
          'label': 'Reduced Employee Time Lost to Helpdesk',
          'subLabel': '+5.5 hours Employee-productivity time per year'
        },
        {
          'value': formatCurrency(reportData.meetingTime.savings, currencySymbol, 0),
          'label': 'Meeting Time Saved',
          'subLabel': `+7.7 hours Employee-productivity time per year`
        },
        {
          'value': formatCurrency(reportData.reducedEmpTimeLoss.savings, currencySymbol, 0),
          'label': 'Reduced Employee Time Lost to Security Incidents',
          'subLabel': `${ getPerecentage(reportData.reducedEmpTimeLoss.savings, reportData.reducedEmpTimeLoss.pcValue)}% reduced time waiting for incident resolution`
        },
        {
          'value': formatCurrency(reportData.fasterSurfDeployment.savings, currencySymbol, 0),
          'label': 'Faster Surface Deployment to Employees',
          'subLabel': `${ getPerecentage(reportData.fasterSurfDeployment.savings, reportData.fasterSurfDeployment.pcValue)}% reduced Employee time waiting for device`
        },
        {
          'value': formatCurrency(reportData.higherEmployeeRetention.savings, currencySymbol, 0),
          'label': 'Higher Employee Retention',
          'subLabel': `${ getPerecentage(reportData.higherEmployeeRetention.savings, reportData.higherEmployeeRetention.pcValue)}% increased retention of Surface-utilizing employees`
        },
        {
          'value': formatCurrency(reportData.bootupTime.savings, currencySymbol, 0),
          'label': 'Faster Bootup Time',
          'subLabel': `${ getPerecentage(reportData.fasterBootupTime.savings, reportData.fasterBootupTime.pcValue)}% faster boot time, 21% fewer reboots with Surface`
        }
      ],
      'total': {
        'value': formatCurrency(data.employeeEmpowerment.savings, currencySymbol, 0),
        'label': 'Total Employee Benefit per Surface device',
        'subLabel': `(over ${noOfYears} years)`
      }
    },
    'sustainability': {
      'totalSavings': formatCurrency(data.sustainability.savings, currencySymbol, 0),
      'energySavings': formatCurrency(reportData.energySavings.savings, currencySymbol, 0),
      'carbonSavings': formatCurrency(reportData.carbonSavings.savings, currencySymbol, 0),
      'isNotEmpty': data.sustainability.rows.length > 0,
    }
  };

  return content;
}

const getOnePagerContent = (content, docWidth, docHeight, year) => {

  return `
  <div style="width:${docWidth}px; height:${docHeight - 5}px;">
    <div class="content_section one_pager">
      <p class="main_title"> 
        The Business Case for Microsoft in Your Organization
      </p>
      <p> 
        Based on your calculation inputs and assumptions  
      </p>
      <div style="margin-top:10px">
          <p class="sub_title" style="margin:5px 0; padding:0;"> THE COSTS & SAVINGS </p>
          <p class="main_title_sub" style="margin:0"> 
              Over ${year === 1 ? `1 year` :  `${year} years`}, organizations can save an average of ${content.avgSavings} per device with Surface 
          </p>
          <p class="title_right"> (powered by Microsoft 365)</p>
          <div class="d-flex p-table">
              ${getPriceTable(content, 'totalCosts', true)}
              ${getPriceTable(content, 'totalSavings', true)}
          </div>
      </div>
      <div style="margin-top:10px">
          <p class="sub_title" style="margin:10px 0 7px; padding:0;"> ADDITIONAL BENEFITS </p>
          <div class="d-flex  p-table">
              ${getPriceTable(content, 'itSimplification', false)}
              ${getPriceTable(content, 'employeeEmpowerment', false)}
          </div>
          ${content.sustainability.isNotEmpty ? 
            `<div class="sus-savings">
              <div><img src="${LeafImage}" alt="leaf"/></div>
              <div>Save up to ${content.sustainability.energySavings} across energy consumption and up to ${content.sustainability.carbonSavings} on carbon emissions for sustainability savings totalling up to ${content.sustainability.totalSavings}.</div>
            </div>`
          : `<div></div>`}
      </div>
      <div class="footer-text sub_title" >
          Up to ${content.ROI}X ROI with Surface + Microsoft 365 from ${content.additionalSavings} in additional benefits per Surface device
      </div>
      <div class=${content.sustainability.isNotEmpty ? "methodolgy-sec-minimized" : "methodolgy-sec"}>
          <p class="title">METHODOLOGY</p>
          <p>
          The results above are based on customer inputs to IDC's study "Evaluating the Business Case of Microsoft Surface", commissioned by Microsoft. IDC Research Study conducted from surveys and interviews between December 2021 - February 2022. All respondents were IT decision-makers at large
            organizations (250-5000+ employees) representing organizations from the United States, Australia, India, Spain, France, United Kingdom, New Zealand,
            and Germany. Cost & Savings findings based on average cost and time estimates provided directly by respondents; actual costs and savings may vary
            based on your specific Device Mix and deployment.
          </p>
          <p>
            *Data point derived from 17 in-depth interviews. All other data points derived from 800 survey results (700 Surface organizations with at least 150 Surface
            Laptop and 2-in-1 Tablets available since 2019, 100 non-Surface organizations). Surface Residual Value adjusted by IDC to reflect average after 36 months.
          </p>
          <div class='bubble-sec'>
              <span>
                For detailed report, <a>click here</a>
              </span>
          </div>
      </div>
    </div>
    
  </div>
`;
}

const getHTML = (doc, reportData, dataPerDevice, chartData, currencySymbol, years, isProduction, userInput) => {
  const docWidth = doc.internal.pageSize.width;
  const docHeight = doc.internal.pageSize.height;
  const content = getPerDeviceContent(dataPerDevice, currencySymbol, years);

  const pageTwoHTML = isProduction ? ` ` : getOnePagerContent(content, docWidth, docHeight, years);
    
  
  return `
    <div class="pdf">

      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div>
            <img style="width:${docWidth}px; height:455px;" src="${PDFLandingImg}" />
        </div>
        <div class="content_section">
          <div class="mb-30" style="width:165px; height:20px;"> </div>
          <div class="flex_col" style="height:${docHeight - 565}px;">
            <div>
              <p class="report_title"> The Business Case </p>
              <p class="title_1"> Total Cost of Ownership (TCO) Report </p>
            </div>
            <div>
              <p style="margin-bottom:10px">
                The TCO tool provides you with a report that includes estimates of potential costs, savings, and ROI you may achieve by replacing other PC deviceswith Surface devices and M365. Default values provided in the TCO tool are based on the study "<a>Evaluating the Business Case of Microsoft Surface</a>" conducted by IDC and commissioned by Microsoft in 2022.
              </p>
              <p>
                The TCO report provided to you is for informational purposes only. You should not interpret the report you receive to be a commitment on the part of Microsoft; actual costs and savings may vary based on your location, purchase method, deployment, usage, and other factors. Neither Microsoft nor IDC makes any representations or warranties, express or implied, as to the information within this website and report.
              </p>
            </div>
          </div>
          
        </div>
      </div>

      ${pageTwoHTML}

      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section">
          <p class="main_title"> Methodology </p>
          <div style="height:140px;margin:0; margin-bottom: 10px; padding:0 10px">
              <img style="width:${
                docWidth - 82.5
              }px; height:140px;" src="${Page3Img1}" />
          </div>
          <div style="height:125px;margin:0;  margin-bottom: 15px; padding:0 10px; text-align: center;">
              <img style="width:${
                (docWidth - 82.5) / 2
              }px; height:125px;" src="${Page3Img2}" />
          </div>
          <div style="height:220px;margin:0;padding:0 10px">
              <img style="width:${
                docWidth - 82.5
              }px; height:220px;" src="${Page3Img3}" />
          </div>
          <div style="height:225px;margin:0;padding:0 10px">
              <img style="width:${
                docWidth - 82.5
              }px; height:225px;" src="${Page3Img4}" />
          </div>

        </div>
      </div>

      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section" >
            <p class="main_title">The Costs</p>
            <p class="main_title_sub"> OVER ${years} YEARS</p>
            <div>
                <p class="sub_title" style="width:${
                  docWidth - 62.5
                }px;"> ${years}-Year Total Costs</p>
                ${getBigTableChartHTML(
                  reportData,
                  "totalCosts",
                  currencySymbol,
                  docWidth,
                  "",
                  years
                )}
            </div>
        </div>
      </div>

      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section" >
            <p class="main_title">The Savings</p>
            <p class="main_title_sub"> OVER ${years} YEARS</p>
            <div>
                <p class="sub_title" style="width:${
                  docWidth - 62.5
                }px;"> Total Surface Savings And Benefits</p>
                <div class="chart_sec">
                  <div>
                      <div style="width:275px; height:150px"> </div>
                  </div>
                  <div style="width:${docWidth - 337.5}px;">
                        <p class="price_text"> ${formatCurrency(
                          reportData.savings,
                          currencySymbol
                        )}</p>
                        
                        <table style="width:100%;font-size:9px">
                          <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                            <td style="display:flex; align-items: center;"> 
                              <div class="marker-355 marker" style="background-color: rgb(0, 75, 28);"></div>
                              Total Direct Savings
                            </td>
                            <td> ${formatCurrency(
                              reportData.totalSavings?.savings,
                              currencySymbol
                            )} </td>
                            
                          </tr>
                          <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                            <td style="display:flex; align-items: center;"> 
                              <div class="marker-355 marker" style="background-color: rgb(186, 216, 10);"></div>
                              Total IT Efficiency Benefits 
                            </td>
                            <td> ${formatCurrency(
                              reportData.itSimplification?.savings,
                              currencySymbol
                            )} </td>
                            
                          </tr>
                          <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                            <td style="display:flex; align-items: center;"> 
                              <div class="marker-355 marker" style="background-color: rgb(16, 124, 16);"></div>
                              Total Employee Experience Benefits 
                            </td>
                            <td> ${formatCurrency(
                              reportData.employeeEmpowerment?.savings,
                              currencySymbol
                            )} </td>
                            
                          </tr>
                          <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                            <td style="display:flex; align-items: center;"> 
                              <div class="marker-355 marker" style="background-color: rgb(80, 196, 51);"></div>
                              Total Sustainability Savings
                            </td>
                            <td> ${formatCurrency(
                              reportData.sustainability?.savings,
                              currencySymbol
                            )} </td>
                            
                          </tr>
                          <tr>
                            <td> 
                              <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
                              Total 
                            </td>
                            <td> ${formatCurrency(
                              reportData.savings,
                              currencySymbol
                            )} </td>
                            
                          </tr>
                        </table>
                        
                  </div>
                </div>
            </div>
            <div>
                <p class="sub_title" style="width:${
                  docWidth - 62.5
                }px;"> ${years} Year Total Direct Savings</p>
                ${getBigTableChartHTML(
                  reportData,
                  "totalSavings",
                  currencySymbol,
                  docWidth,
                  "",
                  years
                )}
            </div>
        </div>
      </div>
      
      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section" >
          <p class="main_title">The Savings</p>
          <p class="main_title_sub"> OVER ${years} YEARS</p>
          <div>
                <p class="sub_title" style="width:${
                  docWidth - 62.5
                }px;"> IT Efficiency</p>
                <div class="chart_sec">
                  <div>
                      <div style="width:270px; height:250px" ></div>
                  </div>
                  <div style="width:${docWidth - 337.5}px;">
                      <p class="price_text"> ${formatCurrency(
                        reportData.itSimplification?.savings,
                        currencySymbol
                      )}</p>
                      <p class="title_text"> Expected IT-Related Savings </p>
                      <p class="price_text"> ${formatNumberWithUnit(
                        reportData.itSimplification?.hoursSavings,
                        null,
                        0
                      )}</p>
                      <p class="title_text"> Expected Hour Savings Per IT Employee </p>
                      <p class="desc_text">
                        Your ${years} year total IT Efficiency Benefits take into consideration device consolidation, help desk support, time to resolve security incidents, ongoing maintenance, IT deployment costs, IT training costs, and ongoing IT security. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.   
                      </p>
                  </div>
                </div>

                <div style="width:${docWidth - 337.5}px;">
                    ${getTwoColTableHTML(
                      reportData,
                      "itSimplification",
                      currencySymbol,
                      docWidth
                    )}
                </div>
          </div>
        </div>
      </div>

      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section" >
          <p class="main_title">The Savings</p>
          <p class="main_title_sub"> OVER ${years} YEARS</p>
          <div>              
            <p class="sub_title" style="width:${
              docWidth - 62.5
            }px;"> IT Hours Saved Due To Reduced Help Desk And Security Incidents</p>
            <div >
              <div class="chart_sec">
                  <div style="width:275px; height:250px"> 
                    <p class="desc_text" style="width: 275px; text-align: center"> Help Desk Incidents  </p>
                  </div>
                  <div class="vh-center" style="height:250px; padding-left: 15px;">
                    <div>
                      <p class="price_text"> ${formatNumberWithUnit(
                        chartData.incidentsSaving.helpDesk?.hoursSavings,
                        null,
                        0
                      )} hours</p>
                      <p class="title_text"> Fewer Helpdesk Incidents </p>
                    </div>
                  </div>
              </div>
              <div class="chart_sec">
                  
                  <div style="width:275px; height:250px"> 
                    <p class="desc_text" style="width: 275px; text-align: center">Security Incidents </p>
                  </div>
                  <div class="vh-center" style="height:250px; padding-left: 15px;">
                    <div>
                      <p class="price_text"> ${formatNumberWithUnit(
                        chartData.incidentsSaving.security?.hoursSavings,
                        null,
                        0
                      )} hours</p>
                      <p class="title_text"> Fewer Security Incidents </p> 
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section" >
          <p class="main_title">The Savings</p>
          <p class="main_title_sub"> OVER ${years} YEARS</p>
          <div>
              <div>
                  <p class="sub_title" style="width:${
                    docWidth - 62.5
                  }px;"> Employee Experience Savings</p>
                  <div class="chart_sec">
                    <div>
                      <div style="width:275px; height:250px"> </div>
                    </div>
                    <div style="width:${docWidth - 337.5}px;">
                          <p class="price_text"> ${formatCurrency(
                            reportData.employeeEmpowerment.savings,
                            currencySymbol
                          )}</p>
                          <p class="title_text"> Expected Savings </p>
                          <p class="price_text"> ${formatNumberWithUnit(
                            reportData.employeeEmpowerment.hoursSavings,
                            null,
                            0
                          )}</p>
                          <p class="title_text"> Expected Hour Savings Per Employee  </p>
                          <p class="desc_text">
                            Your ${years} year total Employee Experience Benefits take into consideration productivity gains from increased mobility, time spent on help desk resolution, meeting time saved, time spent on security incident resolution, Surface deployment to employees, employee retention, and boot up time. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.         
                          </p>
                          
                    </div>
                  </div> 
                  <div >
                      ${getTwoColTableHTML(
                        reportData,
                        "employeeEmpowerment",
                        currencySymbol,
                        docWidth
                      )}
                  </div>               
              </div>
          </div>
        </div>
      </div>

      <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section" >
          <p class="main_title">The Savings</p>
          <p class="main_title_sub"> OVER ${years} YEARS</p>
          <div>
              <div>
                  <p class="sub_title" style="width:${
                    docWidth - 62.5
                  }px;"> Sustainability Savings</p>
                  <div class="chart_sec">
                    <div style="width:${docWidth - 337.5}px;">
                      <p class="price_text"> ${formatCurrency(
                        reportData.sustainability.savings,
                        currencySymbol
                      )}</p>
                      <p class="title_text"> Expected Savings </p>
                    </div>
                  </div> 
                  <div>
                      ${getEcoTableHTML(
                        reportData,
                        currencySymbol,
                        docWidth,
                        userInput
                      )}
                  </div>               
              </div>
          </div>
        </div>
      </div>

      <div style="width:${docWidth}px; height:${docHeight - 15}px;">
        <div style="height:440px;margin:10px 0 0;">
            <img style="width:${docWidth}px; height:440px;" src="${LPImage1}" />
        </div>
        <div class="vh-center" style="width:${docWidth}px; height:${docHeight - 675}px;">
          <div  style="width:340px;" ></div>
        </div>
      </div>

    </div>
  `;
};

const getPriceTable = (
  content,
  section,
  isLargeFont
) => {

 
  const title = {
    'totalCosts' : 'Costs',
    'totalSavings': 'Savings',
    'itSimplification': 'IT Efficiency',
    'employeeEmpowerment': 'Employee Experience'
  };
  

  return `
    <div class="pricing-table">
        <div >
          <p class="highlight"> ${title[section]} </p>
          <ul >
              ${
                content[section].data.map(item =>  `
                  <li class="d-flex">
                    <span class="t-amount m0"> ${item.value} </span>
                    <div>
                        <p class="t-title m0" ${isLargeFont ? `style='font-size: 10px'` : ``}>${item.label}</p>
                        ${item.subLabel ? `<p class="t-sub-title ">${item.subLabel}</p>` : ``} 
                    </div>
                  </li>
                `).join("")
              }
          </ul>
        </div>  
        <ul>
            <li class="d-flex table-total">
              <span class="t-amount"> ${content[section].total.value} </span>
              <div>
                  <p class="t-title m0">
                    ${content[section].total.label}
                    <span class="t-sub-title "> ${content[section].total.subLabel} </span>
                  </p>

              </div>
            </li> 
        </ul>
        
    </div>
  `;
}

const getTwoColTableHTML = (
  reportData,
  fieldName,
  currencySymbol,
  docWidth
) => {
  const bubbleColors = {
    itSimplification: TCO_REPORT_CHART_COLOR.it.reverse(),
    employeeEmpowerment: TCO_REPORT_CHART_COLOR.employee,
  };

  return `
  <table style="width:${docWidth - 62.5}px;font-size:9px">
  <tr>
      <th > </th>
      <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Surfaces</th>
      <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Other PCs </th>
      <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Savings </th>
  </tr>
  ${reportData[fieldName].rows
    .map(
      (item, i) => `
        <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
            <td style="display:flex; align-items: center;"> 
              <div class="marker-355 marker" style="background-color: ${
                bubbleColors[fieldName][i]
              } ;"></div>
              ${item.label}
            </td>
            <td> ${formatCurrency(item.surfaceValue, currencySymbol)} </td>
            <td> ${
              item.surfaceHours
                ? `${formatNumberWithUnit(item.surfaceHours, null, 0)}hr`
                : `N/A`
            } </td>
            <td> ${formatCurrency(item.pcValue, currencySymbol)} </td>
            <td> ${
              item.pcHours
                ? `${formatNumberWithUnit(item.pcHours, null, 0)}hr`
                : `N/A`
            } </td>
            <td> ${formatCurrency(item.savings, currencySymbol)} </td>
            <td> ${
              item.hoursSavings
                ? `${formatNumberWithUnit(item.hoursSavings, null, 0)}hr`
                : `N/A`
            } </td>
        </tr>
    `
    )
    .join("")}
  <tr>
    <td valign="middle"> 
      <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
      Total 
    </td>
    <td> ${formatCurrency(
      reportData[fieldName].surfaceValue,
      currencySymbol
    )} </td>
    <td> ${formatNumberWithUnit(
      reportData[fieldName].surfaceHours,
      null,
      0
    )}hr</td>
    <td> ${formatCurrency(reportData[fieldName].pcValue, currencySymbol)} </td>
    <td> ${formatNumberWithUnit(reportData[fieldName].pcHours, null, 0)}hr</td>
    <td> ${formatCurrency(reportData[fieldName].savings, currencySymbol)} </td>
    <td> ${formatNumberWithUnit(
      reportData[fieldName].hoursSavings,
      null,
      0
    )}hr</td>
  </tr>
</table>
  `;
};

const getEcoTableHTML = (
  reportData,
  currencySymbol,
  docWidth,
  userInput
) => {
  const bubbleColors = {
    sustainability: TCO_REPORT_CHART_COLOR.employee,
  };

  let sustainabilityUserInput = userInput.sustainability;
  let quantity = sessionStorage.getItem("showPerDevice") === "true" ? 1 : convertStringToNumber(userInput.deviceMixPortfolio[0]?.quantity);
  let years = sessionStorage.getItem("years") ? Number(sessionStorage.getItem("years")) : 3;

  const dataObject = {
    Energy: {
      surfaceCol2: convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) * quantity * years,
      otherPcCol2: convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) * quantity * years,
      savingsCol2: (convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) * quantity * years) - (convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) * quantity * years),
    },
    CarbonEmissions: {
      surfaceCol2: convertStringToNumber(sustainabilityUserInput.CarbonEmissions.surfaceValue) * quantity * years,
      otherPcCol2: convertStringToNumber(sustainabilityUserInput.CarbonEmissions.otherPcValue) * quantity * years,
      savingsCol2: (convertStringToNumber(sustainabilityUserInput.CarbonEmissions.otherPcValue) * quantity * years) - (convertStringToNumber(sustainabilityUserInput.CarbonEmissions.surfaceValue) * quantity * years),
    },
  };

  return `
  <table style="width:${docWidth - 62.5}px;font-size:9px">
  <tr>
      <th > </th>
      <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Surfaces</th>
      <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Other PCs </th>
      <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Savings </th>
  </tr>
  ${reportData.sustainability.rows
    .map(
      (item, i) => `
        <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
            <td style="display:flex; align-items: center;"> 
              <div class="marker-355 marker" style="background-color: ${
                bubbleColors.sustainability[i]
              } ;"></div>
              ${item.label}
            </td>
            <td> ${formatCurrency(item.surfaceValue, currencySymbol)} </td>
            <td> ${
              item.label === "Total Energy Consumption Savings"
                ? `${dataObject.Energy.surfaceCol2} Kwh`
                : `${dataObject.CarbonEmissions.surfaceCol2} MTCO2`
            } </td>
            <td> ${formatCurrency(item.pcValue, currencySymbol)} </td>
            <td> ${
              item.label === "Total Energy Consumption Savings"
                ? `${dataObject.Energy.otherPcCol2} Kwh`
                : `${dataObject.CarbonEmissions.otherPcCol2} MTCO2`
            } </td>
            <td> ${formatCurrency(item.savings, currencySymbol)} </td>
            <td> ${
              item.label === "Total Energy Consumption Savings"
                ? `${dataObject.Energy.savingsCol2} Kwh`
                : `${dataObject.CarbonEmissions.savingsCol2} MTCO2`
            } </td>
        </tr>
    `
    )
    .join("")}
  <tr>
    <td valign="middle"> 
      <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
      Total 
    </td>
    <td> ${formatCurrency(reportData.sustainability.surfaceValue, currencySymbol)} </td>
    <td></td>
    <td> ${formatCurrency(reportData.sustainability.pcValue, currencySymbol)} </td>
    <td></td>
    <td> ${formatCurrency(reportData.sustainability.savings, currencySymbol)} </td>
    <td></td>
  </tr>
</table>
  `;
};

const getBigTableChartHTML = (
  reportData,
  fieldName,
  currencySymbol,
  docWidth,
  selector,
  years
) => {
  const bubbleColors = {
    totalCosts: TCO_REPORT_CHART_COLOR.totalCosts,
    totalSavings: TCO_REPORT_CHART_COLOR.totalSavings,
  };

  const subTitle = {
    totalCosts: "Expected Costs ",
    totalSavings: "Expected Savings ",
  };

  const description = {
    totalCosts:
      "These costs take into consideration device costs, accessories costs, M365 Licensing costs, and extended warranty & maintenance costs in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.",
    totalSavings:
      `Your ${years} year total savings take into consideration the increased residual value, reduced support costs, and accessories savings in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
  };

  const dataRowsHTML = reportData[fieldName].rows
    .map(
      (item, i) => `
        <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
            <td style="display:flex; align-items: center;"> 
              <div class="marker-355 marker" style="background-color: ${
                bubbleColors[fieldName][i]
              } ;"></div>
              ${item.label}
            </td>
            <td> ${formatCurrency(item.surfaceValue, currencySymbol)} </td>
            <td> ${formatCurrency(item.pcValue, currencySymbol)} </td>
            <td> ${formatCurrency(item.savings, currencySymbol)} </td>
        </tr>
    `
    )
    .join("");

  return `
  <div class="chart_sec" style="margin-bottom:5px">
    <div>
        <div style="width:275px; height:255px"> </div>
    </div>
    <div style="width:${docWidth - 337.5}px;">
          <p class="price_text"> ${formatCurrency(
            fieldName === 'totalSavings' ? reportData[fieldName].savings : reportData[fieldName].surfaceValue,
            currencySymbol
          )}</p>
          <p class="title_text"> ${subTitle[fieldName]} </p>
          <p class="desc_text">
           ${description[fieldName]}            
          </p>
          
    </div>
  </div>
  <table style="width:${docWidth - 62.5}px;font-size:10px">
    <tr>
        <th > </th>
        <th style="width:100px"> Surfaces</th>
        <th style="width:100px"> Other PCs </th>
        <th style="width:100px"> Savings </th>
    </tr>
    ${dataRowsHTML}
    <tr>
      <td> 
        <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
        Total 
      </td>
      <td> ${formatCurrency(
        reportData[fieldName].surfaceValue,
        currencySymbol
      )} </td>
      <td> ${formatCurrency(
        reportData[fieldName].pcValue,
        currencySymbol
      )} </td>
      <td> ${formatCurrency(
        reportData[fieldName].savings,
        currencySymbol
      )} </td>
    </tr>
  </table>
  `;
};

const getCanvasData = (selector) => {
  const canvas = document.querySelector(selector);
  if(!canvas){
    return;
  }
  const ctx = canvas.getContext("2d");
  ctx.globalCompositeOperation = "destination-over";
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  const canvasImage = canvas.toDataURL("image/jpeg", 1.0);

  return canvasImage;
};

export const trackGAEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  })
}
