import { Stack, StackItem, Text } from "@fluentui/react";
import { FC } from "react";
import { Image, makeStyles } from "@fluentui/react-components";
import { TcoSuccessStory } from "v2/types/apiResponseTypes";

const useStyles = makeStyles({
  container: {
    width: "40%",
  },
  sectionHeading: {
    color: "#005D9D",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "normal",
  },
});

const SuccessStory: FC<{ data: TcoSuccessStory }> = ({ data }) => {
  const styles = useStyles();
  return (
    <Stack
      verticalFill
      className={styles.container}
      tokens={{ childrenGap: 16 }}
      id="successStory"
    >
      <Image
        id="successStoryImg"
        alt={`${data?.title} - Success story`}
        src={
          data?.bannerImage
            ? require(`../../assets/tco/reportpage/${data.bannerImage}.png`)
            : ""
        }
      />
      <Text className={styles.sectionHeading} id="successStoryHeading">
        {data?.title}
      </Text>
      <Stack tokens={{ childrenGap: 20 }} id="description">
        {data?.paragraphs.map((paragraph, index) => (
          <Text key={index} id={"paragraph" + index}>
            {paragraph?.text}
          </Text>
        ))}
      </Stack>
    </Stack>
  );
};

export default SuccessStory;
