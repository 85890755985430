import { FC } from "react";
import { Stack, Text } from "@fluentui/react";
import { Image, makeStyles } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import customerInfoData from "v2/store/customerInfoData.json";
const useStyles = makeStyles({
  container: {
    background: "#F3F9FC",
  },
  sectionHeading: {
    color: "#005D9D",
    fontSize: "22px",
    fontWeight: "600",
    lineHeight: "normal",
  },
  divider: {
    background: "#6BA5D2",
    height: "1.5px",
  },
  paragraph: {
    padding: "24px",
  },
});

const ReportTitle: FC = () => {
  const styles = useStyles();
  const { customerInfo } = useTcoCalculationContext();
  const industry = customerInfoData.industries.find(
    (industry) => industry.name === customerInfo.industry
  );

  return (
    <Stack horizontal className={styles.container} id="industry">
      <Image
        id="industryImg"
        alt="image"
        height={233}
        width={330}
        src={require("../../assets/tco/reportpage/HeroImage_Report.png")}
      />
      <Stack tokens={{ childrenGap: 10 }} className={styles.paragraph}>
        <Text className={styles.sectionHeading} id="industryHeading">
          {industry?.headline}
        </Text>
        <div className={styles.divider} />
        <Text id="description">{industry?.description}</Text>
      </Stack>
    </Stack>
  );
};

export default ReportTitle;
