import { FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Message = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M29.3333 1.20018e-09H2.66667C1.95942 1.20018e-09 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 21.3333C0 22.0406 0.280952 22.7189 0.781049 23.219C1.28115 23.719 1.95942 24 2.66667 24H8V30.5516C8 30.7059 8.04576 30.8567 8.13149 30.985C8.21722 31.1133 8.33907 31.2133 8.48163 31.2724C8.62419 31.3314 8.78105 31.3469 8.9324 31.3168C9.08374 31.2867 9.22275 31.2124 9.33187 31.1033L16.4351 24H29.3333C29.6835 24 30.0303 23.931 30.3538 23.797C30.6774 23.663 30.9713 23.4666 31.219 23.219C31.4666 22.9713 31.663 22.6774 31.797 22.3538C31.931 22.0303 32 21.6835 32 21.3333V2.66667C32 2.31647 31.931 1.9697 31.797 1.64617C31.663 1.32263 31.4666 1.02865 31.219 0.781025C30.9713 0.5334 30.6774 0.336975 30.3538 0.202967C30.0303 0.0689577 29.6835 -1.05051e-05 29.3333 1.20018e-09V1.20018e-09Z"
        fill="url(#paint0_linear_1525_2425)"
      />
      <g filter="url(#filter0_dd_1525_2425)">
        <path
          d="M10.2756 16.9432C10.1521 17.0697 10.0039 17.1695 9.84027 17.2366C9.67665 17.3038 9.50102 17.3367 9.3242 17.3333C8.97315 17.3332 8.63653 17.1936 8.38827 16.9454C8.14001 16.6972 8.00041 16.3607 8.00015 16.0096C7.99748 15.8338 8.03026 15.6592 8.09655 15.4964C8.16284 15.3335 8.26128 15.1857 8.38598 15.0617C8.50641 14.9344 8.65202 14.8335 8.81354 14.7655C8.97506 14.6975 9.14897 14.6638 9.3242 14.6667C9.50056 14.6666 9.67521 14.7013 9.83815 14.7688C10.0011 14.8362 10.1491 14.9352 10.2738 15.0599C10.3985 15.1846 10.4974 15.3327 10.5648 15.4956C10.6323 15.6586 10.6669 15.8332 10.6668 16.0096C10.6689 16.1837 10.6353 16.3564 10.568 16.5169C10.5007 16.6775 10.4012 16.8226 10.2756 16.9432Z"
          fill="white"
        />
        <path
          d="M10.2756 8.9432C10.1521 9.06975 10.0039 9.16965 9.8403 9.23676C9.67667 9.30386 9.50103 9.33674 9.3242 9.33338C8.97321 9.3332 8.63663 9.19374 8.38838 8.94561C8.14013 8.69748 8.0005 8.36097 8.00015 8.00997C7.99748 7.83411 8.03026 7.6595 8.09655 7.49659C8.16284 7.33367 8.26128 7.18577 8.38598 7.06173C8.50643 6.93444 8.65204 6.83359 8.81356 6.76559C8.97508 6.69758 9.14898 6.66391 9.3242 6.66671C9.50144 6.66351 9.67743 6.69694 9.84115 6.76491C10.0049 6.83289 10.1528 6.93394 10.2756 7.06173C10.4017 7.18501 10.5014 7.33264 10.5687 7.49566C10.6359 7.65867 10.6693 7.83365 10.6668 8.00997C10.6689 8.18402 10.6353 8.35664 10.568 8.51717C10.5007 8.67769 10.4012 8.82269 10.2756 8.9432Z"
          fill="white"
        />
        <path d="M18.667 10.6667H12.667V13.3334H18.667V10.6667Z" fill="white" />
        <path
          d="M22.0789 19.3334H19.8108C21.4919 17.2804 22.3862 14.6955 22.3332 12.0426C22.3898 9.36192 21.4931 6.74814 19.8027 4.66675H22.0872C23.8196 6.73422 24.7368 9.3634 24.6665 12.0598C24.7344 14.7211 23.8123 17.3129 22.0789 19.3334Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1525_2425"
          x="5"
          y="2.66675"
          width="22.6704"
          height="20.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_2425"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_2425"
            result="effect2_dropShadow_1525_2425"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_2425"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1525_2425"
          x1="24.7084"
          y1="27.0833"
          x2="7.29168"
          y2="-3.08329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0078D4" />
          <stop offset="1" stopColor="#35C1F1" />
        </linearGradient>
      </defs>
    </svg>
  );
});
