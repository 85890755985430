import { mergeStyleSets } from "@fluentui/react";

const style = mergeStyleSets({
  bannerContainer: {
    width: "100%",
    alignItems: "center",
    gap: "16px !important",
    border: "1px solid #F7F7F7",
    background: " #FFF",
    boxShadow: "0px 4px 4px 0px rgba(191, 191, 191, 0.15)",
  },

  deviceConfigContainer: {
    marginLeft: "30px",
    "&& .costSavingsBannerImage": {
      width: "83px",
      height: "64px",
      flexShrink: "0",
    },
    "&& .alignCenter": {
      alignItems: "center",
    },
    "&& .subDivider": {
      maxHeight: "10px !important",
    },
  },
  bannerTextFont: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "700 !important",
  },
  devices: {
    fontSize: "16px !important",
    lineHeight: "normal !important",
    marginLeft: "0",
  },

  dividerStyle: {
    alignItems: "center",
    justifyItems: "center",
    minHeight: "70px !important",
    margin: "16px 0 14px 0",
  },
  costSavingsContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "3% !important",
    "@media (max-width: 1340px)": {
      marginRight: "1% !important",
    },
    "&& .currencySymbol": {
      fontSize: "28px !important",
      lineHeight: "normal !important",
      fontWeight: "700 !important",
    },
    "&& .costSavingsValue": {
      fontSize: "32px !important",
      lineHeight: "normal !important",
      fontWeight: "700 !important",
    },
    "&& .fui-PopoverSurface": {
      minwidth: "400px",
      minHeight: "200px",
    },
  },
  tooltipRoot: {
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "50px 20px",
      rowGap: "20px",
    },
  },
  tabListWithLine: {
    position: "relative",
    // marginBottom: "10px",
    borderBottom: "1px solid #DADADA",
    "::after": {
      content: "",
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "2px",
      backgroundColor: "#FFF",
    },
  },
});

export default style;
export const benefitsTooltipStyles = mergeStyleSets({
  wrapper: {
    paddingRight: "5%",
    minWidth: "400px",
  },
  chartTitle: {
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: "1.625rem",
    marginTop: 0,
    marginBottom: "1rem",
    textTransform: "capitalize",
  },
  chartValueWrapper: {
    marginBottom: "2rem",
  },
  chartValue: {
    fontSize: "2.625rem",
    fontWeight: 600,
    lineHeight: "2.625rem",
    color: "#201F1E",
    marginBottom: "1rem",
  },
  chartValueText: {
    fontSize: "1rem",
    textTransform: "capitalize",
    color: "#201F1E",
  },
  tooltipItemList: {
    justifyContent: "space-between",
    fontSize: "1rem",
    lineHeight: "2.125rem",
    borderBottom: "1px solid #DADADA",
    padding: "5px 0",
  },
  tooltipSummary: {
    justifyContent: "space-between",
    fontSize: "1rem",
    lineHeight: "2.125rem",
  },
  tooltipItemValue: {
    textAlign: "left",
    minWidth: "15%",
  },
  tooltipItemName: {
    alignItems: "center",
    lineHeight: "1.125rem",
  },
  detailPointerWrapper: {
    alignItems: "center",
  },
  detail: {
    fontSize: "1.25rem",
    lineHeight: "2.5rem",
    marginRight: "0.4375rem",
  },
  totalRow: {
    fontWeight: 600,
  },
});

export const tableStyles = mergeStyleSets({
  tableWrapper: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
    alignItems: "center",
    marginBottom: "10.4375rem",
  },
  tableTitleWrapper: {
    justifyContent: "space-between",
  },
  tableTitle: {
    fontWeight: 600,
    width: "20%",
    lineHeight: "2.125rem",
  },
  tableTitle1: {
    width: "35%",
  },
  tableRow: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem 0",
    borderBottom: "1px solid #EDEBE9",
  },
  tableRow1: {
    width: "35%",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  tableRow2: {
    width: "20%",
  },
  marker: {
    display: "inline-block",
    marginLeft: "0.625rem",
    marginRight: "0.5rem",
    borderRadius: "1.25rem",
    width: "1.25rem",
    height: "1.25rem",
    flexShrink: 0,
  },
  rowSurfaceValue: {
    width: "60%",
  },
  rowValueHourWrapper: {
    width: "100%",
  },
  rowHourValue: {
    width: "40%",
  },
  withBottom: {
    borderBottom: "0.5px solid #107C10",
  },
  withoutBottom: {
    borderBottom: "unset",
  },
  totalLabel: {
    width: "35%",
    fontWeight: 600,
    paddingLeft: "2.375rem",
    boxSizing: "border-box",
  },
  greyBackground: {
    backgroundColor: "#F3F2F1",
  },
  inputWarning: {
    alignItems: "center",
    backgroundColor: "#FECABD",
    padding: "3rem 3.375rem",
    gap: "1.625rem",
  },
  infoIcon: {
    fontSize: "1.25rem",
  },
  inputWarningText: {
    fontSize: "1rem",
    lineHeight: "1.375rem",
  },
});
