import {
  Text,
  Stack,
  StackItem,
  mergeStyleSets,
  IStackTokens,
} from "@fluentui/react";
import {
  NotepadPerson24Regular,
  Form24Regular,
  ChartMultiple24Regular,
  ChevronDown20Regular,
  ChevronUp20Regular,
  FluentIcon,
  BookCoins24Regular
} from "@fluentui/react-icons";
import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMenuItems } from "./NavItems";
import { mergeClasses } from "@fluentui/react-components";

const styles = mergeStyleSets({
  text: {
    color: "#ffffff",
    fontWeight: "400",
  },
  activeText: {
    fontWeight: "700",
    color: "#ffffff",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  disabledLink: {
    cursor: "not-allowed",
  },
  icon: {
    paddingRight: "10px",
    flexShrink: 0,
  },
  poinerIcon: {
    cursor: "pointer",
  },
});

const stackToken: IStackTokens = {
  childrenGap: 16,
};

export type MenuItem = {
  id: string;
  label: string;
  icon: string | null;
  path: string;
  isDisabled?: boolean;
  subMenu?: MenuItem[];
};

const iconMap: { [key: string]: FluentIcon } = {
  NotepadPerson24Regular,
  Form24Regular,
  BookCoins24Regular,
  ChartMultiple24Regular,
};

const NavigationMenu: FC = () => {
  const menuItems = useMenuItems();
  return (
    <Stack tokens={stackToken}>
      {menuItems.map((menuItem: MenuItem) => (
        <NavItem menuItem={menuItem} key={menuItem.id} />
      ))}
    </Stack>
  );
};

export function NavItem({ menuItem }: { menuItem: MenuItem }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const Icon = iconMap[menuItem.icon ?? ""];
  const location = useLocation();
  const isActive = location.pathname === menuItem.path;

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (
      menuItem.subMenu?.some((subMenu) => subMenu.path === location.pathname)
    ) {
      setIsExpanded(true);
    }
  }, [location.pathname, menuItem.subMenu]);

  const RenderMenuLink = () => (
    <Link
      to={menuItem.isDisabled ? location.pathname : menuItem.path}
      className={mergeClasses(
        styles.link,
        menuItem.isDisabled && styles.disabledLink
      )}
    >
      <Stack horizontal>
        {Icon && <Icon className={styles.icon} />}
        <Text className={isActive ? styles.activeText : styles.text}>
          {menuItem.id + "."}
        </Text>
        <Text className={isActive ? styles.activeText : styles.text}>
          &nbsp;{menuItem.label}
        </Text>
      </Stack>
    </Link>
  );

  return (
    <>
      {!menuItem.subMenu ? (
        <RenderMenuLink />
      ) : (
        <Stack
          horizontal
          styles={{
            root: { justifyContent: "space-between" },
          }}
        >
          <RenderMenuLink />
          {!isExpanded ? (
            <ChevronDown20Regular
              className={styles.poinerIcon}
              onClick={toggle}
            />
          ) : (
            <ChevronUp20Regular
              className={styles.poinerIcon}
              onClick={toggle}
            />
          )}
        </Stack>
      )}
      {isExpanded && (
        <StackItem style={{ marginLeft: "24px" }}>
          {menuItem.subMenu?.map((subMenu: MenuItem) => (
            <NavItem menuItem={subMenu} key={subMenu.id} />
          ))}
        </StackItem>
      )}
    </>
  );
}

export default NavigationMenu;
