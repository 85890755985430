import { useRef, useMemo, useEffect, useState } from "react";
import {
  Stack,
  Text,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  FontIcon,
} from "@fluentui/react";
import {
  PIE_CHART_OPTIONS,
  STACKED_BAR_CHART_OPTIONS,
  TCO_REPORT_CHART_COLOR,
  BAR_CHART_OPTIONS,
  ASSUMPTION_ADVANCED_LIST,
} from "constants/tco";
import {
  formatNumberWithUnit,
  isSmallWidthScreen,
  // downloadPdf,
  isObjectEmpty,
  convertNumberToString,
  generatePDF,
  formatCurrency,
  convertStringToNumber,
  trackGAEvent
} from "utils/helpers";
import BackToTop from "components/BackToTop";
import ReactEcharts from "components/ReactEcharts";
import ReadMore from "components/ReadMore";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import ChartDisclaimer from "./ChartDisclaimer";
import "./TcoCompleteReport.scss";

const TcoCompleteReport = (props) => {
  const { data, dataPerDevice, isLoading, advancedInput, currencySymbol, userInput} = props;
  const [completeReport, setCompleteReport] = useState({});

  const detailReportRef = useRef(null);

  useEffect(() => {
    if (!isObjectEmpty(data)) {
      setCompleteReport({ ...data, key: uuid() });
    }
  }, [data])

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const handleResize = () => {
      if (window.innerWidth === windowWidth) return;
      setCompleteReport((state) => {
        return {
          ...state,
          key: uuid(),
        };
      });
    };

    window.addEventListener("resize", _.debounce(handleResize, 500));
    return () => {
      window.removeEventListener("resize", _.debounce(handleResize, 500));
    };
  }, []);

  const uncheckList = useMemo(() => {
    let result = {
      totalCosts: [],
      totalSavings: [],
      itSimplification: [],
      employeeEmpowerment: [],
    };
    Object.keys(advancedInput).forEach((key) => {
      if (advancedInput[key].checked === false) {
        for (let k in ASSUMPTION_ADVANCED_LIST) {
          const uncheckItem = ASSUMPTION_ADVANCED_LIST[k].find((i) => i.id === key);
          if (uncheckItem) {
            result[k]?.push(uncheckItem.reportLabel);
          }
        };
      }
    });
    return result;
  }, [advancedInput]);

  const chartDescriptionStyles = mergeStyleSets({
    wrapper: {
      width: "50%",
      paddingRight: "5%",
    },
    chartTitle: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.625rem",
      marginTop: 0,
      marginBottom: "1rem",
      textTransform: "capitalize",
    },
    chartValueWrapper: {
      marginBottom: "2rem",
    },
    chartValue: {
      fontSize: "2.625rem",
      fontWeight: 600,
      lineHeight: "2.625rem",
      color: "#201F1E",
      marginBottom: "1rem",
    },
    chartValueText: {
      fontSize: "1rem",
      textTransform: "capitalize",
      color: "#201F1E",
    },
    chartItemList: {
      justifyContent: "space-between",
      fontSize: "1rem",
      lineHeight: "2.125rem",
    },
    chartItemName: {
      alignItems: "center",
      lineHeight: "1.125rem",
    },
    detailPointerWrapper: {
      alignItems: "center",
    },
    detail: {
      fontSize: "1.25rem",
      lineHeight: "2.5rem",
      marginRight: "0.4375rem",
    },
  });

  const chartDescription = (text, data, color) => {
    const { title, value, readMore } = text;
    return (
      <div
        className={`${chartDescriptionStyles.wrapper} tco-report-chart-description-wrapper`}
      >
        <h3 className={chartDescriptionStyles.chartTitle} tabIndex={0}>
          {title}
        </h3>
        {value.map((item, index) => (
          <div key={index} className={chartDescriptionStyles.chartValueWrapper}>
            <Text
              block
              className={`${chartDescriptionStyles.chartValue} tco-report-chart-description-value`}
              tabIndex={0}
            >
              {item.val}
            </Text>
            <Text
              className={`${chartDescriptionStyles.chartValueText} tco-report-chart-description-value-text`}
            >
              {item.text}
            </Text>
          </div>
        ))}
        {readMore ? (
          <ReadMore count={isSmallWidthScreen() ? 12 : 15}>
            {readMore}
          </ReadMore>
        ) : null}
        {data ? (
          <>
            {data.map((item, index) => {
              const markerColor = { backgroundColor: color[index] };
              return (
                <Stack
                  horizontal
                  key={index}
                  className={`${chartDescriptionStyles.chartItemList} tco-report-chart-item-list`}
                  tabIndex={0}
                >
                  <Stack
                    horizontal
                    className={chartDescriptionStyles.chartItemName}
                  >
                    <div
                      className={`${tableStyles.marker} tco-report-chart-item-list-marker`}
                      style={markerColor}
                    ></div>
                    <div>{item.name}</div>
                  </Stack>
                  <div>{formatCurrency(item.value, currencySymbol, 0)}</div>
                </Stack>
              );
            })}
            <Stack
              horizontal
              className={`${chartDescriptionStyles.chartItemList} tco-report-chart-item-list`}
              tabIndex={0}
            >
              <Stack
                horizontal
                className={chartDescriptionStyles.chartItemName}
              >
                <div className={`${tableStyles.marker} mobile-hide`}></div>
                <div>Total</div>
              </Stack>
              <div>
                {formatCurrency(
                  data.reduce((acc, curr) => acc + curr.value, 0),
                  currencySymbol,
                  0
                )}
              </div>
            </Stack>
          </>
        ) : null}
      </div>
    );
  };

  const tableStyles = mergeStyleSets({
    tableWrapper: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      alignItems: "center",
      marginBottom: "10.4375rem",
    },
    tableTitleWrapper: {
      justifyContent: "space-between",
    },
    tableTitle: {
      fontWeight: 600,
      width: "20%",
      lineHeight: "2.125rem",
    },
    tableTitle1: {
      width: "35%",
    },
    tableRow: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.5rem 0",
      borderBottom: "1px solid #EDEBE9",
    },
    tableRow1: {
      width: "35%",
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
    },
    tableRow2: {
      width: "20%",
    },
    marker: {
      display: "inline-block",
      marginLeft: "0.625rem",
      marginRight: "0.5rem",
      borderRadius: "1.25rem",
      width: "1.25rem",
      height: "1.25rem",
      flexShrink: 0,
    },
    rowSurfaceValue: {
      width: "60%",
    },
    rowValueHourWrapper: {
      width: "100%",
    },
    rowHourValue: {
      width: "40%",
    },
    withBottom: {
      borderBottom: "0.5px solid #107C10",
    },
    withoutBottom: {
      borderBottom: "unset",
    },
    totalLabel: {
      width: "35%",
      fontWeight: 600,
      paddingLeft: "2.375rem",
      boxSizing: "border-box",
    },
    greyBackground: {
      backgroundColor: "#F3F2F1",
    },
    inputWarning: {
      alignItems: "center",
      backgroundColor: "#FECABD",
      padding: "3rem 3.375rem",
      gap: "1.625rem",
    },
    infoIcon: {
      fontSize: "1.25rem",
    },
    inputWarningText: {
      fontSize: "1rem",
      lineHeight: "1.375rem",
    },
  });

  const chartDataList = (data, color, showHours) => {
    const tableTitleList = [
      {
        label: "Surfaces",
        valueKey: "surfaceValue",
        hourKey: "surfaceHours",
      },
      {
        label: "Other PCs",
        valueKey: "pcValue",
        hourKey: "pcHours",
      },
      {
        label: "Savings",
        valueKey: "savings",
        hourKey: "hoursSavings",
      },
    ];
    const getCustomizeValue = (item, key, prefix) => {
      let result = formatCurrency(item[key], prefix, 0);
      if ((item.isSavingsInput && key === "savings") || (item.isSurfaceInput && key === "surfaceValue") || (item.isOtherPcInput && key === "pcValue")) {
        result = result + "*";
      }
      if (item.isSavingsInput && key !== "savings") {
        result = "----";
      }
      return result;
    }
    const hasCustomizedValue = data.rows.some(
      (i) => i.isSavingsInput || i.isSurfaceInput || i.isOtherPcInput || i.isOtherPcHoursInput
    );
    return (
      <div className={`${tableStyles.tableWrapper} tco-report-table-wrapper`}>
        <Stack
          horizontal
          className={`${tableStyles.tableTitleWrapper} tco-report-table-title mobile-hide`}
          tabIndex={0}
        >
          <div className={tableStyles.tableTitle1}></div>
          {tableTitleList.map((item, index) => (
            <Stack.Item
              key={index}
              className={`${tableStyles.tableTitle} ${
                showHours ? tableStyles.withBottom : ""
              }`}
            >
              {item.label}
            </Stack.Item>
          ))}
        </Stack>
        {data?.rows.map((item, index) => {
          const markerColor = { backgroundColor: color[index] };
          return (
            <Stack
              horizontal
              key={index}
              className={`${tableStyles.tableRow} tco-report-table-row ${
                item.savings < 0 ? tableStyles.greyBackground : ""
              }`}
              tabIndex={0}
            >
              <Stack.Item className={tableStyles.tableRow1}>
                <div className={tableStyles.marker} style={markerColor}></div>
                <div>{item.label}</div>
              </Stack.Item>
              {tableTitleList.map((i) => (
                <Stack
                  horizontal
                  className={tableStyles.tableRow2}
                  key={i.label}
                >
                  <div className="mobile-display-only tco-report-mobile-table-row-label">
                    {i.label}
                  </div>
                  {showHours ? (
                    <Stack
                      horizontal
                      className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
                    >
                      <span className={tableStyles.rowSurfaceValue}>
                        {getCustomizeValue(item, i.valueKey, currencySymbol)}
                      </span>
                      <span className={tableStyles.rowHourValue}>
                        {i.hourKey === "pcHours" && (item["pcHours"] !== undefined && item["isOtherPcHoursInput"])
                          ? `${formatNumberWithUnit(item[i.hourKey], null, 0)}hr*`
                          : item[i.hourKey] !== undefined ?
                          `${formatNumberWithUnit(item[i.hourKey], null, 0)}hr`
                          :  item['isSavingsInput'] ? "----": 'N/A'}
                      </span>
                    </Stack>
                  ) : (
                    <>{getCustomizeValue(item, i.valueKey, currencySymbol)}</>
                  )}
                </Stack>
              ))}
            </Stack>
          );
        })}
        <Stack
          horizontal
          className={`${tableStyles.tableRow} ${tableStyles.withoutBottom} tco-report-table-row`}
          tabIndex={0}
        >
          <Stack.Item
            className={`${tableStyles.totalLabel} tco-report-table-total-label`}
          >
            Total
          </Stack.Item>
          {tableTitleList.map((i) => (
            <Stack horizontal className={tableStyles.tableRow2} key={i.label}>
              <div className="mobile-display-only tco-report-mobile-table-row-label">
                {i.label}
              </div>
              {showHours ? (
                <Stack
                  horizontal
                  className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
                >
                  <span className={tableStyles.rowSurfaceValue}>
                    {formatCurrency(data[i.valueKey], currencySymbol, 0)}
                  </span>
                  <span className={tableStyles.rowHourValue}>
                    {data[i.hourKey] !== undefined
                      ? `${formatNumberWithUnit(data[i.hourKey], null, 0)}hr`
                      : ""}
                  </span>
                </Stack>
              ) : (
                <>{formatCurrency(data[[i.valueKey]], currencySymbol, 0)}</>
              )}
            </Stack>
          ))}
        </Stack>
        {hasCustomizedValue ? (
          <Stack horizontal className={`${tableStyles.inputWarning} tco-report-warning-wrapper`}>
            <FontIcon
              iconName="Info"
              tabIndex={0}
              className={tableStyles.infoIcon}
            />
            <span className={tableStyles.inputWarningText}>
              * These values are taken directly from your user input in the
              Results Editor.
            </span>
          </Stack>
        ) : null}
      </div>
    );
  };

  const chartDataListSustainability = (data, color) => {

    let sustainabilityUserInput = userInput.sustainability;
    let quantity = sessionStorage.getItem("showPerDevice") === "true" ? 1 : convertStringToNumber(userInput.deviceMixPortfolio[0]?.quantity);
    let years = sessionStorage.getItem("years") ? Number(sessionStorage.getItem("years")) : 3;

    const dataObject = {
      Energy: {
        surfaceCol1: 0,
        surfaceCol2: convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) * quantity * years,
        otherPcCol1: 0,
        otherPcCol2: convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) * quantity * years,
        savingsCol1: 0,
        savingsCol2: (convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) * quantity * years) - (convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) * quantity * years),
      },
      CarbonEmissions: {
        surfaceCol1: 0,
        surfaceCol2: convertStringToNumber(sustainabilityUserInput.CarbonEmissions.surfaceValue) * quantity * years,
        otherPcCol1: 0,
        otherPcCol2: convertStringToNumber(sustainabilityUserInput.CarbonEmissions.otherPcValue) * quantity * years,
        savingsCol1: 0,
        savingsCol2: (convertStringToNumber(sustainabilityUserInput.CarbonEmissions.otherPcValue) * quantity * years) - (convertStringToNumber(sustainabilityUserInput.CarbonEmissions.surfaceValue) * quantity * years),
      },
      totalValues: {
        surfaceCol1: data.surfaceValue,
        otherPcCol1: data.pcValue,
        savingsCol1: data.savings,
      }
    };

    data.rows?.map((row) => {
      if(row.label === "Total Energy Consumption Savings"){
        dataObject.Energy.surfaceCol1 = row.surfaceValue;
        dataObject.Energy.otherPcCol1 = row.pcValue;
        dataObject.Energy.savingsCol1 = row.savings;
      } else if (row.label === "Total Carbon Emissions Savings"){
        dataObject.CarbonEmissions.surfaceCol1 = row.surfaceValue;
        dataObject.CarbonEmissions.otherPcCol1 = row.pcValue;
        dataObject.CarbonEmissions.savingsCol1 = row.savings;
      }
    })

    const tableTitleList = [
      {
        label: "Surfaces",
        valueKey1: "surfaceCol1",
        valueKey2: "surfaceCol2",
      },
      {
        label: "Other PCs",
        valueKey1: "otherPcCol1",
        valueKey2: "otherPcCol2",
      },
      {
        label: "Savings",
        valueKey1: "savingsCol1",
        valueKey2: "savingsCol2",
      },
    ];

    const getCustomizeValue = (item, key, prefix) => {
      let result;

      if(item.label === "Total Energy Consumption Savings"){
        if(prefix === "Kwh" || prefix === "MTCO2"){
          result = dataObject.Energy[key] + " " + prefix;
        } else {
          result = formatCurrency(dataObject.Energy[key], prefix, 0);
        }
      } else if(item.label === "Total Carbon Emissions Savings") {
        if(prefix === "Kwh" || prefix === "MTCO2"){
          result = dataObject.CarbonEmissions[key] + " " + prefix;
        } else {
          result = formatCurrency(dataObject.CarbonEmissions[key], prefix, 0);
        }
      } else {
        if(prefix === "Kwh" || prefix === "MTCO2"){
          result = dataObject.totalValues[key] + "" + prefix;
        } else {
          result = formatCurrency(dataObject.totalValues[key], prefix, 0);
        }
      }

      return result;
    }

    return (
      <div className={`${tableStyles.tableWrapper} tco-report-table-wrapper`}>
        <Stack
          horizontal
          className={`${tableStyles.tableTitleWrapper} tco-report-table-title mobile-hide`}
          tabIndex={0}
        >
          <div className={tableStyles.tableTitle1}></div>
          {tableTitleList.map((item, index) => (
            <Stack.Item
              key={index}
              className={`${tableStyles.tableTitle} ${tableStyles.withBottom}`}
            >
              {item.label}
            </Stack.Item>
          ))}
        </Stack>
        {data?.rows.map((item, index) => {
          const markerColor = { backgroundColor: color[index] };
          const col2Unit = item.label === "Total Energy Consumption Savings" ? "Kwh" : "MTCO2";
          return (
            <Stack
              horizontal
              key={index}
              className={`${tableStyles.tableRow} tco-report-table-row ${
                item.savings < 0 ? tableStyles.greyBackground : ""
              }`}
              tabIndex={0}
            >
              <Stack.Item className={tableStyles.tableRow1}>
                <div className={tableStyles.marker} style={markerColor}></div>
                <div>{item.label}</div>
              </Stack.Item>
              {tableTitleList.map((i) => (
                <Stack
                  horizontal
                  className={tableStyles.tableRow2}
                  key={i.label}
                >
                  <div className="mobile-display-only tco-report-mobile-table-row-label">
                    {i.label}
                  </div>
                  <Stack
                    horizontal
                    className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
                  >
                    <span className={tableStyles.rowSurfaceValue}>
                      {getCustomizeValue(item, i.valueKey1, currencySymbol)}
                    </span>
                    <span className={tableStyles.rowHourValue}>
                      {getCustomizeValue(item, i.valueKey2, col2Unit)}
                    </span>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          );
        })}
        <Stack
          horizontal
          className={`${tableStyles.tableRow} ${tableStyles.withoutBottom} tco-report-table-row`}
          tabIndex={0}
        >
          <Stack.Item
            className={`${tableStyles.totalLabel} tco-report-table-total-label`}
          >
            Total
          </Stack.Item>
          {tableTitleList.map((i) => (
            <Stack horizontal className={tableStyles.tableRow2} key={i.label}>
              <div className="mobile-display-only tco-report-mobile-table-row-label">
                {i.label}
              </div>
              <Stack
                horizontal
                className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
              >
                <span className={tableStyles.rowSurfaceValue}>
                  {getCustomizeValue({label: "total"}, i.valueKey1, currencySymbol)}
                </span>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </div>
    );
  };

  const reportStyles = mergeStyleSets({
    contentWrapper: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "85vw",
      height: "85vh",
      color: "#201F1E",
    },
    cancelIcon: {
      color: "#474747",
      alignSelf: "flex-end",
      fontSize: "22.5px",
      cursor: "pointer",
      position: "sticky",
      top: "1.4844rem",
      marginRight: "2.2344rem",
    },
    title: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 600,
      margin: "2.5rem 0 2.5rem 2.5rem",
      textTransform: "capitalize",
    },
    introWrapper: {
      width: "45%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "1.5rem",
    },
    introLeftPart: {
      width: "78.5%",
      backgroundColor: "#f3f2f1",
      padding: "2rem",
    },
    introLeftPartTitle: {
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "3rem",
      color: "#0078D4",
    },
    introLeftPartText: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5375rem",
      marginBottom: "1rem",
      color: "#000000",
      textTransform: "capitalize",
    },
    introLeftPartTextAdditional: {
      marginBottom: "2rem",
    },
    introRightPart: {
      padding: "0 3.4375rem 0 2rem",
    },
    introRightPartText: {
      fontSize: "0.65rem",
      fontWeight: 400,
      lineHeight: "1rem",
      color: "#000000",
    },
    introRightPartContent: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5625rem",
      marginBottom: "1.3125rem",
      marginLeft: "0.75rem",
      color: "#201F1E",
    },
    introParagraph: {
      color: "#191919",
      marginBottom: "2.5rem",
      fontWeight: 600,
      width: "47.69%",
      margin: "0 3rem 0 2.5rem",
      p: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.33rem",
      },
      "p:first-child": {
        marginTop: "0",
        marginBottom: "1.375rem",
      },
    },
    sectionWrapper: {
      padding: "0 3.375rem",
      marginBottom: "9.375rem",
    },
    sectionTitle: {
      fontSize: "1.625rem",
      fontWeight: 400,
      lineHeight: "2.1875rem",
      textTransform: "capitalize",
      marginTop: 0,
      marginBottom: "3.5rem",
    },
    sectionTitleAdditional: {
      marginBottom: "0rem",
      h2: {
        color: "#191919",
        fontWeight: 400,
        fontSize: "1rem",
      }
    },
    chartWrapper: {
      marginBottom: "1.3125rem",
    },
    chart: {
      width: "50%",
    },
    spinner: {
      height: "100%",
    },
    incidentsItemWrapper: {
      width: "50%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    incidentsTitle: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.6875rem",
    },
    incidentsValueWrapper: {
      gap: "0.5rem",
    },
    incidentsValue: {
      fontSize: "2.625rem",
      fontWeight: 600,
      lineHeight: "3.9375rem",
    },
    incidentsUnit: {
      alignSelf: "flex-end",
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: "3rem",
    },
    incidentsDes: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.875rem",
      alignItems: "center",
    },
    pdfLinkWrapper: {
      paddingTop: "6.5625rem",
      "> div:first-child": {
        marginBottom: "6.5625rem",
      },
    },
    pdfLink: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.875rem",
      textDecoration: "underline",
      cursor: "pointer",
    },
    subSection: {
      marginBottom: "9.375rem",
    },
    costsAndSavings: {
      "> div:first-child": {
        paddingRight: "0",
        width: "95%"
      }
    },
    costsAndSavingsAdditional: {
      "> div:first-child": {
        marginBottom: "1rem",
      }
    }
  });

  const chartData = useMemo(() => {
    if (isObjectEmpty(completeReport)) {
      return {};
    }
    let chartData = {};
    const convertToChartData = (data) => {
      return data.rows.map((item) => {
        return {
          name: item.label,
          value: [item.surfaceValue || 0, item.pcValue || 0],
          stack:
            item.label === "Increased Residual Value" ? "residual" : "stack",
          barWidth: data.rows.find(
            (i) => i.label === "Increased Residual Value"
          )
            ? "30%"
            : isSmallWidthScreen() ? "60" : "35%",
        };
      });
    };
    chartData.totalCosts = {
      chartData: convertToChartData(completeReport.totalCosts).reverse(),
      label: ["Surface", "PC"],
      currencySymbol: currencySymbol,
    };
    chartData.totalSavings = {
      chartData: completeReport.totalSavings?.rows
        .map((item) => {
          return {
            name: item.label,
            value: [item.savings],
            stack: "stack",
            barWidth: "20%",
          };
        })
        ?.reverse(),
      label: ["Savings"],
      currencySymbol: currencySymbol,
    };
    chartData.itSimplification = {
      chartData: convertToChartData(completeReport.itSimplification).reverse(),
      label: ["Surface", "PC"],
      currencySymbol: currencySymbol,
    };

    chartData.savingAndBenefit = [
      {
        name: "Total Direct Savings",
        value: completeReport.totalSavings?.savings,
      },
      {
        name: "Total IT Efficiency Benefits",
        value: completeReport.itSimplification?.savings,
      },
      {
        name: "Total Employee Experience Benefits",
        value: completeReport.employeeEmpowerment?.savings,
      },
      {
        name: "Total Sustainability Savings",
        value: completeReport.sustainability?.savings,
      },
    ];
    const employeeChartData = convertToChartData(
      completeReport.employeeEmpowerment
    );
    chartData.employeeEmpowerment = {
      chartData: employeeChartData.reverse(),
      label: ["Surface", "PC"],
      currencySymbol: currencySymbol,
    };

    chartData.incidentsSaving = {
      helpDesk: completeReport.itSimplification.rows.find(
        (i) => i.label === "Reduced Helpdesk Support"
      ),
      security: completeReport.itSimplification.rows.find(
        (i) => i.label === "Reduced Time for Security Incident Response"
      ),
    };

    chartData.sustainability = {
      chartData: completeReport.sustainability?.rows
        .map((item) => {
          return {
            name: item.label,
            value: [item.savings],
            stack: "stack",
            barWidth: "15%",
          };
        })
        ?.reverse(),
      label: ["Savings"],
      currencySymbol: currencySymbol,
    };

    return chartData;
  }, [completeReport]);

  const uncheckWarning = (key) => (
    <>
      {uncheckList[key].length > 0 ? (
        <Stack
          horizontal
          className={`${tableStyles.inputWarning} tco-report-warning-wrapper`}
        >
          <FontIcon
            iconName="Info"
            tabIndex={0}
            className={tableStyles.infoIcon}
          />
          <span className={tableStyles.inputWarningText}>
            * The following categories are not included because they were
            unselected in the Custom view: {uncheckList[key].join(", ")}
          </span>
        </Stack>
      ) : null}
    </>
  );

  const hasValidChartData = (data) => {
    if (!data || !data.rows) return false;
    const isAllValueModified = data.rows.every((i) => i.isSavingsInput === true);
    if (data.rows.length > 0 && !isAllValueModified) {
      return true;
    }
    return false;
  }

  return (
    <>
      {!isObjectEmpty(completeReport) && !isLoading ? (
        <div className="tco-report-content-wrapper" ref={detailReportRef}>
          <h1 className={reportStyles.title} tabIndex={0}>
            Microsoft Surface TCO Report
          </h1>
          <div className="export_pdf">
            <button onClick={() => {
              generatePDF(completeReport, dataPerDevice, chartData, currencySymbol, sessionStorage.getItem("activeView") === "Advanced" ? Number(sessionStorage.getItem("years")) : 3, userInput);
              trackGAEvent("Export Results", "Full report export", "Export full report to PDF in TCO Results page");
            }}>
              <FontIcon aria-label="PDF" iconName="PDF" />
              <span>Export to PDF</span>
            </button>
          </div>
          <Stack horizontal className="intro-wrapper">
            <div
              className={`${reportStyles.introParagraph} tco-report-intro-paragraph`}
            >
              <p>
                The TCO tool provides you with a report that includes estimates of
                potential costs, savings, and ROI you may achieve by replacing
                other PC devices with Surface devices and M365. Default values
                provided in the TCO tool are based on the study "
                <a
                  href="https://aka.ms/SurfaceIDCWhitepaper"
                  rel="noreferrer"
                  target="_blank"
                >
                  Evaluating the Business Case of Microsoft Surface
                </a>
                " conducted by IDC and commissioned by Microsoft in 2022.
              </p>
              <p>
                The TCO report provided to you is for informational purposes only.
                You should not interpret the report you receive to be a commitment
                on the part of Microsoft; actual costs and savings may vary based
                on your location, purchase method, deployment, usage, and other
                factors. Neither Microsoft nor IDC makes any representations or
                warranties, express or implied, as to the information within this
                website and report.
              </p>
            </div>
            <div
              className={`${reportStyles.introWrapper} tco-report-intro-wrapper`}
            >
              <div
                className={`${reportStyles.introLeftPart} tco-report-intro-left`}
              >
                <Text
                  block
                  className={`${reportStyles.introLeftPartTitle} tco-report-intro-left-title`}
                  tabIndex={0}
                >
                  {formatCurrency(completeReport.savings, currencySymbol, 0)}
                </Text>
                <Text
                  block
                  className={`${reportStyles.introLeftPartText} tco-report-intro-left-text`}
                  tabIndex={0}
                >
                  Expected {sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3}-Year Total Savings and Benefits*
                </Text>
                <Text
                  block
                  className={`${reportStyles.introLeftPartTitle} tco-report-intro-left-title`}
                  tabIndex={0}
                >
                  {completeReport.ROI
                    ? convertNumberToString(completeReport.ROI * 100)
                    : 0}
                  %
                </Text>
                <Text
                  block
                  className={`${reportStyles.introLeftPartText} ${reportStyles.introLeftPartTextAdditional} tco-report-intro-left-text`}
                  tabIndex={0}
                >
                  Total return on investment (ROI)*
                </Text>
                <Text
                  block
                  className={reportStyles.introRightPartText}
                  tabIndex={0}
                >
                  * Total Savings & Benefits = Direct Savings + IT Efficiency Savings + Employee Experience Savings
                </Text>
                <Text
                  block
                  className={reportStyles.introRightPartText}
                  tabIndex={0}
                >
                  * ROI = Total Savings & Benefits / Costs
                </Text>
              </div>
              {/* <div
                className={`${reportStyles.introRightPart} tco-report-intro-right`}
              >
              </div> */}
            </div>
          </Stack>

          <section
            className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
          >
            <div className={reportStyles.subSection}>
              <h2
                className={`${reportStyles.sectionTitle} ${reportStyles.sectionTitleAdditional} tco-report-section-title`}
                tabIndex={0}
              >
                Highlights of Costs and Savings Over {sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} Years
              </h2>
              <p>{sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3}-Year Total Costs</p>
              <Stack className={reportStyles.chartWrapper}>
                <Stack horizontal className="tco-report-chart-wrapper">
                  {completeReport.totalCosts?.rows.length > 0 ? (
                    <div className={`${reportStyles.chart} tco-report-chart`}>
                      <ReactEcharts
                        options={STACKED_BAR_CHART_OPTIONS(
                          chartData.totalCosts,
                          TCO_REPORT_CHART_COLOR.totalCosts
                        )}
                        id="chart1"
                        width="100%"
                        height="31.25rem"
                      />
                    </div>
                  ) : null}

                  <Stack className="costsAndSavingsWrapper">
                    <div className={`${reportStyles.costsAndSavings}`}>
                      {chartDescription({
                        // title: "3-Year Total Costs",
                        value: [
                          {
                            val: formatCurrency(
                              completeReport.totalCosts?.surfaceValue,
                              currencySymbol,
                              0
                            ),
                            text: "Expected Costs",
                          },
                        ],
                        readMore:
                          "These costs take into consideration device costs, accessories costs, M365 Licensing costs, and extended warranty & maintenance costs in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.",
                      })}
                    </div>

                    <div className={`${reportStyles.costsAndSavingsAdditional}`}>
                      {uncheckWarning("totalCosts")}
                      {completeReport.totalCosts?.rows.length > 0
                        ? chartDataList(
                            completeReport.totalCosts,
                            TCO_REPORT_CHART_COLOR.totalCosts
                              .slice(0, completeReport.totalCosts?.rows.length)
                              .reverse()
                          )
                        : null}
                    </div>
                  </Stack>

                </Stack>
                <ChartDisclaimer />
              </Stack>
            </div>
            <Stack
              horizontal
              className={`${reportStyles.chartWrapper} tco-report-chart-wrapper`}
            >
              <div className={`${reportStyles.chart} tco-report-chart`}>
                <ReactEcharts
                  options={PIE_CHART_OPTIONS(
                    chartData.savingAndBenefit,
                    TCO_REPORT_CHART_COLOR.savingsAndBenefits,
                    currencySymbol
                  )}
                  id="chart2"
                  width="100%"
                  height="18.75rem"
                />
              </div>
              {chartDescription(
                {
                  title: `Total Surface Savings and Benefits over ${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} Years`,
                  value: [
                    {
                      val: formatCurrency(completeReport.savings, currencySymbol, 0),
                    },
                  ],
                },
                chartData.savingAndBenefit,
                TCO_REPORT_CHART_COLOR.savingsAndBenefits
              )}
            </Stack>
          </section>
          <section
            className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
          >
            <h2
              className={`${reportStyles.sectionTitle} tco-report-section-title`}
              tabIndex={0}
            >
              Total Surface Savings and Benefits Breakdown Over {sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} Years
            </h2>
            <div className={reportStyles.subSection}>
              <Stack
                horizontal
                className={`${reportStyles.chartWrapper} tco-report-chart-wrapper`}
              >
                {chartDescription({
                  title: `${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} Year Total Direct Savings`,
                  value: [
                    {
                      val: formatCurrency(
                        completeReport.totalSavings?.savings,
                        currencySymbol,
                        0
                      ),
                      text: "Expected Savings",
                    },
                  ],
                  readMore:
                    `Your ${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} year total savings take into consideration the increased residual value, reduced support costs, and accessories savings in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
                })}
                {hasValidChartData(completeReport.totalSavings) ? (
                  <div className={`${reportStyles.chart} tco-report-chart`}>
                    <ReactEcharts
                      options={STACKED_BAR_CHART_OPTIONS(
                        chartData.totalSavings,
                        TCO_REPORT_CHART_COLOR.totalSavings
                      )}
                      width="100%"
                      height="31.25rem"
                      id="chart3"
                    />
                  </div>
                ) : null}
              </Stack>
              {uncheckWarning("totalSavings")}
              {completeReport.totalSavings?.rows.length > 0
                ? chartDataList(
                    completeReport.totalSavings,
                    TCO_REPORT_CHART_COLOR.totalSavings
                      .slice(0, completeReport.totalSavings?.rows.length)
                      .reverse()
                  )
                : null}
            </div>
            <Stack className={reportStyles.chartWrapper}>
              <Stack horizontal className="tco-report-chart-wrapper">
                {hasValidChartData(completeReport.itSimplification) ? (
                  <div className={`${reportStyles.chart} tco-report-chart`}>
                    <ReactEcharts
                      options={STACKED_BAR_CHART_OPTIONS(
                        chartData.itSimplification,
                        TCO_REPORT_CHART_COLOR.it
                      )}
                      width="100%"
                      height="31.25rem"
                      id="chart4"
                    />
                  </div>
                ) : null}
                {chartDescription(
                  {
                    title: `IT Efficiency Savings over ${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} Years`,
                    value: [
                      {
                        val: formatCurrency(
                          completeReport.itSimplification?.savings,
                          currencySymbol,
                          0
                        ),
                        text: "Expected IT-Related Savings",
                      },
                      {
                        val: formatNumberWithUnit(
                          completeReport.itSimplification?.hoursSavings,
                          null,
                          0
                        ),
                        text: "Expected Hour Savings per IT Employee",
                      },
                    ],
                    readMore:
                      `Your ${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} year total IT Efficiency Benefits take into consideration device consolidation, help desk support, time to resolve security incidents, ongoing maintenance, IT deployment costs, IT training costs, and ongoing IT security. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
                  },
                  null,
                  TCO_REPORT_CHART_COLOR.it
                )}
              </Stack>
              <ChartDisclaimer />
            </Stack>
            {uncheckWarning("itSimplification")}
            {completeReport.itSimplification?.rows.length > 0
              ? chartDataList(
                  completeReport.itSimplification,
                  TCO_REPORT_CHART_COLOR.it
                    .slice(0, completeReport.itSimplification?.rows.length)
                    .reverse(),
                  true
                )
              : null}
          </section>
          <section
            className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
          >
            <h2
              className={`${reportStyles.sectionTitle} tco-report-section-title`}
              tabIndex={0}
            >
              IT Hours Saved due to Reduced Help Desk and Security Incidents
            </h2>
            <Stack
              horizontal
              className="help-desk-and-security-incidents-wrapper"
            >
              <Stack className={reportStyles.incidentsItemWrapper}>
                <div
                  className={`${reportStyles.incidentsTitle} help-desk-and-security-incidents-title`}
                >
                  Help Desk Incidents
                </div>
                {chartData.incidentsSaving.helpDesk &&
                !chartData.incidentsSaving.helpDesk.isSavingsInput ? (
                  <ReactEcharts
                    options={BAR_CHART_OPTIONS(
                      {
                        data: [
                          chartData.incidentsSaving.helpDesk?.surfaceHours,
                          chartData.incidentsSaving.helpDesk?.pcHours,
                        ],
                        label: ["Surface", "Other PC"],
                        unit: "hours",
                      },
                      TCO_REPORT_CHART_COLOR.incidents.helpDesk
                    )}
                    width="30rem"
                    height="26rem"
                    id="chart5"
                  />
                ) : null}
                <Stack
                  horizontal
                  className={reportStyles.incidentsValueWrapper}
                >
                  <span
                    className={`${reportStyles.incidentsValue} help-desk-and-security-incidents-value`}
                  >
                    {formatNumberWithUnit(
                      chartData.incidentsSaving.helpDesk?.hoursSavings,
                      null,
                      0
                    )}
                  </span>
                  <span
                    className={`${reportStyles.incidentsUnit} help-desk-and-security-incidents-unit`}
                  >
                    hours
                  </span>
                </Stack>
                <Stack
                  className={`${reportStyles.incidentsDes} help-desk-and-security-incidents-des`}
                >
                  <span>Fewer</span>
                  <span>Helpdesk Incidents</span>
                </Stack>
              </Stack>
              <Stack className={reportStyles.incidentsItemWrapper}>
                <div
                  className={`${reportStyles.incidentsTitle} help-desk-and-security-incidents-title`}
                >
                  Security Incidents
                </div>
                {chartData.incidentsSaving.security &&
                !chartData.incidentsSaving.security.isSavingsInput ? (
                  <ReactEcharts
                    options={BAR_CHART_OPTIONS(
                      {
                        data: [
                          chartData.incidentsSaving.security?.surfaceHours,
                          chartData.incidentsSaving.security?.pcHours,
                        ],
                        label: ["Surface", "Other PC"],
                        unit: "hours",
                      },
                      TCO_REPORT_CHART_COLOR.incidents.security
                    )}
                    width="30rem"
                    height="26rem"
                    id="chart6"
                  />
                ) : null}
                <Stack
                  horizontal
                  className={reportStyles.incidentsValueWrapper}
                >
                  <span
                    className={`${reportStyles.incidentsValue} help-desk-and-security-incidents-value`}
                  >
                    {formatNumberWithUnit(
                      chartData.incidentsSaving.security?.hoursSavings,
                      null,
                      0
                    )}
                  </span>
                  <span
                    className={`${reportStyles.incidentsUnit} help-desk-and-security-incidents-unit`}
                  >
                    hours
                  </span>
                </Stack>
                <Stack
                  className={`${reportStyles.incidentsDes} help-desk-and-security-incidents-des`}
                >
                  <span>Fewer</span>
                  <span>Security Incidents</span>
                </Stack>
              </Stack>
            </Stack>
            <ChartDisclaimer />
          </section>
          <section
            className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
          >
            {completeReport.employeeEmpowerment ? (
              <>
                <Stack className={reportStyles.chartWrapper}>
                  <Stack horizontal className="tco-report-chart-wrapper">
                    {chartDescription({
                      title: `Employee Experience Savings over ${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} Years`,
                      value: [
                        {
                          val: formatCurrency(
                            completeReport.employeeEmpowerment?.savings,
                            currencySymbol,
                            0
                          ),
                          text: "Expected Savings",
                        },
                        {
                          val: formatNumberWithUnit(
                            completeReport.employeeEmpowerment?.hoursSavings,
                            null,
                            0
                          ),
                          text: "Expected Hour Savings per Employee",
                        },
                      ],
                      readMore:
                        `Your ${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} year total Employee Experience Benefits take into consideration productivity gains from increased mobility, time spent on help desk resolution, meeting time saved, time spent on security incident resolution, Surface deployment to employees, employee retention, and boot up time. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
                    })}
                    {hasValidChartData(completeReport.employeeEmpowerment) ? (
                      <div className={`${reportStyles.chart} tco-report-chart`}>
                        <ReactEcharts
                          options={STACKED_BAR_CHART_OPTIONS(
                            chartData.employeeEmpowerment,
                            TCO_REPORT_CHART_COLOR.employee
                          )}
                          width="100%"
                          height="31.25rem"
                          id="chart7"
                        />
                      </div>
                    ) : null}
                  </Stack>
                  <ChartDisclaimer />
                </Stack>
                {uncheckWarning("employeeEmpowerment")}
                {completeReport.employeeEmpowerment?.rows.length
                  ? chartDataList(
                      completeReport.employeeEmpowerment,
                      TCO_REPORT_CHART_COLOR.employee
                        .slice(
                          0,
                          completeReport.employeeEmpowerment?.rows.length
                        )
                        .reverse(),
                      true
                    )
                  : null}
              </>
            ) : null}
          </section>









          <section
            className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
          >
            {completeReport.sustainability ? (
              <>
                <Stack className={reportStyles.chartWrapper}>
                  <Stack horizontal className="tco-report-chart-wrapper">
                    {chartDescription({
                      title: `Sustainability Savings over ${sessionStorage.getItem("activeView") === "Advanced" ? sessionStorage.getItem("years") : 3} Years`,
                      value: [
                        {
                          val: formatCurrency(
                            completeReport.sustainability?.savings,
                            currencySymbol,
                            0
                          ),
                          text: "Expected Savings",
                        },
                      ],
                    })}
                    {hasValidChartData(completeReport.sustainability) ? (
                      <div className={`${reportStyles.chart} tco-report-chart`}>
                        {/* <ReactEcharts
                          options={STACKED_BAR_CHART_OPTIONS(
                            chartData.sustainability,
                            TCO_REPORT_CHART_COLOR.employee
                          )}
                          width="100%"
                          height="31.25rem"
                          id="chart8"
                        /> */}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
                {/* {uncheckWarning("employeeEmpowerment")} */}
                {completeReport.sustainability?.rows.length
                  ? chartDataListSustainability(
                      completeReport.sustainability,
                      TCO_REPORT_CHART_COLOR.employee
                        .slice(
                          0,
                          completeReport.sustainability?.rows.length
                        )
                        .reverse(),
                      true
                    )
                  : null}
              </>
            ) : null}
          </section>








          <section
            className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
          >
            <h2
              className={`${reportStyles.sectionTitle} tco-report-section-title`}
              tabIndex={0}
            >
              Methodology
            </h2>
            <p>
              IDC Research Study conducted from surveys and interviews between
              December 2021 - February 2022. All respondents were IT
              decision-makers at large organizations (250-5000+ employees)
              representing organizations from the United States, Australia,
              India, Spain, France, United Kingdom, New Zealand, and Germany.
              Cost & Savings findings based on average cost and time estimates
              provided directly by respondents; actual costs and savings may
              vary based on your specific Device Mix and deployment.
            </p>
            <p>
              *Data point derived from 17 in-depth interviews. All other data
              points derived from 800 survey results (700 Surface organizations
              with at least 150 Surface Laptop and 2-in-1 Tablets available
              since 2019, 100 non-Surface organizations). Surface Residual Value
              adjusted by IDC to reflect average after 36 months.
            </p>
            <p>
              <b>
                For the detailed report, click{" "}
                <a
                  href="https://aka.ms/SurfaceIDCWhitepaper"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>
                .
              </b>
            </p>
            {/* <div
              className={`${reportStyles.pdfLinkWrapper} tco-report-pdf-link-wrapper`}
            >
              <div
                className={reportStyles.pdfLink}
                onClick={() => downloadPdf("US49453722-BVSnapshot")}
              >
                US49453722-BVSnapshot.pdf
              </div>
              <div
                className={reportStyles.pdfLink}
                onClick={() => downloadPdf("US49453722-BVWP-ADA")}
              >
                US49453722-BVWP-ADA (1).pdf
              </div>
            </div> */}
          </section>
          <BackToTop
            handleClick={() =>
              detailReportRef.current.scrollIntoView({ behavior: "smooth" })
            }
          />
        </div>
      ) : (
        <Spinner className={reportStyles.spinner} size={SpinnerSize.large} />
      )}
    </>
  );
};

export default TcoCompleteReport;
