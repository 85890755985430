import { mergeStyleSets, Stack } from "@fluentui/react";
import ProductDetailsTile from "v2/components/product-details-tile/ProductDetailsTile";
import { ProductDetails } from "v2/types/productTypes";
import productImageNameMapping from "../../store/ProductImageNameMapping.json";
import CostSavingsBanner from "v2/components/cost-savings-banner/CostSavingsBanner";
import { Text } from "@fluentui/react-components";
import NavigationButtons from "v2/components/navigation-buttons/NavigationButtons";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { useEffect } from "react";
import { deviceMap } from "v2/utilities/mapping";
import { isDeviceValid } from "v2/utilities/helper";
import Header, { IHeaderProps } from "v2/components/common/Header";

const style = mergeStyleSets({
  chooseProductMixHeader: {
    color: "#1E1E1E",
    fontSize: "24px",
    fontWeight: 700,
    margin: "0   ",
  },
  chooseYourMixWrapper: {
    margin: "0 0 50px 34px",
    width: "95%",
  },
});

const headerDetails: IHeaderProps = {
  title: "Choose your Surface mix",
  subtitle:
    "Choose your mix of Surface devices and personalize your product specifications below.",
  step: "STEP 2",
};

const ChooseProductMix: React.FC = () => {
  const { selectedDevices, setTcoReportPayload } = useTcoCalculationContext();
  const { setTcoReportPayloadList } = useTcoCalculationContext();
  const imageMapping = productImageNameMapping.productImageNameMapping;

  useEffect(() => {
    const isValid = selectedDevices?.every(isDeviceValid);
    if (!isValid) return;
    setTcoReportPayload((prev) => ({
      ...prev,
      deviceMixPortfolio: deviceMap(selectedDevices),
    }));
    
    setTcoReportPayloadList((prev) => ({
      ...prev,
      deviceMixPortfolio: deviceMap(selectedDevices),
    }));
  }, [selectedDevices]);



 


  return (
    <Stack tokens={{ childrenGap: 24 }}>
      <CostSavingsBanner />
     
      <Stack
        className={style.chooseYourMixWrapper}
        verticalFill
        tokens={{ childrenGap: 24 }}
      >
        <Stack verticalFill tokens={{ childrenGap: 16 }}>
          <Header
            title={headerDetails.title}
            subtitle={headerDetails.subtitle}
            step={headerDetails.step}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 24 }}>
          {selectedDevices?.map((product: ProductDetails, index: number) => (
            <ProductDetailsTile
              key={index}
              product={product}
              image={
                imageMapping?.find((x) => x.name === product.name)?.imageName ||
                ""
              }
              index={index}
            />
          ))}
        </Stack>
        <NavigationButtons />
      </Stack>
    
    </Stack>
  );
};

export default ChooseProductMix;
