import { mergeStyleSets, Stack, StackItem, Text } from "@fluentui/react";
import { FC, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LeftNavBar from "v2/components/navbar/LeftNavBar";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import TcoCalculationProvider from "v2/provider/TcoCalculationProvider";
import { CustomerDetail } from "v2/types/customerDetailType";

//need to add styles for responsiveness
const styles = mergeStyleSets({
  mainContainer: {
    height: "100%",
    margin: "auto",
    "@media (min-width: 859px) ": {
      maxWidth: "100vw",
    },
    "@media (min-width: 1700px)": {
      maxWidth: "1600px",
    },
  },
});

const TcoLayoutComponent: FC = () => {
  const { customerInfo } = useTcoCalculationContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isInfoAvailable = ["companySize", "industry", "role"].every(
      (key) => customerInfo?.[key as keyof CustomerDetail] !== ""
    );
    if (!isInfoAvailable) {
      navigate("/tell-us-about-yourself");
      window.history.replaceState(null, '', window.location.href);
    }
  }, [customerInfo, navigate]);

  return (
    <Stack horizontal className={styles.mainContainer}>
      <LeftNavBar />
      <Outlet />
    </Stack>
  );
};

const TcoLayout: FC = () => {
  return (
    <TcoCalculationProvider>
      <TcoLayoutComponent />
    </TcoCalculationProvider>
  );
};

export default TcoLayout;
