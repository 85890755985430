import { mergeStyleSets, Stack } from "@fluentui/react";
import { TcoReportRow, TcoReportSegment } from "v2/types/apiResponseTypes";
import { Currency } from "v2/types/currencyType";
import { convertFromUSDToOther, formatCurrency } from "v2/utilities/currencyConverter";
const tableStyles = mergeStyleSets({
    tableWrapper: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        alignItems: "center",
        width: "100%",
        minWidth: "600px",
    },
    tableTitleWrapper: {
        justifyContent: "space-between",
        // marginLeft: "23%",
    },
    tableTitle: {
        fontWeight: 600,
        width: "20%",
        lineHeight: "2.125rem",
    },
    tableTitle1: {
        width: "64%",
    },
    tableRow: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5rem 0",
        borderBottom: "1px solid #EDEBE9",
    },
    tableRow1: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        // fontWeight: 600,
    },
    tableRow2: {
        width: "15%",
    },
    marker: {
        display: "inline-block",
        marginLeft: "0.625rem",
        marginRight: "0.5rem",
        borderRadius: "1.25rem",
        width: "1.25rem",
        height: "1.25rem",
        flexShrink: 0,
    },
    rowSurfaceValue: {
        width: "60%",
    },
    rowValueHourWrapper: {
        width: "100%",
    },
    rowHourValue: {
        width: "40%",
    },
    withBottom: {
        borderBottom: "0.5px solid #107C10",
    },
    withoutBottom: {
        borderBottom: "unset",
    },
    totalLabel: {
        width: "50%",
        fontWeight: 600,
        paddingLeft: "2.375rem",
        boxSizing: "border-box",
    },
    greyBackground: {
        backgroundColor: "#F3F2F1",
    },
    inputWarning: {
        alignItems: "center",
        backgroundColor: "#FECABD",
        padding: "3rem 3.375rem",
        gap: "1.625rem",
    },
    infoIcon: {
        fontSize: "1.25rem",
    },
    inputWarningText: {
        fontSize: "1rem",
        lineHeight: "1.375rem",
    },
});
const CostsDetailTooltip: React.FC<{ colors: string[], data: TcoReportSegment, currency: Currency }> = (props) => {
    const { colors, data, currency } = props as { colors: string[], data: { [key: string]: any }, currency: Currency };
    const tableTitleList = [
        {
            label: "Surfaces",
            valueKey: "surfaceValue",
        },
        {
            label: "Other PCs",
            valueKey: "pcValue",
        },
        {
            label: "Savings",
            valueKey: "savings",
        },
    ];

    const labelMapping: { [key: string]: string } = {
        "Total Device Costs": "Device cost per unit",
        "Total Accessories Cost": "Accessories cost per unit",
        "Total M365 Licensing Costs": "M365 Licensing cost",
        "Total Extended Warranty & Maintenance Costs": "Extended Warranty & Maintenance cost"
    };
    const getCustomizeValue = (item: any, key: any, prefix: string) => {
        const convertedValue = convertFromUSDToOther(item[key], currency);
        let result = formatCurrency(convertedValue, prefix, 0);
        if (
            (item.isSavingsInput && key === "savings") ||
            (item.isSurfaceInput && key === "surfaceValue") ||
            (item.isOtherPcInput && key === "pcValue")
        ) {
            result = result + "*";
        }
        if (item.isSavingsInput && key !== "savings") {
            result = "----";
        }
        return result;
    };
    return (
        <div className={`${tableStyles.tableWrapper} tco-report-table-wrapper`}>
            <Stack
                horizontal
                className={`${tableStyles.tableTitleWrapper} tco-report-table-title mobile-hide`}
                tabIndex={0}
            >
                <div className={tableStyles.tableTitle1}></div>
                {tableTitleList.map((item, index) => (
                    <Stack.Item
                        key={`${index}-${item.label}`}
                        className={tableStyles.tableTitle}
                    >
                        {item.label}
                    </Stack.Item>
                ))}
            </Stack>
            {data?.rows?.map((item: TcoReportRow, index: any) => {
                const markerColor = { backgroundColor: colors[index] };
                const newLabel = labelMapping[item.label] || item.label;
                return (
                    <Stack
                        horizontal
                        key={index}
                        className={`${tableStyles.tableRow} tco-report-table-row ${item.savings < 0 ? tableStyles.greyBackground : ""
                            }`}
                        tabIndex={0}
                    >
                        <Stack.Item className={tableStyles.tableRow1}>
                            <div className={tableStyles.marker} style={markerColor}></div>
                            <div>{newLabel}</div>
                        </Stack.Item>
                        {tableTitleList.map((i) => (
                            <Stack
                                horizontal
                                className={tableStyles.tableRow2}
                                key={`${i.label}-${index}`}
                            >
                                <div className="mobile-display-only tco-report-mobile-table-row-label">
                                    {i.label}
                                </div>
                                <>{getCustomizeValue(item, i.valueKey, currency?.symbol || "$")}</>

                            </Stack>
                        ))}
                    </Stack>
                );
            })}
            <Stack
                horizontal
                className={`${tableStyles.tableRow} ${tableStyles.withoutBottom} tco-report-table-row`}
                tabIndex={0}
            >
                <Stack.Item
                    className={`${tableStyles.totalLabel} tco-report-table-total-label`}
                >
                    Total
                </Stack.Item>
                {tableTitleList.map((i) => (
                    <Stack horizontal className={tableStyles.tableRow2} key={i.label} styles={{ root: { fontWeight: 600, } }}>
                        <div className="mobile-display-only tco-report-mobile-table-row-label">
                            {i.label}
                        </div>

                        <>{formatCurrency(convertFromUSDToOther(data[i.valueKey], currency), currency?.symbol || "$", 0)}</>

                    </Stack>
                ))}
            </Stack>

        </div>
    );

}
export default CostsDetailTooltip;