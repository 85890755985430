import { FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const People = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M1 30.6666H10L14.6667 22.6666C14.6667 18.5999 11.4 15.3333 7.33333 15.3333C3.26667 15.3333 0 18.5999 0 22.6666V29.6666C0 30.1999 0.466667 30.6666 1 30.6666Z"
        fill="url(#paint0_linear_1525_2512)"
      />
      <path
        d="M6.6665 14C8.87564 14 10.6665 12.2091 10.6665 10C10.6665 7.79086 8.87564 6 6.6665 6C4.45736 6 2.6665 7.79086 2.6665 10C2.6665 12.2091 4.45736 14 6.6665 14Z"
        fill="url(#paint1_linear_1525_2512)"
      />
      <path
        d="M22.3335 30.6666H31.0002C31.5335 30.6666 32.0002 30.1999 32.0002 29.6666V22.6666C32.0002 18.5999 28.7335 15.3333 24.6668 15.3333C20.6002 15.3333 17.3335 18.5999 17.3335 22.6666L22.3335 30.6666Z"
        fill="url(#paint2_linear_1525_2512)"
      />
      <path
        d="M25.3335 14C27.5426 14 29.3335 12.2091 29.3335 10C29.3335 7.79086 27.5426 6 25.3335 6C23.1244 6 21.3335 7.79086 21.3335 10C21.3335 12.2091 23.1244 14 25.3335 14Z"
        fill="url(#paint3_linear_1525_2512)"
      />
      <mask
        id="mask0_1525_2512"
        maskUnits="userSpaceOnUse"
        x="0"
        y="15"
        width="32"
        height="16"
      >
        <path
          d="M1 30.6666H10L14.6667 22.6666C14.6667 18.5999 11.4 15.3333 7.33333 15.3333C3.26667 15.3333 0 18.5999 0 22.6666V29.6666C0 30.1999 0.466667 30.6666 1 30.6666Z"
          fill="url(#paint4_linear_1525_2512)"
        />
        <path
          d="M22.3333 30.6666H31C31.5333 30.6666 32 30.1999 32 29.6666V22.6666C32 18.5999 28.7333 15.3333 24.6667 15.3333C20.6 15.3333 17.3333 18.5999 17.3333 22.6666L22.3333 30.6666Z"
          fill="url(#paint5_linear_1525_2512)"
        />
      </mask>
      <g mask="url(#mask0_1525_2512)">
        <g filter="url(#filter0_dd_1525_2512)">
          <path
            d="M9.6665 30.6665H22.3332C22.8665 30.6665 23.3332 30.1998 23.3332 29.6665V19.9998C23.3332 15.9332 20.0665 12.6665 15.9998 12.6665C11.9332 12.6665 8.6665 15.9332 8.6665 19.9998V29.6665C8.6665 30.1998 9.13317 30.6665 9.6665 30.6665Z"
            fill="url(#paint6_linear_1525_2512)"
          />
        </g>
      </g>
      <path
        d="M9.6665 30.6667H22.3332C22.8665 30.6667 23.3332 30.2001 23.3332 29.6667V20.0001C23.3332 15.9334 20.0665 12.6667 15.9998 12.6667C11.9332 12.6667 8.6665 15.9334 8.6665 20.0001V29.6667C8.6665 30.2001 9.13317 30.6667 9.6665 30.6667Z"
        fill="url(#paint7_linear_1525_2512)"
      />
      <path
        d="M16 11.3333C18.2091 11.3333 20 9.54239 20 7.33325C20 5.12411 18.2091 3.33325 16 3.33325C13.7909 3.33325 12 5.12411 12 7.33325C12 9.54239 13.7909 11.3333 16 11.3333Z"
        fill="url(#paint8_linear_1525_2512)"
      />
      <defs>
        <filter
          id="filter0_dd_1525_2512"
          x="5.6665"
          y="10.6665"
          width="20.6665"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_2512"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_2512"
            result="effect2_dropShadow_1525_2512"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_2512"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1525_2512"
          x1="10.3172"
          y1="30.4834"
          x2="2.5192"
          y2="16.9769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0669BC" />
          <stop offset="1" stopColor="#1B9DE2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1525_2512"
          x1="8.66684"
          y1="13.4647"
          x2="4.66617"
          y2="6.53533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0669BC" />
          <stop offset="1" stopColor="#1B9DE2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1525_2512"
          x1="30.2063"
          y1="31.2776"
          x2="21.4255"
          y2="16.0688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#184F85" />
          <stop offset="1" stopColor="#0C63AD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1525_2512"
          x1="27.3338"
          y1="13.4647"
          x2="23.3332"
          y2="6.53533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#184F85" />
          <stop offset="1" stopColor="#0C63AD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1525_2512"
          x1="30.2061"
          y1="31.2776"
          x2="21.4253"
          y2="16.0688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#184F85" />
          <stop offset="1" stopColor="#0C63AD" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1525_2512"
          x1="30.2061"
          y1="31.2776"
          x2="21.4253"
          y2="16.0688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#184F85" />
          <stop offset="1" stopColor="#0C63AD" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1525_2512"
          x1="20.5439"
          y1="31.8522"
          x2="10.6084"
          y2="14.6434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#35C1F1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1525_2512"
          x1="20.5439"
          y1="31.8525"
          x2="10.6084"
          y2="14.6437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#35C1F1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1525_2512"
          x1="18.0003"
          y1="10.7979"
          x2="13.9997"
          y2="3.86859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#35C1F1" />
        </linearGradient>
      </defs>
    </svg>
  );
});
