import {
  DevicePortfolio,
  TcoAssumptionsPayload,
} from "v2/types/apiPayloadTypes";
import { TcoAssumption } from "v2/types/apiResponseTypes";
import { ProductDetails } from "v2/types/productTypes";

export const tcoAssumptionMap = (
  tcoAssumptions: TcoAssumption[]
): TcoAssumptionsPayload => {
  if (tcoAssumptions.length < 1) return {} as TcoAssumptionsPayload;
  const mappedTcoAssumption: TcoAssumptionsPayload = tcoAssumptions?.reduce(
    (acc, assumption) => {
      acc[assumption.tco_code] = assumption.tco_default;
      return acc;
    },
    {} as TcoAssumptionsPayload
  );
  return mappedTcoAssumption;
};

export const deviceMap = (devices: ProductDetails[]): DevicePortfolio[] => {
  const mappedDevice: DevicePortfolio[] = devices
    ?.filter((device: ProductDetails) => device.code !== "")
    .map(
      (device: ProductDetails, index: number) =>
        ({
          seqNo: index,
          product: device.name,
          quantity: device.quantity,
          msrp: device.msrp,
          microsoft365Subscription: device.subscription,
        } as DevicePortfolio)
    );
  return mappedDevice;
};
