import { FC, useEffect, useState } from "react";
import { Link, mergeStyleSets, Stack, StackItem, Text } from "@fluentui/react";
import NavigationMenu from "./NavigationMenu";
import { CodeNamePair } from "v2/types/commonTypes";
import {
  Dropdown,
  FluentProvider,
  makeStyles,
  Option,
  OptionOnSelectData,
  SelectionEvents,
} from "@fluentui/react-components";
import { myTheme } from "./theme";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { TcoReportPayload } from "v2/types/apiPayloadTypes";

const styles = mergeStyleSets({
  navContainer: {
    padding: "24px",
    height: "100%",
    width: "215px !important",
  },
  title: {
    color: "#ffffff",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "30px",
  },
  subTitle: {
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
  },
  regionTitle: {
    color: "#ffffff",
    fontweight: "700",
  },
  text: {
    color: "#ffffff",
  },
  tabStyles: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "35px",
    rowGap: "20px",
  },
  link: {
    color: "#66BCFF",
    textDecoration: " underline",
    selectors: {
      ":hover": {
        color: "#3999e3",
      },
      ":active": {
        color: "#3999e3",
      },
    },
  },
});

const useStyles = makeStyles({
  listbox: {
    maxHeight: "160px !important",
  },
  option: {
    height: "32px",
  },
  dropDown: {
    "& .f1exfvgq": {
      minWidth: "auto",
    },
    "& .fg706s2": {
      borderBottomStyle: "none",
    },
    "& .fly5x3f": {
      fontSize: "14px",
    },
    "& .f9ez7ne": {
      borderBottom: "none",
    },
    "& .fye6m5k": {
      paddingLeft: "0",
    },
  },
});

const LeftNavBar: FC = () => {
  const {
    tcoConfig,
    selectedCountry,
    currency,
    setSelectedCountry,
    setTcoReportPayload,
  } = useTcoCalculationContext();
  const onOptionSelect = (event: SelectionEvents, data: OptionOnSelectData) => {
    const currentCountry = tcoConfig?.countries?.find(
      (country: CodeNamePair) => country.code === data.optionValue
    );
    currentCountry && setSelectedCountry(currentCountry);
    setTcoReportPayload((prev: TcoReportPayload) => ({
      ...prev,
      selectedCountry: selectedCountry.code,
      currency: currency?.currencyCode,
      currencyConversionMultipleFromUSD: currency?.usdPerCurrency,
      currencyConversionMultipleToUSD: currency?.currencyPerUSD,
    }));
  };

  const AUTH_RESERLLER_URL =
    "https://www.microsoft.com/en-us/surface/business/where-to-buy-microsoft-surface#DEVICESRESELLERS";

  const customStyles = useStyles();
  return (
    <FluentProvider theme={myTheme}>
      <Stack
        className={styles.navContainer}
        tokens={{ childrenGap: 20 }}
        verticalAlign="space-between"
      >
        <StackItem>
          <Stack tokens={{ childrenGap: 40 }}>
            <Text className={styles.title}>
              Surface Total Cost Of Ownership (TCO) Calculator
            </Text>
            <Text className={styles.subTitle}>Build your report:</Text>
            <NavigationMenu />
            <Stack
              verticalFill
              tokens={{ childrenGap: 10 }}
              styles={{
                root: { borderTop: "1px solid #2C5483", paddingTop: "20px" },
              }}
            >
              <Text
                styles={{
                  root: { fontWeight: "700", color: "#fff" },
                }}
              >
                REGION / CURRENCY
              </Text>
              <StackItem>
                {selectedCountry && (
                  <Stack className={customStyles.dropDown}>
                    <Dropdown
                      size="small"
                      title="Select country dropdown"
                      appearance="underline"
                      positioning={{ position: "below", autoSize: "height" }}
                      value={selectedCountry.name}
                      onOptionSelect={onOptionSelect}
                      listbox={{ className: customStyles.listbox }}
                    >
                      {tcoConfig?.countries?.map((country: CodeNamePair) => (
                        <Option value={country.code} key={country.code}>
                          {country.name}
                        </Option>
                      ))}
                    </Dropdown>
                  </Stack>
                )}
              </StackItem>
              <Text className={styles.text}>
                {currency?.symbol}&nbsp;
                {currency?.currencyCode}
              </Text>
            </Stack>
          </Stack>
        </StackItem>
        <StackItem align="end">
          <Text className={styles.text} style={{ paddingTop: "30px" }}>
            To learn more about Microsoft Surface and obtain a quote, contact a
            Surface &nbsp;
            <Link
              href={AUTH_RESERLLER_URL}
              target="_blank"
              className={styles.link}
            >
              Commercial Authorized Reseller.
            </Link>
          </Text>
        </StackItem>
      </Stack>
    </FluentProvider>
  );
};

export default LeftNavBar;
