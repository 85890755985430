interface SuccessStoryData {
  heading: string;
  description: string[];
  imageUrl: string;
}

export type HeadingSubHeadingPair = {
  heading: string;
  subHeading: string;
};

export type ReportBlockData = {
  sectionHeading: string;
  headingSubHeadingPair: HeadingSubHeadingPair[];
  footer: string;
};

export function extractReportBlockData(element: Element): ReportBlockData {
  const reportBlock = element.querySelector("#reportBlock");
  if (!reportBlock)
    return { sectionHeading: "", headingSubHeadingPair: [], footer: "" };
  const sectionHeading =
    reportBlock.querySelector("#sectionHeading")?.textContent?.trim() || "";

  const headingSubHeadingPair: HeadingSubHeadingPair[] = Array.from(
    reportBlock.querySelectorAll("#detailsSection")
  ).map((stackItem) => {
    const heading =
      stackItem.querySelector("#heading")?.textContent?.trim() || "";
    const subHeading =
      stackItem.querySelector("#subHeading")?.textContent?.trim() || "";
    return { heading, subHeading };
  });

  const footer =
    reportBlock.querySelector("#reportFooter")?.textContent?.trim() || "";

  return {
    sectionHeading,
    headingSubHeadingPair,
    footer,
  };
}

const extractHeaderAndImg = (
  element: Element,
  id: "industry" | "successStory"
): { imageUrl: string; heading: string } => {
  const imageElement = element.querySelector(
    "#" + id + "Img"
  ) as HTMLImageElement;
  const imageUrl = imageElement?.src || "";
  const heading = element.querySelector("#" + id + "Heading");
  return { imageUrl, heading: heading?.textContent?.trim() || "" };
};

export const extractIndustryData = () => {
  const element = document.querySelector("#industry");
  if (!element) return null;

  const { imageUrl, heading } = extractHeaderAndImg(element, "industry");
  const description =
    element.querySelector("#description")?.textContent?.trim() || "";

  return { imageUrl, heading, description };
};

export const extractSuccessStoryData = (element: Element): SuccessStoryData => {
  const successStory = element.querySelector("#successStory");
  if (!successStory) return { heading: "", description: [], imageUrl: "" };

  const { imageUrl, heading } = extractHeaderAndImg(
    successStory,
    "successStory"
  );
  const description: string[] = Array.from(
    successStory.querySelectorAll('[id^="paragraph"]')
  ).map((paragraph) => paragraph.textContent?.trim() || "");
  return { heading, description, imageUrl };
};

export const fetchLinks = (): string[] => {
  const additionalLinksDiv = document.getElementById('additional-links');
  if (additionalLinksDiv) {
    const anchorTags = additionalLinksDiv.querySelectorAll('a');
    return Array.from(anchorTags).map((a) => a.getAttribute('href') || '');
  }
  return [];
};
