import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import { FC } from "react";
import Banner from "../../components/landing-page/Banner";
import LandingPageContent from "v2/components/landing-page/LandingPageContent";

export const commonStyles = mergeStyleSets({
  container: {
    // maxWidth: "1316px",
    paddingRight: "5%",
    paddingLeft: "5%",
    "@media (min-width: 860px) ": {
      maxWidth: "100vw",
    },
    "@media (min-width: 1700px)": {
      maxWidth: "1700px",
    },
  },
  background: {
    background: "linear-gradient(87deg, #102250 0.7%, #00649D 55.44%)",
  },
});

const TcoLandingPage: FC = () => {
  return (
    <Stack style={{ paddingBottom: "60px" }}>
      <StackItem className={commonStyles.background} align="center">
        <Banner />
      </StackItem>
      <StackItem className={commonStyles.container} align="center">
        <LandingPageContent />
      </StackItem>
    </Stack>
  );
};
export default TcoLandingPage;
