import { FC } from "react";
import { Stack, Text } from "@fluentui/react";
import { Image, makeStyles } from "@fluentui/react-components";
import ReportBlock from "./ReportBlock";
import { TcoReportLayoutData } from "v2/types/apiResponseTypes";
import SuccessStory from "./SuccessStory";

const useStyles = makeStyles({
  container: {
    background: "#F3F9FC",
    padding: "24px",
    paddingTop: "24px",
  },
  paragraph: {
    padding: "24px",
  },
});

const ReportLayout: FC<{ data: TcoReportLayoutData; valueType?: string }> = ({ data, valueType = "savings" }) => {
  const styles = useStyles();
  return (
    <Stack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={styles.container}
      >
        <ReportBlock data={data?.reportBlock} valueType={valueType} />
        <SuccessStory data={data?.successStory} />
      </Stack>
    </Stack>
  );
};

export default ReportLayout;
