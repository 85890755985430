import { TcoReportPayload } from "v2/types/apiPayloadTypes";
import {
  TcoFinalReport,
  TcoReportRow,
  TcoReportSegment,
} from "v2/types/apiResponseTypes";
import { ProductDetails } from "v2/types/productTypes";

export const validateTcoReportPayload = (
  payload: TcoReportPayload
): boolean => {
  return (
    Object.keys(payload.assumptions).length > 0 &&
    payload.country.trim() !== "" &&
    payload.currency.trim() !== "" &&
    payload.years > 0 &&
    payload.currencyConversionMultipleFromUSD > 0 &&
    payload.currencyConversionMultipleToUSD > 0 &&
    payload.deviceMixPortfolio.length > 0 &&
    payload.industry.trim() !== "" &&
    Object.keys(payload.options).length > 0 &&
    Object.keys(payload.sustainability).length > 0
  );
};

export const isDeviceValid = (device: ProductDetails) => {
  const { name, msrp, quantity, subscription, processor, driveSize, ram } =
    device;
  if (name === "") return true;
  return (
    [name, subscription, processor, driveSize, ram].every(Boolean) &&
    msrp > 0 &&
    quantity &&
    quantity > 0
  );
};

export const isPositiveNumber = (val: any) => {
  val = val.replaceAll(",", "");
  const isNumberRegex = /^\d+(\.\d+)?$/;
  const isHalfDecimalRegex = /^\d+\.$/;
  if (val.match(isHalfDecimalRegex)) {
    return true;
  }
  if (!val || !val.match(isNumberRegex)) {
    return false;
  }
  return true;
};

export const convertStringToNumber = (str: string) => {
  if (typeof str === "number") return str;
  str = str?.replaceAll(",", "");
  return Number(str);
};

export const formatNumber = (val: any) => {
  val = val.replaceAll(",", "");
  const isNumberRegex = /^-?\d+(\.\d+)?$/;
  const isHalfDecimalRegex = /^-?\d+\.$/;

  if (!val || val.match(isHalfDecimalRegex)) {
    return val;
  }
  if (!val.match(isNumberRegex)) {
    return "";
  }
  let number;
  if (val.includes(".")) {
    const arr = val.split(".");
    let current = arr[0] + "." + arr[1].slice(0, 2);
    const tailZeroIndex = current.search(/0+$/);
    number = parseFloat(current);
    // add tail zero back
    if (tailZeroIndex !== -1) {
      let result = number.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });
      if (!result.includes(".")) {
        result = `${result}.${current.slice(tailZeroIndex)}`;
      } else {
        result = `${result}${current.slice(tailZeroIndex)}`;
      }
      return result;
    }
  } else {
    number = parseInt(val);
  }
  return number.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const roundValues = (report: TcoFinalReport): TcoFinalReport => {
  const round = (value: number) => Math.round(value);

  const roundRow = (row: TcoReportRow): TcoReportRow => ({
    ...row,
    pcValue: round(row.pcValue),
    surfaceValue: round(row.surfaceValue),
  });

  const roundSegment = (segment: TcoReportSegment): TcoReportSegment => ({
    ...roundRow(segment),
    rows: segment.rows?.map(roundRow),
  });

  return {
    ...report,
    employeeEmpowerment: roundSegment(report.employeeEmpowerment),
    itSimplification: roundSegment(report.itSimplification),
    sustainability: roundSegment(report.sustainability),
    totalCosts: roundSegment(report.totalCosts),
    totalSavings: roundSegment(report.totalSavings),
    details: report.details.map((detail) => ({
      ...detail,
      rows: report.details,
    })),
  };
};

export const isPositiveInteger = (val: any) => {
  val = val.replaceAll(",", "");
  const isIntegerRegex = /^\d+$/;
  if (!val || !val.match(isIntegerRegex) || val === "0") {
    return false;
  }
  return true;
};

export const splitLargeNumber = (num: any) => {
  const numString = convertNumberToString(num);
  const arr = numString?.split(",");
  let splitIndex = arr.length - 1;
  if (arr.length > 5) {
    splitIndex = 4;
  }
  const leftPart = arr.slice(0, splitIndex).join(",");
  const rightPart = arr.slice(splitIndex).join(",");
  return [leftPart, rightPart];
};

export const convertNumberToString = (
  num: any,
  prefix?: any,
  maximumFractionDigits?: number
) => {
  if (num === undefined) return;
  let str = num.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits ?? 2,
  });
  if (prefix) {
    let arr = str.split("-");
    if (arr.length > 1) {
      str = `-${prefix}${arr[1]}`;
    } else {
      str = `${prefix}${str}`;
    }
  }
  return str;
};

// Utility function to split array into chunks
export const chunkArray = (array: any[], chunkSize: number) => {
  if (chunkSize <= 0) return [];
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const getPercentage = (value1: any, value2: any) => {
  const percentage = Math.round((value1 * 100) / value2);

  return value2 === 0 ? 0 : Math.abs(percentage);
};

export const formatNumberWithUnit = (
  num: number,
  prefix: string | null,
  maximumFractionDigits: number
) => {
  if (!num || isNaN(num)) return 0;
  if (typeof num === "string") {
    num = Number(num);
  }
  if (Math.abs(num) / 1000 < 1) {
    return convertNumberToString(num, prefix, maximumFractionDigits);
  }
  if (Math.abs(num) > 1000000) {
    return `${convertNumberToString(
      num / 1000000,
      prefix,
      maximumFractionDigits
    )}M`;
  }
  return `${convertNumberToString(num, prefix, maximumFractionDigits)}`; //`${convertNumberToString(num / 1000, prefix, maximumFractionDigits)}K`;
};

export const numberToWords = (num:number) => {
  const words = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];
  return words[num] || num.toString();
};
