import { mergeStyleSets } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { FC } from "react";

const styles = mergeStyleSets({
  defaultButton: {
    background: "#E4E2DE !important",
    color: "#1E1E1E !important",
  },
  activeButton: {
    background: "#0078D4 !important",
    color: "#FFFFFF !important",
  },
});

interface CircularButtonProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const CircularButton: FC<CircularButtonProps> = ({
  label,
  isActive,
  onClick,
}) => {
  return (
    <Button
      className={isActive ? styles.activeButton : styles.defaultButton}
      shape="circular"
      key={label}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default CircularButton;
