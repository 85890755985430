import { ChangeEvent, FC } from "react";
import { mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import {
  Label,
  Input,
  Text,
  OptionOnSelectData,
  InputOnChangeData,
} from "@fluentui/react-components";
import customerInfoData from "v2/store/customerInfoData.json";
import LabelledDropdown from "../common/LabelledDropdown";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";

const styles = mergeStyleSets({
  formContainer: {
    borderRadious: "8px",
    border: "1px solid #F4F4F4",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
    padding: "24px",
    width: "100%",
  },
  inputFieldStack: {
    maxWidth: "524px",
    "@media (max-width: 1087px)": {
      maxWidth: "250px",
    },
  },
});

const CustomerInfoForm: FC = () => {
  const { customerInfo, setCustomerInfo } = useTcoCalculationContext();

  const onOptionSelect = (data: OptionOnSelectData, dropDownName: string) => {
    setCustomerInfo({ ...customerInfo, [dropDownName]: data.optionText });
  };

  const onInputChange = (
    ev: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setCustomerInfo({ ...customerInfo, companyName: data.value });
  };

  return (
    <Stack className={styles.formContainer} tokens={{ childrenGap: 16 }}>
      <Stack.Item style={{ width: "80%" }}>
        <Stack horizontal wrap tokens={{ childrenGap: 24 }}>
          <StackItem grow>
            <LabelledDropdown
              label="Industry"
              isRequired
              options={customerInfoData.industries}
              dropdownProp={{
                placeholder: "Select",
                value: customerInfo.industry,
              }}
              onSelect={(event, data) => onOptionSelect(data, "industry")}
            />
          </StackItem>
          <StackItem grow>
            <LabelledDropdown
              label="Role"
              isRequired
              options={customerInfoData.roles}
              dropdownProp={{
                placeholder: "Select",
                value: customerInfo.role,
              }}
              onSelect={(event, data) => onOptionSelect(data, "role")}
            />
          </StackItem>
          <StackItem grow>
            <LabelledDropdown
              label="Company size"
              isRequired
              options={customerInfoData.companySizes}
              dropdownProp={{
                placeholder: "Select",
                value: customerInfo.companySize,
              }}
              onSelect={(event, data) => onOptionSelect(data, "companySize")}
            />
          </StackItem>
        </Stack>
      </Stack.Item>
      <Stack.Item style={{ maxWidth: "524px" }} grow>
        <Stack
          verticalFill
          tokens={{ childrenGap: 8 }}
          className={styles.inputFieldStack}
        >
          <Label> Company name (Optional)</Label>
          <Text style={{ color: "#707070" }}>
            We will use this field to personalize your TCO report.
          </Text>
          <Input
            placeholder="Enter name"
            onChange={onInputChange}
            value={customerInfo.companyName}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default CustomerInfoForm;
