import { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Divider,
  makeStyles,
} from "@fluentui/react-components";
import { AccordianDetail } from "./DetailMatrix";
import { Stack, StackItem, Text } from "@fluentui/react";

const useStyle = makeStyles({
  header: {
    "& .figsok6": {
      fontSize: "16px",
      fontWeight: "600",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  accordian: {
    "& .f9c4gz4": {
      justifyContent: "flex-start",
    },
  },
  border: {
    display: "flex",
    width: "4px",
    height: "100%",
    background: "#0078D4",
    borderRadius: "4px",
    transform: "scaleY(1)",
    transition: "opacity, transform .8s ease-in-out;",
  },
  panel: {
    transition: "all 0.9s ease-out !important",
  },
  divider: {
    padding: "31px 0px 0px 12px",
  },
  paragraph: {
    textAlign: "justify" as "justify",
  },
});

const DetailAccordian: FC<{ data: AccordianDetail[] }> = ({ data }) => {
  const [openItems, setOpenItems] = useState<string[]>([data[0].id]);
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems);
  };

  useEffect(() => {
    setOpenItems([data[0].id]);
  }, [data]);

  const styles = useStyle();
  return (
    <Accordion
      collapsible
      openItems={openItems}
      onToggle={handleToggle}
      style={{ maxWidth: "392px", minWidth: "285" }}
      className={styles.accordian}
    >
      {data.map((item: AccordianDetail) => {
        return (
          <Stack horizontal tokens={{ childrenGap: 20 }} key={item.id}>
            <StackItem align="stretch">
              {openItems.includes(item.id) && <div className={styles.border} />}
            </StackItem>

            <StackItem styles={{ root: {} }} grow>
              <AccordionItem value={item.id}>
                <AccordionHeader
                  expandIconPosition="end"
                  className={styles.header}
                >
                  {item.title}
                </AccordionHeader>
                <AccordionPanel>
                  <Stack className={styles.paragraph}>
                    {item.content.split("\n").map((line, index) => (
                      <Text key={index}>{line}</Text>
                    ))}
                  </Stack>
                </AccordionPanel>
                <Divider className={styles.divider} />
              </AccordionItem>
            </StackItem>
          </Stack>
        );
      })}
    </Accordion>
  );
};

export default DetailAccordian;
