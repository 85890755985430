import { FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Weather = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
        fill="url(#paint0_linear_1525_4164)"
      />
      <mask
        id="mask0_1525_4164"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="28"
        height="28"
      >
        <path
          d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
          fill="url(#paint1_linear_1525_4164)"
        />
      </mask>
      <g mask="url(#mask0_1525_4164)">
        <g filter="url(#filter0_dd_1525_4164)">
          <path
            d="M14.3999 21.9999C14.3999 20.1333 15.8666 18.5999 17.7332 18.4666C18.3332 16.1333 20.4666 14.3333 23.0666 14.3333C24.9999 14.3333 26.6665 15.3333 27.6665 16.7999C30.0665 16.7999 31.9999 18.7999 31.9999 21.1999C31.9999 22.1333 31.7332 22.9999 31.1999 23.7333C30.3999 24.8666 29.0666 25.5999 27.5999 25.5999H17.9999C16.6666 25.5999 15.4666 24.8666 14.8666 23.7333C14.5999 23.1999 14.3999 22.5999 14.3999 21.9999Z"
            fill="url(#paint2_linear_1525_4164)"
          />
        </g>
      </g>
      <path
        d="M14.3999 21.9999C14.3999 20.1333 15.8666 18.5999 17.7332 18.4666C18.3332 16.1333 20.4666 14.3333 23.0666 14.3333C24.9999 14.3333 26.6665 15.3333 27.6665 16.7999C30.0665 16.7999 31.9999 18.7999 31.9999 21.1999C31.9999 22.1333 31.7332 22.9999 31.1999 23.7333C30.3999 24.8666 29.0666 25.5999 27.5999 25.5999H17.9999C16.6666 25.5999 15.4666 24.8666 14.8666 23.7333C14.5999 23.1999 14.3999 22.5999 14.3999 21.9999Z"
        fill="url(#paint3_linear_1525_4164)"
      />
      <defs>
        <filter
          id="filter0_dd_1525_4164"
          x="11.7332"
          y="12.3333"
          width="22.9334"
          height="16.5999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="1.33333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_4164"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.666667" />
          <feGaussianBlur stdDeviation="1.33333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_4164"
            result="effect2_dropShadow_1525_4164"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_4164"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1525_4164"
          x1="23.0012"
          y1="28.1531"
          x2="8.99887"
          y2="3.90027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E25A01" />
          <stop offset="1" stopColor="#FFD400" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1525_4164"
          x1="23.0012"
          y1="28.1531"
          x2="8.99887"
          y2="3.90027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E25A01" />
          <stop offset="1" stopColor="#FFD400" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1525_4164"
          x1="26.3316"
          y1="26.9413"
          x2="19.6857"
          y2="15.4302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1525_4164"
          x1="26.3316"
          y1="26.9413"
          x2="19.6857"
          y2="15.4302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
});
