import React from "react";
import ReactDOM from "react-dom/client";
//import "./styles.scss";
import "./mainStyles.scss";
import App from "./App";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { AlertProvider } from "./components/Alert";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AlertProvider>
      <App />
    </AlertProvider>
  </React.StrictMode>
);

initializeIcons();
