import { Stack } from "@fluentui/react";
import { Text } from "@fluentui/react-components";
import { FC } from "react";

export interface IHeaderProps {
  title: string;
  subtitle: string;
  step: string;
}

const Header: FC<IHeaderProps> = (props) => {
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Text style={{ fontSize: "14px", fontWeight: "400" }}>{props.step}</Text>
      <Text style={{ fontSize: "24px", fontWeight: "700" }}>{props.title}</Text>
      <Text>{props.subtitle}</Text>
    </Stack>
  );
};

export default Header;
