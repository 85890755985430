import { FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const FeedbackHub = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M30.6665 0H13.9998C13.6462 1.76795e-05 13.3071 0.140499 13.057 0.390544C12.807 0.640588 12.6665 0.979717 12.6665 1.33333V12C12.6665 12.3536 12.807 12.6927 13.057 12.9428C13.3071 13.1928 13.6462 13.3333 13.9998 13.3333H17.9998V17.0343C17.9998 17.1135 18.0233 17.1908 18.0673 17.2566C18.1112 17.3224 18.1737 17.3736 18.2468 17.4039C18.3199 17.4342 18.4004 17.4421 18.478 17.4266C18.5556 17.4112 18.6268 17.3731 18.6828 17.3171L22.6665 13.3333H30.6665C31.0201 13.3333 31.3592 13.1928 31.6093 12.9428C31.8593 12.6927 31.9998 12.3536 31.9998 12V1.33333C31.9998 0.979717 31.8593 0.640588 31.6093 0.390544C31.3592 0.140499 31.0201 1.76795e-05 30.6665 0V0Z"
        fill="url(#paint0_linear_1525_669)"
      />
      <mask
        id="mask0_1525_669"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="12"
        y="0"
        width="20"
        height="18"
      >
        <path
          d="M30.6665 0H13.9998C13.6462 1.76795e-05 13.3071 0.140499 13.057 0.390544C12.807 0.640588 12.6665 0.979717 12.6665 1.33333V12C12.6665 12.3536 12.807 12.6927 13.057 12.9428C13.3071 13.1928 13.6462 13.3333 13.9998 13.3333H17.9998V17.0343C17.9998 17.1135 18.0233 17.1908 18.0673 17.2566C18.1112 17.3224 18.1737 17.3736 18.2468 17.4039C18.3199 17.4342 18.4004 17.4421 18.478 17.4266C18.5556 17.4112 18.6268 17.3731 18.6828 17.3171L22.6665 13.3333H30.6665C31.0201 13.3333 31.3592 13.1928 31.6093 12.9428C31.8593 12.6927 31.9998 12.3536 31.9998 12V1.33333C31.9998 0.979717 31.8593 0.640588 31.6093 0.390544C31.3592 0.140499 31.0201 1.76795e-05 30.6665 0V0Z"
          fill="url(#paint1_linear_1525_669)"
        />
      </mask>
      <g mask="url(#mask0_1525_669)">
        <g filter="url(#filter0_dd_1525_669)">
          <path
            d="M9.33285 18.6667C12.2784 18.6667 14.6662 16.2789 14.6662 13.3333C14.6662 10.3878 12.2784 8 9.33285 8C6.38733 8 3.99951 10.3878 3.99951 13.3333C3.99951 16.2789 6.38733 18.6667 9.33285 18.6667Z"
            fill="url(#paint2_linear_1525_669)"
          />
        </g>
      </g>
      <path
        d="M9.33333 18.6667C12.2789 18.6667 14.6667 16.2789 14.6667 13.3333C14.6667 10.3878 12.2789 8 9.33333 8C6.38781 8 4 10.3878 4 13.3333C4 16.2789 6.38781 18.6667 9.33333 18.6667Z"
        fill="url(#paint3_linear_1525_669)"
      />
      <path
        d="M9.33333 20.0532C11.8087 20.0532 14.1827 21.0366 15.933 22.7869C17.6833 24.5372 18.6667 26.9112 18.6667 29.3866V31.3332C18.6667 31.51 18.5964 31.6796 18.4714 31.8046C18.3464 31.9297 18.1768 31.9999 18 31.9999H0.666667C0.489856 31.9999 0.320286 31.9297 0.195262 31.8046C0.0702379 31.6796 0 31.51 0 31.3332V29.3866C0 26.9112 0.98333 24.5372 2.73367 22.7869C4.48401 21.0366 6.85798 20.0532 9.33333 20.0532Z"
        fill="url(#paint4_linear_1525_669)"
      />
      <defs>
        <filter
          id="filter0_dd_1525_669"
          x="0.999512"
          y="5"
          width="16.6665"
          height="16.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_669"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_669"
            result="effect2_dropShadow_1525_669"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_669"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1525_669"
          x1="27.3927"
          y1="15.43"
          x2="17.2737"
          y2="-2.09665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C59A4" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1525_669"
          x1="27.3927"
          y1="15.43"
          x2="17.2737"
          y2="-2.09665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C59A4" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1525_669"
          x1="12"
          y1="17.9529"
          x2="6.66573"
          y2="8.71376"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#35C1F1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1525_669"
          x1="12.0004"
          y1="17.9529"
          x2="6.66622"
          y2="8.71376"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#35C1F1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1525_669"
          x1="357.231"
          y1="649.575"
          x2="249.453"
          y2="357.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#35C1F1" />
        </linearGradient>
      </defs>
    </svg>
  );
}, "MyFilledIcon");
