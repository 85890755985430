import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { MenuItem as NavMenuItem } from "./NavigationMenu";
import { CustomerDetail } from "v2/types/customerDetailType";
import { isDeviceValid } from "v2/utilities/helper";

export const useMenuItems = () => {
  const { customerInfo, selectedDevices } = useTcoCalculationContext();

  const menuItems: NavMenuItem[] = [
    {
      id: "1",
      label: "Tell us about yourself",
      icon: "NotepadPerson24Regular",
      path: "/tell-us-about-yourself",
      isDisabled: false,
    },
    {
      id: "2",
      label: "Choose your surface mix",
      icon: "Form24Regular",
      path: "/choose-your-surface-mix",
      isDisabled: ["companySize", "industry", "role"].some(
        (key) => customerInfo?.[key as keyof CustomerDetail] === ""
      ),
    },
    {
      id: "3",
      label: "Review business case assumptions",
      icon: "BookCoins24Regular",
      path: "/review-business-case-assumptions",
      isDisabled: !selectedDevices?.every(isDeviceValid),
    },
    {
      id: "4",
      label: "TCO report",
      icon: "ChartMultiple24Regular",
      path: "/tco-report",
      isDisabled: !selectedDevices.every(isDeviceValid),
    },
  ];

  return menuItems;
};
