import { FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Calculator = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M26.6667 32H5.33333C4.59695 32 4 31.403 4 30.6667V1.33333C4 0.596953 4.59695 0 5.33333 0H26.6667C27.403 0 28 0.596953 28 1.33333V30.6667C28 31.403 27.403 32 26.6667 32Z"
        fill="url(#paint0_linear_1525_5354)"
      />
      <g filter="url(#filter0_dd_1525_5354)">
        <path
          d="M24.6665 2.66675H7.33317C6.96498 2.66675 6.6665 2.96523 6.6665 3.33341V7.33342C6.6665 7.70161 6.96498 8.00008 7.33317 8.00008H24.6665C25.0347 8.00008 25.3332 7.70161 25.3332 7.33342V3.33341C25.3332 2.96523 25.0347 2.66675 24.6665 2.66675Z"
          fill="url(#paint1_linear_1525_5354)"
        />
      </g>
      <path
        d="M11.3332 16.0001H7.33317C6.96498 16.0001 6.6665 15.7016 6.6665 15.3334V11.3334C6.6665 10.9652 6.96498 10.6667 7.33317 10.6667H11.3332C11.7014 10.6667 11.9998 10.9652 11.9998 11.3334V15.3334C11.9998 15.7016 11.7014 16.0001 11.3332 16.0001Z"
        fill="#CAD2D9"
      />
      <path
        d="M18.0002 16.0001H14.0002C13.632 16.0001 13.3335 15.7016 13.3335 15.3334V11.3334C13.3335 10.9652 13.632 10.6667 14.0002 10.6667H18.0002C18.3684 10.6667 18.6668 10.9652 18.6668 11.3334V15.3334C18.6668 15.7016 18.3684 16.0001 18.0002 16.0001Z"
        fill="#CAD2D9"
      />
      <path
        d="M24.6667 16.0001H20.6667C20.2985 16.0001 20 15.7016 20 15.3334V11.3334C20 10.9652 20.2985 10.6667 20.6667 10.6667H24.6667C25.0349 10.6667 25.3333 10.9652 25.3333 11.3334V15.3334C25.3333 15.7016 25.0349 16.0001 24.6667 16.0001Z"
        fill="#CAD2D9"
      />
      <path
        d="M11.3332 22.6666H7.33317C6.96498 22.6666 6.6665 22.3681 6.6665 21.9999V17.9999C6.6665 17.6317 6.96498 17.3333 7.33317 17.3333H11.3332C11.7014 17.3333 11.9998 17.6317 11.9998 17.9999V21.9999C11.9998 22.3681 11.7014 22.6666 11.3332 22.6666Z"
        fill="#CAD2D9"
      />
      <path
        d="M18.0002 22.6666H14.0002C13.632 22.6666 13.3335 22.3681 13.3335 21.9999V17.9999C13.3335 17.6317 13.632 17.3333 14.0002 17.3333H18.0002C18.3684 17.3333 18.6668 17.6317 18.6668 17.9999V21.9999C18.6668 22.3681 18.3684 22.6666 18.0002 22.6666Z"
        fill="#CAD2D9"
      />
      <path
        d="M24.6667 29.3333H20.6667C20.2985 29.3333 20 29.0348 20 28.6666V17.9999C20 17.6317 20.2985 17.3333 20.6667 17.3333H24.6667C25.0349 17.3333 25.3333 17.6317 25.3333 17.9999V28.6666C25.3333 29.0348 25.0349 29.3333 24.6667 29.3333Z"
        fill="url(#paint2_linear_1525_5354)"
      />
      <path
        d="M11.3332 29.3333H7.33317C6.96498 29.3333 6.6665 29.0349 6.6665 28.6667V24.6667C6.6665 24.2985 6.96498 24 7.33317 24H11.3332C11.7014 24 11.9998 24.2985 11.9998 24.6667V28.6667C11.9998 29.0349 11.7014 29.3333 11.3332 29.3333Z"
        fill="#CAD2D9"
      />
      <path
        d="M18.0002 29.3333H14.0002C13.632 29.3333 13.3335 29.0349 13.3335 28.6667V24.6667C13.3335 24.2985 13.632 24 14.0002 24H18.0002C18.3684 24 18.6668 24.2985 18.6668 24.6667V28.6667C18.6668 29.0349 18.3684 29.3333 18.0002 29.3333Z"
        fill="#CAD2D9"
      />
      <defs>
        <filter
          id="filter0_dd_1525_5354"
          x="3.6665"
          y="0.666748"
          width="24.6665"
          height="11.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_5354"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_5354"
            result="effect2_dropShadow_1525_5354"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_5354"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1525_5354"
          x1="25.6843"
          y1="32.7737"
          x2="6.31571"
          y2="-0.773694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626F7A" />
          <stop offset="1" stopColor="#8B9299" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1525_5354"
          x1="19.3659"
          y1="11.1636"
          x2="12.6338"
          y2="-0.496811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#28AFEA" />
          <stop offset="0.37387" stopColor="#3CCAF4" />
          <stop offset="0.74949" stopColor="#4BDFFC" />
          <stop offset="1" stopColor="#50E6FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1525_5354"
          x1="25.8095"
          y1="28.7767"
          x2="19.5239"
          y2="17.8898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#173A73" />
          <stop offset="0.55519" stopColor="#134584" />
          <stop offset="1" stopColor="#114A8B" />
        </linearGradient>
      </defs>
    </svg>
  );
});
