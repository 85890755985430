import { FluentIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Savings = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M21.3335 22.6667H28.0002V0.666667C28.0002 0.489856 27.9299 0.320286 27.8049 0.195262C27.6799 0.0702379 27.5103 0 27.3335 0L23.3335 0C23.1567 0 22.9871 0.0702379 22.8621 0.195262C22.7371 0.320286 22.6668 0.489856 22.6668 0.666667V4L21.3335 22.6667Z"
        fill="#50E6FF"
      />
      <path
        d="M16 22.6667H22.6667V4H18C17.8232 4 17.6536 4.07024 17.5286 4.19526C17.4036 4.32029 17.3333 4.48986 17.3333 4.66667V8L16 22.6667Z"
        fill="#35C1F1"
      />
      <path
        d="M10.667 22.6667H17.3337V8H12.667C12.4902 8 12.3206 8.07024 12.1956 8.19526C12.0706 8.32029 12.0003 8.48986 12.0003 8.66667V12L10.667 22.6667Z"
        fill="#199BE2"
      />
      <path
        d="M4.66699 22.6667L12.0003 22.6666V12H7.33366C7.15685 12 6.98728 12.0702 6.86225 12.1953C6.73723 12.3203 6.66699 12.4899 6.66699 12.6667V16.0406L4.66699 22.6667Z"
        fill="#0669BC"
      />
      <path
        d="M1.99967 16H6.66634V22.6667H1.99967C1.82286 22.6667 1.65329 22.5964 1.52827 22.4714C1.40325 22.3464 1.33301 22.1768 1.33301 22V16.6667C1.33301 16.4899 1.40325 16.3203 1.52827 16.1953C1.65329 16.0702 1.82286 16 1.99967 16Z"
        fill="#0C59A4"
      />
      <mask
        id="mask0_1525_2121"
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="27"
        height="23"
      >
        <path
          d="M21.3335 22.6667H28.0002V0.666667C28.0002 0.489856 27.9299 0.320286 27.8049 0.195262C27.6799 0.0702379 27.5103 0 27.3335 0H23.3335C23.1567 0 22.9871 0.0702379 22.8621 0.195262C22.7371 0.320286 22.6668 0.489856 22.6668 0.666667V4L21.3335 22.6667Z"
          fill="#0C59A4"
        />
        <path
          d="M16.0002 22.6667H22.6668V4H18.0002C17.8234 4 17.6538 4.07024 17.5288 4.19526C17.4037 4.32029 17.3335 4.48986 17.3335 4.66667V8L16.0002 22.6667Z"
          fill="#0C59A4"
        />
        <path
          d="M10.6668 22.6667H17.3335V8H12.6668C12.49 8 12.3204 8.07024 12.1954 8.19526C12.0704 8.32029 12.0002 8.48985 12.0002 8.66667V12L10.6668 22.6667Z"
          fill="#0C59A4"
        />
        <path
          d="M4.66683 22.6667L12.0002 22.6666V12H7.3335C7.15668 12 6.98712 12.0702 6.86209 12.1953C6.73707 12.3203 6.66683 12.4899 6.66683 12.6667V16.0406L4.66683 22.6667Z"
          fill="#0C59A4"
        />
        <path
          d="M2.00016 16H6.66683V22.6667H2.00016C1.82335 22.6667 1.65378 22.5964 1.52876 22.4714C1.40373 22.3464 1.3335 22.1768 1.3335 22V16.6667C1.3335 16.4899 1.40373 16.3203 1.52876 16.1953C1.65378 16.0702 1.82335 16 2.00016 16Z"
          fill="#0C59A4"
        />
      </mask>
      <g mask="url(#mask0_1525_2121)">
        <g filter="url(#filter0_dd_1525_2121)">
          <path
            d="M22.0002 32.0001C26.7866 32.0001 30.6668 28.1199 30.6668 23.3334C30.6668 18.5469 26.7866 14.6667 22.0002 14.6667C17.2137 14.6667 13.3335 18.5469 13.3335 23.3334C13.3335 28.1199 17.2137 32.0001 22.0002 32.0001Z"
            fill="#184F85"
          />
        </g>
      </g>
      <path
        d="M21.9997 32.0001C26.7861 32.0001 30.6663 28.1199 30.6663 23.3334C30.6663 18.5469 26.7861 14.6667 21.9997 14.6667C17.2132 14.6667 13.333 18.5469 13.333 23.3334C13.333 28.1199 17.2132 32.0001 21.9997 32.0001Z"
        fill="#184F85"
      />
      <path
        d="M25.9997 22.0001H23.333V19.3334C23.333 19.1566 23.2628 18.987 23.1377 18.862C23.0127 18.737 22.8432 18.6667 22.6663 18.6667H21.333C21.1562 18.6667 20.9866 18.737 20.8616 18.862C20.7366 18.987 20.6663 19.1566 20.6663 19.3334V22.0001H17.9997C17.8229 22.0001 17.6533 22.0703 17.5283 22.1953C17.4032 22.3204 17.333 22.4899 17.333 22.6667V24.0001C17.333 24.1769 17.4032 24.3465 17.5283 24.4715C17.6533 24.5965 17.8229 24.6667 17.9997 24.6667H20.6663V27.3334C20.6663 27.5102 20.7366 27.6798 20.8616 27.8048C20.9866 27.9298 21.1562 28.0001 21.333 28.0001H22.6663C22.8432 28.0001 23.0127 27.9298 23.1377 27.8048C23.2628 27.6798 23.333 27.5102 23.333 27.3334V24.6667H25.9997C26.1765 24.6667 26.3461 24.5965 26.4711 24.4715C26.5961 24.3465 26.6663 24.1769 26.6663 24.0001V22.6667C26.6663 22.4899 26.5961 22.3204 26.4711 22.1953C26.3461 22.0703 26.1765 22.0001 25.9997 22.0001Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_dd_1525_2121"
          x="10.3335"
          y="11.6667"
          width="23.3335"
          height="23.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_2121"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_2121"
            result="effect2_dropShadow_1525_2121"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_2121"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});
