import { FC, useState } from "react";
import { mergeStyleSets, Stack, StackItem, Text } from "@fluentui/react";
import CircularButton from "./CircularButton";
import accordianData from "./AccordianData.json";
import DetailAccordian from "./DetailAccordian";

export interface AccordianDetail {
  id: string;
  title: string;
  content: string;
}

interface AccordianSection {
  buttonLabel: string;
  imageSrc: string;
  details: AccordianDetail[];
}

interface AccordianData {
  [key: string]: AccordianSection;
}

const styles = mergeStyleSets({
  imgStyle: {
    minHeight: "370px",
    height: "376px",
    maxWidth: "680px",
    minWidth: "100px",
    width: "100%",
    transition: "all 0.9s ease-out",
  },
  detailMatrixContainer: {
    transition: "all 0.9s ease-out",
    "@media (max-width: 1024px)": {
      "flex-wrap": "wrap !important",
      gap: "40px !important",
    },
  },
});

const DetailMatrix: FC = () => {
  const typedAccordianData = accordianData as AccordianData;
  const [activeSection, setActiveSection] = useState<string>(
    Object.keys(typedAccordianData)[0]
  );
  const images = (require as any).context("../../assets/tco/banner", true);
  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 40 }}
        className={styles.detailMatrixContainer}
        grow
        horizontalAlign="center"
      >
        <StackItem shrink>
          <img
            src={images(typedAccordianData[activeSection].imageSrc)}
            alt=""
            className={styles.imgStyle}
          />
        </StackItem>

        <Stack tokens={{ childrenGap: 17 }}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: 8 }}
          >
            {Object.keys(typedAccordianData).map((key: string) => (
              <CircularButton
                label={typedAccordianData[key].buttonLabel}
                key={key}
                isActive={activeSection === key}
                onClick={() => setActiveSection(key)}
              />
            ))}
          </Stack>
          <DetailAccordian data={typedAccordianData[activeSection].details} />
        </Stack>
      </Stack>
      <Stack>
        <Text variant="xSmall">
          [1] Data point derived from 17 in-depth interviews completed by IDC.
          All other data points derived from 800 survey results (700 Surface
          organizations with at least 150 Surface Laptops and 2-in-1 Tablets
          available since 2019, 100 non-Surface organizations).
        </Text>
        <Text variant="xSmall">
          [2] Surface residual value adjusted by IDC to reflect average after 36
          months.
        </Text>
      </Stack>
    </>
  );
};

export default DetailMatrix;
