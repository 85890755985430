import { FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const OnScreenKeyboard = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1525_5010)">
        <rect
          x="4"
          y="8"
          width="28"
          height="22"
          rx="2"
          fill="url(#paint0_linear_1525_5010)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 28C4 29.1046 4.89543 30 6 30H30C31.1046 30 32 29.1046 32 28H4Z"
          fill="url(#paint1_linear_1525_5010)"
        />
        <mask
          id="mask0_1525_5010"
          maskUnits="userSpaceOnUse"
          x="4"
          y="8"
          width="28"
          height="22"
        >
          <rect
            x="4"
            y="8"
            width="28"
            height="22"
            rx="2"
            fill="url(#paint2_linear_1525_5010)"
          />
        </mask>
        <g mask="url(#mask0_1525_5010)">
          <g filter="url(#filter0_dd_1525_5010)">
            <path
              d="M8.24699e-06 15.7104L4.93848e-05 4.28955C5.39395e-05 3.02507 1.02513 2 2.28961 2H19.7104C20.9749 2 22 3.02507 22 4.28956V15.7104C22 16.9749 20.9749 18 19.7104 18H2.28957C1.02508 18 3.69231e-06 16.9749 8.24699e-06 15.7104Z"
              fill="url(#paint3_linear_1525_5010)"
            />
          </g>
        </g>
        <rect
          y="2"
          width="22"
          height="16"
          rx="2"
          fill="url(#paint4_linear_1525_5010)"
        />
        <g filter="url(#filter1_dd_1525_5010)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44772 7 5 7C5.55228 7 6 6.55228 6 6C6 5.44772 5.55228 5 5 5ZM5 13C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H5ZM8 6C8 5.44772 8.44772 5 9 5C9.55228 5 10 5.44772 10 6C10 6.55228 9.55228 7 9 7C8.44772 7 8 6.55228 8 6ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9ZM12 6C12 5.44772 12.4477 5 13 5C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7C12.4477 7 12 6.55228 12 6ZM11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9ZM16 6C16 5.44772 16.4477 5 17 5C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6ZM15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_1525_5010"
          x="-2.22222"
          y="-0.222222"
          width="26.4444"
          height="20.4444"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.37037" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_5010"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.11111" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_5010"
            result="effect2_dropShadow_1525_5010"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_5010"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_1525_5010"
          x="2"
          y="3"
          width="18"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.333333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1525_5010"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1525_5010"
            result="effect2_dropShadow_1525_5010"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1525_5010"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1525_5010"
          x1="25.5217"
          y1="34.1982"
          x2="7.50311"
          y2="6.39368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45494D" />
          <stop offset="1" stopColor="#737A80" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1525_5010"
          x1="21.7688"
          y1="33.0375"
          x2="18.8829"
          y2="24.2899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#737A80" />
          <stop offset="1" stopColor="#8A9299" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1525_5010"
          x1="25.5217"
          y1="34.1982"
          x2="7.50311"
          y2="6.39368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45494D" />
          <stop offset="1" stopColor="#737A80" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1525_5010"
          x1="15.6413"
          y1="14.968"
          x2="8.69083"
          y2="0.484876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#30E5D0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1525_5010"
          x1="15.6413"
          y1="14.968"
          x2="8.69083"
          y2="0.484876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset="1" stopColor="#30E5D0" />
        </linearGradient>
        <clipPath id="clip0_1525_5010">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
