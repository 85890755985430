import { useEffect, useState } from "react";
import { mergeStyleSets, Pivot, PivotItem, Stack } from "@fluentui/react";
import Header from "v2/components/common/Header";
import assumptionDataAll from "v2/components/business-assumptions/BusinessAssumptionDataAll.json";
import assumptionDataDevices from "v2/components/business-assumptions/BusinessAssumptionDataDevices.json";
import AssumptionDetailPanel from "v2/components/business-assumptions/AssumptionDetailPanel";
import LabelledDropdown from "v2/components/common/LabelledDropdown";
import {
  Button,
  Divider,
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
  tokens,
} from "@fluentui/react-components";
import EmployeeAssumption from "v2/components/business-assumptions/EmployeeAssumption";
import ITEfficiency from "v2/components/business-assumptions/ITEfficiency";
import EmployeeExperience from "v2/components/business-assumptions/EmployeeExperience";
import AccessoriesAndExtendedWarranty from "v2/components/business-assumptions/AccessoriesAndExtendedWarranty";
import SurfaceAndOtherPC from "v2/components/business-assumptions/SurfaceAndOtherPC";
import Sustainability from "v2/components/business-assumptions/Sustainability";
import DeviceSavings from "v2/components/business-assumptions/DeviceSavings";
import DeviceCosts from "v2/components/business-assumptions/DeviceCosts";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { useNavigate } from "react-router-dom";
import { Buttonstyle } from "v2/components/navigation-buttons/NavigationButtons";

import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

import CostSavingsBanner from "v2/components/cost-savings-banner/CostSavingsBanner";
import { set } from "lodash";
import AllDevices from "./AlldevicesAssumptions";
import Devices from "./DeviceAssumptions";
import { FC } from "react";

const step = "STEP 3";
const heading = "Review business case assumptions (optional)";
const subHeading =
  "To get a more accurate TCO report, update the assumptions below with your own information or accept default assumptions based on a study conducted by IDC and commissioned by Microsoft in 2022.";

export type BussinessAssumption = {
  id: string;
  title: string;
  subTitle: string;
  iconName: string;
};

interface BusinessAssumptionJson {
  [key: string]: BussinessAssumption;
}

const childrenMapping: { [key: string]: FC<any> } = {
  //employeeAssumptions: EmployeeAssumption,
  itEfficiency: ITEfficiency,
  employeeExperience: EmployeeExperience,
  // accessoriesAndExternalWarranty: AccessoriesAndExtendedWarranty,
  // surfaceAndOtherPCDevices: SurfaceAndOtherPC,
  // Sustainability: Sustainability,
  deviceSavings: DeviceSavings,
  deviceCost: DeviceCosts,
  //add other childrens here
};

const childrenMappingAllDevices: { [key: string]: FC<any> } = {
  employeeAssumptions: EmployeeAssumption,
  accessoriesAndExternalWarranty: AccessoriesAndExtendedWarranty,
  surfaceAndOtherPCDevices: SurfaceAndOtherPC,
  Sustainability: Sustainability,
};

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "0px 0px",
    rowGap: "15px",
  },
  panels: {
    padding: "0 0px",
    "& th": {
      textAlign: "left",
      padding: "0 14px 0 0",
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      padding: "0 15px 0 0",
    },
  },
});

const BusinessCaseAssumptions: FC = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] =
    React.useState<TabValue>("All Devices");
  const styles = useStyles();
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const dataAll = assumptionDataAll as BusinessAssumptionJson;
  const dataDevices = assumptionDataDevices as BusinessAssumptionJson;
  const {
    selectedYears,
    setSelectedYears,
    setTcoReportPayload,
    tcoReportPayloadList,
    setTcoReportPayloadList,
  } = useTcoCalculationContext();
  useEffect(() => {
    if (selectedYears) {
      setTcoReportPayload((prev) => {
        if (prev.years !== selectedYears) {
          return { ...prev, years: selectedYears };
        }
        return prev;
      });

      setTcoReportPayloadList((prev1) => {
        return {
          ...prev1,
          payloadList: prev1.payloadList.map((item) => {
            if (item.years !== selectedYears) {
              return { ...item, years: selectedYears };
            }
            return item;
          }),
        };
      });
    }
  }, [selectedYears, setTcoReportPayload]);

  const location = useLocation();
  const focusDivRef = useRef(null);

  const [openItems, setOpenItems] = useState<string[]>([]);

  useEffect(() => {
    const focusDivId = location.state?.focusDivId;
    if (focusDivId) {
      const element = document.getElementById(focusDivId);
      if (element) {
        element.setAttribute("tabindex", "0");
        element.scrollIntoView({ behavior: "smooth" });
        element.focus();
        setOpenItems(focusDivId);
      }
    }
  }, [location.state, navigate]);

  const {
    setTcoCompleteReport,
    currency,
    tcoCompleteReport,
    selectedCountry,
    tcoCompleteReportMultipleDevice,
  } = useTcoCalculationContext();

  return (
    <div style={{width:"100%"}}>
      <Stack>
      
      <CostSavingsBanner></CostSavingsBanner>
       <div style={{paddingLeft:"40px",paddingTop:"20px"}}> {step} </div> 
       <div style={{paddingLeft:"25px",paddingTop:"10px"}}>
        <TabList
          selectedValue={selectedValue}
          onTabSelect={onTabSelect}
          appearance="subtle-circular"
        >
          <Tab id="All Devices" value="All Devices">
            All Devices
          </Tab>
          {tcoCompleteReportMultipleDevice.deviceResponse.map((device) => (
            <Tab id={device.device.toString()} value={device.device.toString()}>
              {device.device}
            </Tab>
          ))}
        </TabList>
        </div>
       
        <div>
          {selectedValue === "All Devices" && <AllDevices />}
          {tcoCompleteReportMultipleDevice.deviceResponse.map(
            (device) =>
              selectedValue === device.device.toString() && (
                <Devices DeviceName={device.device.toString()} />
              )
          )}
        </div>
      </Stack>
    </div>
  );
};

export default BusinessCaseAssumptions;
