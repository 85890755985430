import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import {
  InfoLabel,
  Input,
  InputOnChangeData,
  makeStyles,
  InfoLabelProps,
  Slot,
  PopoverSurface,
} from "@fluentui/react-components";
import { FC } from "react";
import { formatNumber, isPositiveNumber } from "v2/utilities/helper";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";

export interface LabelledInputProps {
  label: string;
  infoLabel?: NonNullable<Slot<typeof PopoverSurface>>;
  infoLabelProps?: InfoLabelProps;
  value: string;
  maxWidth?: number;
  isAmount?: boolean;
  type?: "number" | "text";
  sublabel?: string;
  isHeadingBold?: boolean;
  onChange: (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean;
}

const useStyles = makeStyles({
  label: {
    fontSize: "14px",
  },
});

export const Tooltip = mergeStyleSets({
  tooltipStyle: {
    "&& .fui-PopoverSurface": {
      padding: "0.7rem 1.5rem",
      width: 380,
      maxWidth: "90%",
      span: {
        display: "block",
        paddingBottom: "0.7rem",
      },
    },
  },
  inputBackground: {
    background: "#F0F0F0 !important",
  },
});

const LabelledInput: FC<LabelledInputProps> = ({
  label,
  infoLabelProps,
  infoLabel,
  value,
  maxWidth,
  isAmount,
  type,
  sublabel,
  isHeadingBold,
  onChange,
  onBlur,
  disabled = false,
}) => {
  const styles = useStyles();
  const { currency } = useTcoCalculationContext();
  const onInputChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    if (
      (isAmount || type === "number") &&
      !isPositiveNumber(data.value) &&
      data.value !== ""
    ) {
      return;
    }
    onChange(ev, data);
  };
  return (
    <Stack style={{ maxWidth: maxWidth }}>
      <InfoLabel
        size="medium"
        info={infoLabel}
        aria-label={label}
        {...infoLabelProps}
        className={Tooltip.tooltipStyle}
      >
        {isHeadingBold ? <b>{label}</b> : label}
      </InfoLabel>

      <InfoLabel size="medium" className={styles.label}>
        {!label && sublabel && <br />}
        <br />
        {sublabel}
      </InfoLabel>
      <Input
        title={label}
        value={isAmount || type === "number" ? formatNumber(value) : value}
        onChange={onInputChange}
        onBlur={onBlur}
        contentBefore={isAmount ? <Text>{currency?.symbol}</Text> : ""}
        className={`${disabled ? Tooltip.inputBackground : ""}`}
        disabled={disabled}
      />
    </Stack>
  );
};

export default LabelledInput;
