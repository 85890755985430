import { Stack, StackItem, Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import TextBlock from "./TextBlock";
import { TcoReportSegment } from "v2/types/apiResponseTypes";
import { chunkArray } from "v2/utilities/helper";

const useStyles = makeStyles({
  sectionHeading: {
    color: "#005D9D",
    fontSize: "22px",
    fontWeight: "600",
  },
  title: {
    color: "#005D9D",
    fontSize: "16px",
    fontWeight: "600",
  },
  divider: {
    background: "#6BA5D2",
    height: "1.5px",
    width: "95%",
  },
  container: {
    width: "100%",
  },
});

const ReportBlock: React.FC<{ data: TcoReportSegment, valueType: string }> = ({ data, valueType }) => {
  const styles = useStyles();
  const chunkedRows = data?.rows ? chunkArray(data?.rows, 4) : [];

  return (
    <Stack
      className={styles.container}
      tokens={{ childrenGap: 20 }}
      id="reportBlock"
    >
      <Text className={styles.sectionHeading} id="sectionHeading">
        {data?.label}
      </Text>
      <div className={styles.divider} />
      <Stack horizontal tokens={{ childrenGap: 15 }}>
        {chunkedRows.map((chunk, columnIndex) => (
          <StackItem key={columnIndex}>
            {chunk.map((item, index) => (
              <TextBlock key={index} data={item} valueType={valueType} />
            ))}
          </StackItem>
        ))}
      </Stack>
      <div className={styles.divider} />
      <Text className={styles.title} id="reportFooter">
        {data?.footer}
      </Text>
    </Stack>
  );
};

export default ReportBlock;
