import { FC, useEffect, useRef, useState } from "react";
import { Stack, StackItem, Text } from "@fluentui/react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleData,
  makeStyles,
} from "@fluentui/react-components";
import { BussinessAssumption } from "v2/pages/tco/BusinessCaseAssumptions";
import { Calculator } from "v2/assets/icons/Calculator";
import { FeedbackHub } from "v2/assets/icons/FeedbackHub";
import { Magnifier } from "v2/assets/icons/Magnifier";
import { Savings } from "v2/assets/icons/Savings";
import { Message } from "v2/assets/icons/Message";
import { People } from "v2/assets/icons/People";
import { Weather } from "v2/assets/icons/Weather";
import { OnScreenKeyboard } from "v2/assets/icons/OnScreenKeyboard";
import { FluentIcon } from "@fluentui/react-icons";
import { set } from "lodash";

interface AssumptionDetailPanelProps {
  data: BussinessAssumption;
  children: React.ReactNode;
  Id: string;
  openItems: string[];
  device: String;
}

const iconMap: { [key: string]: FluentIcon } = {
  Calculator,
  FeedbackHub,
  Magnifier,
  Message,
  OnScreenKeyboard,
  People,
  Savings,
  Weather,
};

const useStyles = makeStyles({
  card: {
    padding: "24px",
    borderRadius: "8px",
    border: "1px #F4F4F4",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
  },
  headerIcon: {
    "& .f9c4gz4": {
      alignSelf: "start",
    },
  },
});

const AssumptionDetailPanel: FC<AssumptionDetailPanelProps> = (
  props: AssumptionDetailPanelProps
) => {
  let { data, children, Id, openItems,device } = props;
  const Icon = iconMap[data.iconName ?? ""];
  const styles = useStyles();
  const focusDivRef = useRef(null);

  const [initialOpenItems, setinitialOpenItemsList] = useState<string[]>([]);

  let [openItemsList, setOpenItemsList] = useState<string[]>([]);

  useEffect(() => {
    if (openItems.length > 0) {
      if (Id.toString() === openItems.toString()) {
        setinitialOpenItemsList([openItems.toString()]);
        setOpenItemsList([openItems.toString()]);
      }
    }
  }, [openItems]);

  const ontoggle = (ev: React.ChangeEvent<any>, data: AccordionToggleData) => {
    const value = data.value as string;

    if (!initialOpenItems.includes(value)) {
      initialOpenItems.push(value);
      setOpenItemsList([...initialOpenItems]);
    } else {
      initialOpenItems.pop();
      setOpenItemsList([...initialOpenItems]);
    }
  };

  return (
    <Stack className={styles.card}>
      <Accordion
        collapsible
        openItems={openItemsList}
        onToggle={(ev, data) => {
          ontoggle(ev, data);
        }}
      >
        <AccordionItem value={Id}>
          <AccordionHeader
            expandIconPosition="end"
            className={styles.headerIcon}
          >
            <Stack tokens={{ childrenGap: 16 }} horizontal>
              <StackItem>{Icon && <Icon fontSize={"32"} />}</StackItem>
              <Stack tokens={{ childrenGap: 9 }}>
                <Text style={{ fontWeight: "700" }}>{data.title}</Text>
                <Text>{data.subTitle}</Text>
              </Stack>
            </Stack>
          </AccordionHeader>
          <div id={Id} ref={focusDivRef}>
            <AccordionPanel>{children}</AccordionPanel>
          </div>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default AssumptionDetailPanel;
