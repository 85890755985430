import { Link, mergeStyleSets, Stack, StackItem, Text } from "@fluentui/react";
import { FC } from "react";
import DetailMatrix from "./DetailMatrix";
import { BUSINESS_CASE_URL } from "v2/constants";

const LandingPageContent: FC = () => {
  const styles = mergeStyleSets({
    heading: {
      fontSize: "18px",
      fontWeight: "700",
    },
    subHeading: {
      lineHeight: "normal",
      textAlign: "center",
      margin: "0 8%",
    },
    imgStyle: {
      height: "370px",
      maxWidth: "780px",
      width: "100%",
      objectFit: "contain",
    },
    link: {
      textDecoration: "underline",
      "&:hover": {
        color: "#0a61a3",
      },
    },
  });
  return (
    <Stack
      tokens={{ childrenGap: 32 }}
      styles={{ root: { padding: "25px 6% 0px" } }}
    >
      <StackItem align="center" style={{ width: "90%" }}>
        <Stack className={styles.subHeading} tokens={{ childrenGap: 16 }}>
          <Text className={styles.heading}>What is Surface TCO?</Text>
          <Text>
            Total Cost of Ownership (TCO) factors in the cost of the device plus
            the operating costs of ownership over a period of time (e.g. 3
            years); to provide a complete and holistic assessment of the value
            of your investment.
          </Text>
          <Text>
            Grounded on a{" "}
            <Link
              href={BUSINESS_CASE_URL}
              target="_blank"
              className={styles.link}
            >
              Microsoft commissioned IDC study
            </Link>
            , Microsoft Surface benefits are worth almost three times the
            investment cost over 3 years.
          </Text>
        </Stack>
      </StackItem>
      <DetailMatrix />
    </Stack>
  );
};

export default LandingPageContent;
